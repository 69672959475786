import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import HomePageIsip from "./pages/HomePageIsip";
import HomePageIsipManager from "./pages/HomePageIsipManager";
import NotFoundPage from "./pages/NotFoundPage";
import DocumentValidationPage from "./pages/DocumentValidationPage";
import DocumentValidationTask from "./components/DocumentValidationTask";
import InvoiceValidationPage from "./pages/InvoiceValidationPage";
import InvoiceApprovalPage from "./pages/InvoiceApprovalPage";
import TaskPageApproval from "./pages/TaskPageApproval";
import EditUser from "./components/user_management/EditUser";
import AddUser from "./components/user_management/AddUser";
import ResetPassword from "./components/user_management/ResetPassword";
import ForgotPassword from "./components/user_management/ForgotPassword";
import CNCPage from "./pages/CNCPage";
import CNCValidationTask from "./components/CNCValidationTask";
import DEPage from "./pages/DEPage";
import DamageEvaluationLink from "./components/DamageEvaluationLink";
import DamageEvaluationTaskWithProvider from "./components/DamageEvaluationTaskWithProvider";
import PolicyEnquiryPage from "./pages/PolicyEnquiryPage";
import DECreateTask from "./pages/DECreateTask";
import IsipDocumentValidationTask from "./components/isip_components/IsipDocumentValidationTask";
import RoleNotAuthorized from "./components/RoleNotAuthorized";
import HomePageAdmin from "./pages/HomePageAdmin";
import UserManagementPage from "./pages/UserManagementPage";
import ActivityTrailPage from "./pages/ActivityTrailPage";
import secureLocalStorage from "react-secure-storage";
import { GlobalDataContext } from "../src/contexts/GlobalDataContext";
import ChangePassword from "./pages/ChangePassword";
import ValidateMfa from "./pages/ValidateMfa";
import SetupMfa from "./pages/SetupMfa";
import { useNavigate } from "react-router-dom";

function App() {
  const { globalData, updateGlobalData } = React.useContext(GlobalDataContext);
  const [isLoading, setIsLoading] = React.useState(true);
  const idToken = secureLocalStorage.getItem('idToken') as string;
  const role = JSON.parse(secureLocalStorage.getItem('role') as string);
  const firstName = JSON.parse(secureLocalStorage.getItem('firstName') as string);
  const lastName = JSON.parse(secureLocalStorage.getItem('lastName') as string);
  const username = JSON.parse(secureLocalStorage.getItem('username') as string);
  const accessToken = JSON.parse(secureLocalStorage.getItem('accessToken') as string);
  const refreshToken = JSON.parse(secureLocalStorage.getItem('refreshToken') as string);
  const lastLogin = JSON.parse(secureLocalStorage.getItem('lastLogin') as string);
  const lastLogout = JSON.parse(secureLocalStorage.getItem('lastLogout') as string);
  const status = JSON.parse(secureLocalStorage.getItem('status') as string);
  const tokenExpiration = JSON.parse(secureLocalStorage.getItem('tokenExpiration') as string);

  const navigate = useNavigate();
  
  React.useEffect(() => {
    const fetchUser = () => {
      updateGlobalData({ 
        firstName,
        lastName,
        role, 
        username, 
        idToken,
        accessToken,
        refreshToken,
        lastLogin,
        lastLogout,
        status,
        tokenExpiration
      });
      setIsLoading(false);
    };
    console.log(firstName);

  
    fetchUser();

    const checkTokenExpiration = () => {
      const currentTime = Math.floor(new Date().getTime() / 1000);

      if (tokenExpiration && currentTime > tokenExpiration) {
        updateGlobalData({ 
          firstName: null,
          lastName: null,
          role: null, 
          username: null, 
          idToken: null, 
          isUserLogOut: true, 
          lastLogin: null, 
          lastLogout: null,
          tokenExpiration: null
        });
  
        secureLocalStorage.removeItem('username');
        secureLocalStorage.removeItem('role');
        secureLocalStorage.removeItem('idToken');
        secureLocalStorage.removeItem('accessToken');
        secureLocalStorage.removeItem('lastLogin');
        secureLocalStorage.removeItem('lastLogout');
        secureLocalStorage.removeItem('status');
        secureLocalStorage.removeItem('tokenExpiration');
  
        navigate('/');
      }
    }
    
    const intervalId = setInterval(checkTokenExpiration, 60000);
    checkTokenExpiration();

    return () => clearInterval(intervalId);
  }, [tokenExpiration, navigate, updateGlobalData]);
  
  

  const [totalRepairersAmount, setTotalRepairersAmount] =
    React.useState(3900.0);
  const [totalLaborAmount, setTotalLaborAmount] = React.useState(5000.0);
  const [selectedBrand, setSelectedBrand] = React.useState<string | null>(null);
  const [selectedModel, setSelectedModel] = React.useState<string | null>(null);

  const handleBrandModelUpdate = (
    brand: string | null,
    model: string | null
  ) => {
    setSelectedBrand(brand);
    setSelectedModel(model);
  };

  const themeLight = createTheme({
    palette: {
      background: {
        default: "#F7FBFC",
      },
      primary: {
        main: "#1C1364", // Replace this with your desired primary color
        contrastText: "#fff", // This is the text color that appears on primary buttons
      },
      warning: {
        main: "#ddbc19", // Replace this with your desired primary color
        contrastText: "#fff", // This is the text color that appears on primary buttons
      },
      error: {
        main: "#a42a25", // Replace this with your desired primary color
        contrastText: "#fff", // This is the text color that appears on primary buttons
      }
    },
    typography: {
      fontFamily: '"Poppins", "system-ui", "sans-serif"', // Use System-ui with sans-serif as fallback
    },
  });

  if (!globalData.role && !isLoading) {
    return (
      <Routes>
        <Route index element={<LoginPage />} />
        <Route path="/" element={<LoginPage />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="change-password" element={<ChangePassword />} />
        <Route path="validate-mfa" element={<ValidateMfa/>} />
        <Route path="setup-mfa" element={<SetupMfa/>} />
        <Route path="policyenquiry" element={<PolicyEnquiryPage />} />
      </Routes>
    );
  }

  const renderRoutesForRole = (role: string) => {
    switch (role) {
      case "Processor":
        return (
          <>
            <Route index element={<HomePage />} />
            <Route
              path="document-validation"
              element={<DocumentValidationPage />}
            />
            <Route
              path="document-validation/:transId"
              element={<DocumentValidationTask />}
            />
            <Route path="damage-evaluation" element={<DEPage />} />
            <Route
              path="damage-evaluation/:transId"
              element={<DamageEvaluationTaskWithProvider />}
            />
            <Route path="cnc" element={<CNCPage />} />
            <Route path="cnc/:transId" element={<CNCValidationTask />} />
            <Route path="policyenquiry" element={<PolicyEnquiryPage />} />
            <Route path="*" element={<NotFoundPage isLoading={isLoading}/>} />
          </>
        );
        case "Admin":
          return (
            <>
              <Route index element={<HomePageAdmin />} />
              <Route path="user-management" element={<UserManagementPage/>} />
              <Route path="activity-trail" element={<ActivityTrailPage/>} />
              <Route path="user-management/add-user" element={<AddUser />} />
              <Route path="user-management/edit-user/:username" element={<EditUser />} />
              <Route path="policyenquiry" element={<PolicyEnquiryPage />} />
              <Route path="*" element={<NotFoundPage isLoading={isLoading}/>} />
            </>
          );
      case "Head Clec":
        return (
          <>
            <Route index element={<HomePage />} />
            <Route
              path="document-validation"
              element={<DocumentValidationPage />}
            />
            <Route
              path="document-validation/:transId"
              element={<DocumentValidationTask />}
            />
            <Route path="damage-evaluation" element={<DEPage />} />
            <Route path="/create-task" element={<DECreateTask />} />
            <Route
              path="damage-evaluation/:transId"
              element={<DamageEvaluationTaskWithProvider />}
            />
            <Route
              path="damage-evaluation-link/:transId"
              element={
                <DamageEvaluationLink
                  totalRepairersAmount={totalRepairersAmount}
                  setTotalRepairersAmount={setTotalRepairersAmount}
                  setTotalLaborAmount={setTotalLaborAmount}
                  totalLaborAmount={totalLaborAmount}
                  selectedBrand={selectedBrand}
                  selectedModel={selectedModel}
                  onBrandModelUpdate={handleBrandModelUpdate}
                />
              }
            />
            <Route path="policyenquiry" element={<PolicyEnquiryPage />} />
            <Route path="*" element={<NotFoundPage isLoading={isLoading}/>} />
          </>
        );
      case "Isip Processor":
        return (
          <>
            <Route index element={<HomePageIsip />} />
            <Route
              path="invoice-validation"
              element={<InvoiceValidationPage />}
            />
            <Route
              path="invoice-validation/:transId"
              element={<IsipDocumentValidationTask />}
            />
            <Route path="policyenquiry" element={<PolicyEnquiryPage />} />
            <Route path="*" element={<NotFoundPage isLoading={isLoading}/>} />
          </>
        );
      case "Isip Manager":
        return (
          <>
            <Route index element={<HomePageIsipManager />} />
            <Route path="invoice-approval" element={<InvoiceApprovalPage />} />
            <Route
              path="invoice-approval/:transId"
              element={<TaskPageApproval />}
            />
            <Route path="policyenquiry" element={<PolicyEnquiryPage />} />
            <Route path="*" element={<NotFoundPage isLoading={isLoading} />} />
          </>
        );
      default:
        return <Route path="*" element={<RoleNotAuthorized />} />;
    }
  };

  return (
    <ThemeProvider theme={themeLight}>
      <CssBaseline />
      <Header />
      <Routes>
        { !isLoading && globalData?.role && globalData?.status === "1" ? (
          renderRoutesForRole(globalData?.role)
        ) : (
         <Route path="/" element={<RoleNotAuthorized />} />
        )}
      </Routes>
    </ThemeProvider>
  );
}

export default App;
