import React, { useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import './styles.css'; // Import your CSS

interface DocumentViewerProps {
  documentUrl: string;
}

const DocumentViewer: React.FC<DocumentViewerProps> = ({ documentUrl }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [scale, setScale] = useState<number>(1.0); // Initial scale factor
  const [dragging, setDragging] = useState<boolean>(false);
  const [position, setPosition] = useState<{ x: number, y: number }>({ x: 0, y: 0 });
  const [offset, setOffset] = useState<{ x: number, y: number }>({ x: 0, y: 0 });

  useEffect(() => {
    if (!documentUrl) {
      console.error("Invalid document URL:", documentUrl);
    }
  }, [documentUrl]);

  const getFileType = (url: string) => {
    if (!url) {
      return '';
    }
    const extension = url.split('.').pop();
    if (extension) {
      return extension.toLowerCase();
    }
    return '';
  };

  const fileType = getFileType(documentUrl);

  const handleZoomIn = () => {
    setScale((prevScale) => prevScale + 0.25); // Increase scale by 0.25
  };

  const handleZoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.25, 0.25)); // Decrease scale by 0.25, with a minimum value of 0.25
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    setDragging(true);
    setOffset({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (dragging) {
      setPosition({
        x: e.clientX - offset.x,
        y: e.clientY - offset.y,
      });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  useEffect(() => {
    setPosition({ x: 0, y: 0 }); // Reset position when document changes
  }, [documentUrl]);

  const renderPdf = () => (
    <Document
      file={documentUrl}
      onLoadSuccess={({ numPages }: { numPages: number }) => setNumPages(numPages)}
    >
      {Array.from(new Array(numPages), (el, index) => (
        <Page
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          scale={scale}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
      ))}
    </Document>
  );

  const renderImage = () => (
    <img
      src={documentUrl}
      alt="Document"
      style={{
        transform: `scale(${scale}) translate(${position.x / scale}px, ${position.y / scale}px)`,
        transformOrigin: 'top left',
        cursor: dragging ? 'grabbing' : 'grab',
      }}
    />
  );

  return (
    <div>
      <div className="pdf-viewer-controls">
        <button onClick={handleZoomIn}>Zoom In</button>
        <button onClick={handleZoomOut} disabled={scale <= 0.25}>Zoom Out</button>
      </div>
      <div
        className="pdf-container"
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        <div className="pdf-content" style={{ transform: `translate(${position.x}px, ${position.y}px)` }}>
          {fileType === 'pdf' ? renderPdf() : renderImage()}
        </div>
      </div>
    </div>
  );
};

export default DocumentViewer;
