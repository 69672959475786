// RoleNotAuthorized.tsx

import React from "react";
import { Container, Typography, Box } from "@mui/material";

const RoleNotAuthorized = () => {
  return (
    <Container maxWidth="sm" sx={{ mt: 4, textAlign: "center" }}>
      <Box
        sx={{
          p: 4,
          border: "1px solid #ddd",
          borderRadius: 2,
          boxShadow: 3,
          backgroundColor: "#fff",
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Access Denied
        </Typography>
        <Typography variant="body1">
          You don’t have the correct role to access this page.
        </Typography>
      </Box>
    </Container>
  );
};

export default RoleNotAuthorized;
