import {
  Container,
  Card,
  CardContent,
  Typography,
  Button,
  Skeleton,
  Box,
  CircularProgress,
  Chip,
} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MUIDataTable, {
  SelectableRows,
  Responsive,
  MUIDataTableOptions,
} from "mui-datatables";
import { useNavigate } from "react-router-dom";
import React from "react";
// import users from '../data/users.json';
import { GlobalDataContext } from "../../contexts/GlobalDataContext";
import { toTitleCase } from "../../api/utils";
import { formatDate } from "../../api/FormatDate";
import axios from "axios";
import CustomToolbarSelect from "./table_components/CustomToolbarSelect";

export default function LogsManagement({ globalData, mainState, rows }: any) {
  const options: MUIDataTableOptions = {
    download: true,
    print: true,
    selectableRows: "none",
    responsive: "standard",
    // resizableColumns: true,
    // customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
    //   <CustomToolbarSelect selectedRows={selectedRows} data={rows} />
    // ),
    // tableBodyHeight: "400px", // Set a fixed height for the table to control the vertical scroll
    // tableBodyMaxHeight: "",
  };
  const navigate = useNavigate();

  const statusMap: { [key: string]: string } = {
    null: "Not Active",
    "0": "Not Active",
    "1": "Active",
  };

  const columns = [
    {
      name: "taskType",
      label: "Task Type",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "left",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", textAlign: "left" },
        }),
      },
    },
    {
      name: "event",
      label: "Event / Action",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "left",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", textAlign: "left" },
        }),
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "left",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", textAlign: "left" },
        }),
      },
    },
    {
      name: "username",
      label: "User Name",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "left",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", textAlign: "left" },
        }),
      },
    },
    {
      name: "fullname",
      label: "Full Name",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "left",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", textAlign: "left" },
        }),
      },
    },
    {
      name: "roleLevel",
      label: "Role Level",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "left",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", textAlign: "left" },
        }),
      },
    },
    {
      name: "dateTime",
      label: "Date Time",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "left",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", textAlign: "left" },
        }),
      },
    }
  ];

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              paddingY: "5px !important",
              paddingX: "5px !important",
              backgroundColor: "#EEEEEE !important",
              // textAlign: 'left'
            },
            toolButton: {
              fontWeight: "bold !important",
              width: "100%",
              justifyContent: "center",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              paddingY: "5px !important",
              paddingX: "5px !important",
              textAlign: "left",
            },
          },
        },
        MUIDataTableSelectCell: {
          styleOverrides: {
            headerCell: {
              backgroundColor: "#EEEEEE !important",
            },
            // root: {
            //     backgroundColor: "#FFFFFF !important"
            // },
          },
        },
        // MUIDataTableResize: {
        //     styleOverrides:{
        //         resizer: {
        //             border: "0.05px solid rgba(0, 0, 0, 0.1) !important"
        //         }
        //     }
        // }
      },
    });

  return (
    <>
      <Typography
        variant="h3"
        sx={{
          color: "#2683e0",
        }}
      >
        Activity Logs
      </Typography>
      <Typography
        sx={{
          color: "#607274",
        }}
      >
        Monitor Logs
      </Typography>
      <hr />
      <br />
      <br />
      {mainState.isLoading ? (
        // (<Skeleton variant="rectangular" animation="wave" width={"100%"} height={500} />)
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title={""}
              data={rows}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </Box>
      )}
    </>
  );
}
