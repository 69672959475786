import { Button, DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import * as React from "react";
import CRForm from './CRForm';
import DocumentSummary from './DocumentSummary';
import DriverLicenseForm from './DriverLicenseForm';
import EstimationForm from './EstimationForm';
import MotorVehicleForm from './MotorVehicleForm';
import ORForm from './ORForm';
import AffidavitForm from './AffidavitForm';
import OtherForm from './OtherForm';
import PoliceReportForm from './PoliceReportForm';
import globalHooks from '../../hooks';
// import './general.scss';
import LogoImage from '../../assets/logo.json';
import { isDisabled } from "@testing-library/user-event/dist/utils";
import axios from "axios";
import { useParams } from 'react-router-dom';
import { GlobalDataContext } from '../../contexts/GlobalDataContext';


function toTitleCase(str: string | undefined | null): string | undefined {
  if (!str) {
      return str as undefined;
  }

  return str.replace(/\w\S*/g, (word) => {
      return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
  });
}

type OptionType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
export default function MainModal({ 
  data, 
  attachments, 
  transID, 
  scroll, 
  // policyNoField,
  // onChangePolicy,
  selectedForm,  
  userName, 
  disabled,
  setIsDisabled,
  refereshList, 
  close,
  docId,
}: any) {
  const [option, setOption] = React.useState<OptionType>(selectedForm);
  const [mainState, setMainState] = React.useState<any>({
      data: {},
      oriData: {},
      selections: {},
      changed: {},
      formError: {},
      isError: false,
      attachments: [],
      isLoading: false
  })
  //console.log('option', option);



  const handleNext = () => {
    setOption(option === 7 ? 0 : (option + 1) as OptionType);
  };

  const handleBack = () => {
    setOption((option - 1) as OptionType);
  };


  //#endregion Control Methods
  React.useEffect(() => {
    //console.log("IsSubmitted:",disabled)
    setIsDisabled(disabled)
    //console.log("isError: ", mainState?.isError)
  },[])

  return (
    <>
      <DialogContent dividers={scroll === 'paper'}>
        <div style={{ margin: "40px" }}>
          <Grid container spacing={2}>
            <Grid item md={9}>

              {/* Official Receipt */}
              {option === 0 && <div>
                <Typography variant="h4" className="tp-text-header-primary">
                  Official Receipt Validation
                </Typography>
                <Typography variant="body1">
                  Note: Please check all Required Fields with (<span className="tp-text-required">*</span>) before you submit.
                </Typography>
              </div>}

              {/* Certification of Registration */}
              {option === 1 && <div>
                <Typography variant="h4" className="tp-text-header-primary">
                  Certicate of Registration Validation
                </Typography>
                <Typography variant="body1">
                  Note: Please check all Required Fields with (<span className="tp-text-required">*</span>) before you submit.
                </Typography>
              </div>}

              {/* Driver's License */}
              {option === 2 && <div>
                <Typography variant="h4" className="tp-text-header-primary">
                  Driver's License Validation
                </Typography>
                <Typography variant="body1">
                  Note: Please check all Required Fields with (<span className="tp-text-required">*</span>) before you submit.
                </Typography>
              </div>}

              {/* Police Report */}
              {option === 3 && <div>
                <Typography variant="h4" className="tp-text-header-primary">
                  Motor Vehicle Form Validation
                </Typography>
                <Typography variant="body1">
                  Note: Please check all Required Fields with (<span className="tp-text-required">*</span>) before you submit.
                </Typography>
              </div>}

              {/* Motor Vehicle */}
              {option === 4 && <div>
                <Typography variant="h4" className="tp-text-header-primary">
                  Police Report Validation
                </Typography>
                <Typography variant="body1">
                  Note: Please check all Required Fields with (<span className="tp-text-required">*</span>) before you submit.
                </Typography>
              </div>}

              {/* Damage Evaluation and Estimation */}
              {option === 5 && <div>
                <Typography variant="h4" className="tp-text-header-primary">
                  Shop Estimate Form
                </Typography>
                <Typography variant="body1">
                  Note: Please check all Required Fields with (<span className="tp-text-required">*</span>) before you submit.
                </Typography>
              </div>}
              {option === 6 && <div>
                <Typography variant="h4" className="tp-text-header-primary">
                  Affidavit
                </Typography>
                <Typography variant="body1">
                  Note: Please check all Required Fields with (<span className="tp-text-required">*</span>) before you submit.
                </Typography>
              </div>}

              {option === 7 && <div>
                <Typography variant="h4" className="tp-text-header-primary">
                  Other Documents
                </Typography>
                <Typography variant="body1">
                  Note: Please check all Required Fields with (<span className="tp-text-required">*</span>) before you submit.
                </Typography>
              </div>}

            </Grid>
            <Grid item md={3} textAlign={"center"}>
              <img src={LogoImage.logo} width={180} height={60} alt='img' />
            </Grid>
          </Grid>
          <hr className="tp-border-hr_primary" /><br /><br />
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={8} sm={8} md={8} >
              <Typography variant="h6" className="tp-text-header-primary">
                Transaction No.: &ensp;
                <span className="tp-text-default">
                  ACAP-{data?.transId}
                </span>
              </Typography>
              <Typography variant="h6" className="tp-text-header-primary tp-mb-30px">
                Document ID.: &ensp;
                <span className="tp-text-default">
                  {docId}
                </span>
              </Typography>

              {option === 0 && <>
                <ORForm
                  setOption={setOption}
                  transID={transID}
                  disabled={disabled}
                  handleBack={handleBack}
                  handleNext={handleNext}
                  close={close}
                  userData={userName}
                  docId={docId}
                />
              </>}

              {option === 1 && <>
                <CRForm
                  option={option}
                  setOption={setOption}
                  transID={transID}
                  disabled={disabled}
                  handleBack={handleBack}
                  handleNext={handleNext}
                  close={close}
                  userData={userName}
                  docId={docId}
                />
              </>}

              {option === 2 && <>
                <DriverLicenseForm
                  option={option}
                  setOption={setOption}
                  transID={transID}
                  disabled={disabled}
                  handleBack={handleBack}
                  handleNext={handleNext}
                  close={close}
                  userData={userName}
                  docId={docId}
                />
              </>}

              {option === 3 && <>
                <MotorVehicleForm
                  option={option}
                  setOption={setOption}
                  // policyNoField={policyNoField}
                  // onChangePolicy={onChangePolicy}
                  transID={transID}
                  disabled={disabled}
                  handleBack={handleBack}
                  handleNext={handleNext}
                  close={close}
                  userData={userName}
                  docId={docId}
                />
              </>}

              {option === 4 &&
                <>
                  <PoliceReportForm
                    option={option}
                    setOption={setOption}
                    transID={transID}
                    disabled={disabled}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    close={close}
                    userData={userName}
                    docId={docId}
                  />
                </>}

              {option === 5 &&
                <>
                  <EstimationForm
                    option={option}
                    setOption={setOption}
                    transID={transID}
                    disabled={disabled}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    close={close}
                    userData={userName}
                    docId={docId}
                  />
                </>}

              {option === 6 &&
                <>
                  <AffidavitForm
                    option={option}
                    setOption={setOption}
                    transID={transID}
                    disabled={disabled}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    close={close}
                    userData={userName}
                    docId={docId}
                  />
                </>}
                {option === 7 &&
                <>
                  <OtherForm
                    option={option}
                    setOption={setOption}
                    transID={transID}
                    disabled={disabled}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    close={close}
                    userData={userName}
                    docId={docId}
                  />
                </>}
            </Grid>

            <Grid item xs={4} sm={4} md={4} >
              <Typography variant="h6" className="tp-text-header-primary tp-mb-30px">
                Document Summary
              </Typography>
              <DocumentSummary data={data} attachments={attachments} option={option} docId={docId}/>
            </Grid>
          </Grid>
        </div>
      </DialogContent>

    </>
  );
}
