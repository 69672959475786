import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  OutlinedInput,
  Typography,
  Container,
  Button,
  Box,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as React from "react";
import POValidation from "../components/po_validation/POValidation";
import { formatDate } from "../utils/FormatDate";
import Image from "../assets/logo_isip.json";
import InvoiceValidation from "../components/invoice_validation/InvoiceValidation";
import "../App.css";
import { useParams, useNavigate } from "react-router-dom";
import tasks from "../data/isip_tasks.json";
import ProcessorValidation from "../components/processor_validation/ProcessorValidation";
import ActivityLogs from "../components/activity_logs/ActivityLogs";
import { useGlobalData } from "../contexts/GlobalDataContext";

function TaskPageApproval() {
  const { transId } = useParams();
  const navigate = useNavigate();
  const { globalData } = useGlobalData();
  const [selectedInvoice, setSelectedInvoice] = React.useState<boolean>(true);
  const [selectedPO, setSelectedPO] = React.useState<boolean>(true);
  // const [selectedGR, setSelectedGR] = React.useState<boolean>(true);
  const handleClick = () => {
    if (globalData.submitForm) {
      // If submitForm is asynchronous
      globalData
        .submitForm()
        .then(() => {
          navigate(-1); // Navigate back after form submission
        })
        .catch((error) => {
          console.error("Form submission error:", error);
          // Handle any errors here, such as showing a notification to the user
        });
    } else {
      navigate(-1); // Navigate back if there's no form to submit
    }
  };
  return (
    <Container
      maxWidth="xl"
      sx={{
        my: 4,
        mt: 4,
      }}
    >
      <IconButton
        onClick={() => navigate(-1)}
        disableRipple={true}
        aria-label="open"
        sx={{
          my: 2,
          padding: 0,
          mt: 5,
        }}
      >
        <ArrowBackIcon sx={{ height: 28, width: 28 }} />
        <Typography sx={{ ml: 1 }}>Back</Typography>
      </IconButton>

      <Card sx={{ mt: 2 }}>
        <CardContent className="tp-mlr-50px tp-mtb-20px">
          <Grid container spacing={2}>
            <Grid item md={9}>
              <Typography variant="h4" className="tp-text-header-primary">
                <span className="tp-text-default">Transaction No.: &ensp;</span>
                {/* {data?.transId} */}
                {transId}
              </Typography>
              <Typography variant="body1" className="tp-text-header-primary">
                <span className="tp-text-default">Date Received: &ensp;</span>
                {/* {formatDate(data?.dateReceived)} */}
                {formatDate(tasks[0]?.created_at)}
              </Typography>
            </Grid>
            <Grid item md={3} textAlign={"center"}>
              <img src={Image.image} width={240} height={75} alt="img" />
            </Grid>
          </Grid>

          <br />
          <br />
          <Grid container spacing={2}>
            <Grid item xs={11} sm={11} md={11}>
              <Typography variant="h5" className="tp-text-header-primary">
                Invoice Approval
              </Typography>
              <Typography
                variant="caption"
                display="block"
                className="tp-text-input-sub"
              >
                Note: Please check all Required Fields with (
                <span className="tp-text-required">*</span>) before you submit.
              </Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <IconButton color="primary" aria-label="colapse">
                {selectedInvoice ? (
                  <KeyboardArrowUpIcon
                    fontSize="large"
                    color="primary"
                    onClick={() => {
                      setSelectedInvoice(!selectedInvoice);
                    }}
                    className="tp-cursor-pointer"
                  />
                ) : (
                  <KeyboardArrowDownIcon
                    fontSize="large"
                    color="primary"
                    onClick={() => {
                      setSelectedInvoice(!selectedInvoice);
                    }}
                    className="tp-cursor-pointer"
                  />
                )}
              </IconButton>
            </Grid>
          </Grid>
          <hr className="tp-border-hr_primary" />
          <br />
          <br />
          {selectedInvoice ? <InvoiceValidation transID={transId} /> : ""}

          <Grid container spacing={2}>
            <Grid item xs={11} sm={11} md={11}>
              <Typography variant="h5" className="tp-text-header-primary">
                LOA Validation
              </Typography>
              <Typography
                variant="caption"
                display="block"
                className="tp-text-input-sub"
              >
                Note: Please check all Required Fields with (
                <span className="tp-text-required">*</span>) before you submit.
              </Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <IconButton color="primary" aria-label="colapse">
                {selectedPO ? (
                  <KeyboardArrowUpIcon
                    fontSize="large"
                    color="primary"
                    onClick={() => {
                      setSelectedPO(!selectedPO);
                    }}
                    className="tp-cursor-pointer"
                  />
                ) : (
                  <KeyboardArrowDownIcon
                    fontSize="large"
                    color="primary"
                    onClick={() => {
                      setSelectedPO(!selectedPO);
                    }}
                    className="tp-cursor-pointer"
                  />
                )}
              </IconButton>
            </Grid>
          </Grid>
          <hr className="tp-border-hr_primary" />
          <br />
          <br />
          {selectedPO ? <POValidation transID={transId} /> : ""}

          {/* <Grid container spacing={2}>
            <Grid item xs={11} sm={11} md={11}>
              <Typography variant="h5" className="tp-text-header-primary">
                GR Validation
              </Typography>
              <Typography
                variant="caption"
                display="block"
                className="tp-text-input-sub"
              >
                Note: These are the available SAP GR in our System.
              </Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <IconButton color="primary" aria-label="colapse">
                {selectedGR ? (
                  <KeyboardArrowUpIcon
                    fontSize="large"
                    color="primary"
                    onClick={() => {
                      setSelectedGR(!selectedGR);
                    }}
                    className="tp-cursor-pointer"
                  />
                ) : (
                  <KeyboardArrowDownIcon
                    fontSize="large"
                    color="primary"
                    onClick={() => {
                      setSelectedGR(!selectedGR);
                    }}
                    className="tp-cursor-pointer"
                  />
                )}
              </IconButton>
            </Grid>
          </Grid>
          <hr className="tp-border-hr_primary" />
          <br />
          <br />
          {selectedGR ? <GRValidation transID={transId} /> : ""} */}

          <ProcessorValidation />

          <ActivityLogs />
        </CardContent>
      </Card>

      <Box
        sx={{
          my: 6,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button
          size="small"
          variant="outlined"
          sx={{ px: 4, borderRadius: 28 }}
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          sx={{ px: 4, borderRadius: 28 }}
          onClick={handleClick}
        >
          Submit
        </Button>
      </Box>
    </Container>
  );
}

export default TaskPageApproval;
