import NotFoundImage from '../assets/NotFoundImage.jpg'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { useNavigate } from "react-router-dom";
import { GlobalDataContext } from '../contexts/GlobalDataContext';
import { useContext } from 'react';
import { CircularProgress } from '@mui/material';

export default function NotFoundPage(isLoading: any) {
    const navigate = useNavigate();
    const { globalData } = useContext(GlobalDataContext);

    return (
        <div>
             {!isLoading && !globalData?.role ?(
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh',
                }}>
                    <img src={NotFoundImage} alt='NotFoundImage.jpg' width="300px" height="300px"/>
                    <Typography component="h1" variant="h3" sx={{
                        fontWeight: 'bolder'
                    }}>
                        Page Not Found
                    </Typography>
                    <Typography component="h2" variant="h6" sx={{
                        color: '#607274',
                        margin: '10px',
                        mx: '20%',
                        textAlign: 'center'
                    }}>
                        We're sorry, the page you requested could not be found please go back to the homepage.
                    </Typography>
                    <Button 
                        color="primary" 
                        variant="contained" 
                        onClick={() => navigate("/")}
                    >Go Home</Button>
                </div>
            ) : <CircularProgress />}
        </div>
    )
}