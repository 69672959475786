import { Box, Typography } from '@mui/material';

export default function MissingDocument() {
    return (
        <>
        <Box sx={{
            width: "100%",
            height: "300px",
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Typography variant="h3" className="tp-text-bold" sx={{
              my: 2
            }}>
              Missing Document
            </Typography>
            <Typography variant="body1">
              Please provide document to process
            </Typography>
          </Box>
        </>
    )
}