
import axios from "axios";
import { convertKeysToSnakeCase } from "./SnakeCaseConverter";
export const getCurrentUser = async (globalData: { username: any; accessToken: any; }) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/accounts/${globalData?.username}`, {
        headers: {
          Authorization: `Bearer ${globalData?.accessToken}`,
        },
      });
      const resData = res.data.data;

      return convertKeysToSnakeCase(resData);
    } catch (error) {
      console.error(error);

      return {};
    }
  };


export const checkUserStatus = async (username: string, accessToken: string) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/accounts/${username}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const resData = res.data.data;

    return convertKeysToSnakeCase(resData);
  } catch (error) {
    console.error(error);
    return {};
  }
}

export const getUser = async (username: string, globalData: any) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/accounts/${username}`, {
      headers: {
        Authorization: `Bearer ${globalData?.accessToken}`,
      },
    });

    const resData = res.data.data;
    const snakeCaseResponse = convertKeysToSnakeCase(resData);

    return snakeCaseResponse;
  } catch (error) {
    console.error(error);

    return {};
  }
};