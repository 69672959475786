import { FormControl, FormHelperText, OutlinedInput } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Select from "react-select";
// import { apiFetch } from 'robin-todo-list/src/api/RestApi';
// import './general.scss';
import damageEvalautionJson from "../../data/damageEvaluationData.json";
import { GlobalDataContext } from "../../contexts/GlobalDataContext";

export default function ForProcessor({
  mainState,
  setMainState,
  isDisable,
  handleSelectChange,
}: any) {
  const { globalData, setGlobalData } = React.useContext(GlobalDataContext);

  const handleInputChange = (e: any) => {
    const { value, id } = e.target;
    setMainState({ ...mainState, data: { ...mainState.data, [id]: value } });
  };

  // const handleSelectChange = (selection: any) => {
  //   console.log(selection);
  //   setMainState({
  //     ...mainState,
  //     data: {...mainState.data, status: selection.value},
  //     selections: { status: selection }
  //   })
  // }

  // const handleBlur = (id: string) => {
  //   if (damEvalState.changed[id]) { //check if have any changes in the value and then data will reflect on the Database via PUT API.
  //     updateData()
  //     // do something here.. ex. udpate your state.
  //     console.log('OnBlur Activated !!! Please check database if the change are reflected...')
  //   }

  // }

  //#endregion Control Methods
  // const options =
  //   globalData?.role === "Head Clec"
  //     ? [
  //         { value: 3, label: 'For Review by CLEC' },
  //         { value: 9, label: "Pending for Offer" },
  //         { value: 18, label: "Pending Adjuster's Report" },
  //       ]
  //     : [
  //         // { value: 3, label: 'Pending Inhouse Survey(CLEC)' },
  //         { value: 9, label: "Pending for Offer" },
  //         // { value: 18, label: "Pending Adjuster's Report" },
  //         { value: 4, label: "Forward To Head CLEC" },
  //       ];

  return (
    <div>
      <Typography variant="h6" className="tp-text-header-primary">
        For Processor
      </Typography>
      <hr className="tp-border-hr_primary" />
      <br />
      <FormControl variant="outlined" fullWidth className="tp-mb-20px">
        <Typography variant="body1" className="tp-text-bold">
          Status <span className="tp-text-required">*</span>
        </Typography>
        <Select
          id="status"
          placeholder="Please select status here..."
          value={mainState?.selections?.status || mainState?.statusOptions[0]}
          isDisabled={isDisable}
          onChange={handleSelectChange}
          options={mainState?.statusOptions}
        />
        {/* {!!damEvalState.formError?.status && (
          <FormHelperText error id="status">
            {damEvalState.formError?.status}
          </FormHelperText>
        )} */}
      </FormControl>
      <FormControl variant="outlined" fullWidth>
        <Typography variant="body1" className="tp-text-bold">
          Remarks
        </Typography>
        <OutlinedInput
          id="remarks"
          size="small"
          type="text"
          multiline
          className="tp-mb-30px"
          placeholder={`Enter Remarks Here...`}
          value={mainState?.data?.remarks || ""}
          onChange={handleInputChange}
          disabled={isDisable}
          // onBlur={() => handleBlur('remarks')}
          inputProps={{
            "aria-label": "remarks",
          }}
          // error={!!damEvalState.formError?.remarks}
        />
        {/* {!!damEvalState.formError?.remarks && (
          <FormHelperText error id="remarks">
            {damEvalState.formError?.remarks}
          </FormHelperText>
        )} */}
      </FormControl>
    </div>
  );
}
