import * as React from "react";
import {
  Container,
  Card,
  CardContent,
  Grid,
  Typography,
  IconButton,
  Box,
  Button,
  TextField,
  FormControl,
  Alert,
  Snackbar,
} from "@mui/material";
import Select, { ActionMeta, SingleValue } from "react-select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EstimationTable from "./damage_evaluation/EstimationTable";
import ClientTransaction from "./damage_evaluation/ClientTransaction";
import ForProcessor from "./damage_evaluation/ForProcessor";
import NoImageFound from "../assets/noFoundImage.json";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { formatDate } from "../api/FormatDate";
import axios from "axios";
import { GlobalDataContext } from "../contexts/GlobalDataContext";
import FieldChecklistClec from "./processor_validation/FieldChecklistClec";
import UploadImsi from "./UploadImsi";
import GenerateInsurer from "./GenerateInsurer";
import UploadClec from "./UploadClec";
import ComputationSheet from "./ComputationSheet";
import { format } from "path";
import { v4 as uuidv4 } from "uuid";
import { convertStringToNumber } from "../utils/stringUtils";

// Utility function
function toTitleCase(str: string | undefined | null): string | undefined {
  if (!str) {
    return str as undefined;
  }

  return str.replace(/\w\S*/g, (word) => {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
  });
}

export default function DamageEvaluationTask({
  totalRepairersAmount,
  setTotalRepairersAmount,
  totalLaborAmount,
  setTotalLaborAmount,
  selectedBrand,
  selectedModel,
  onBrandModelUpdate,
}: {
  totalRepairersAmount: number;
  setTotalRepairersAmount: React.Dispatch<React.SetStateAction<number>>;
  totalLaborAmount: number;
  selectedBrand: string | null;
  selectedModel: string | null;
  onBrandModelUpdate: (brand: string | null, model: string | null) => void;
  setTotalLaborAmount: React.Dispatch<React.SetStateAction<number>>;
}) {
  const handleNumberChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setter: React.Dispatch<React.SetStateAction<number>>
  ) => {
    const value = event.target.value;
    const parsedValue = parseFloat(value.replace(/,/g, ""));

    if (!isNaN(parsedValue)) {
      setter(parsedValue);
    }
  };

  type OptionType = { value: number; label: string };
  type CartItem = {
    id?: number; // Make id optional because it will be assigned by the backend
    deId?: string;
    transId?: string;
    name: string;
    amount: number;
    qty: number;
    price: number;
    saveDate?: string;
  };

  interface LaborRow {
    id?: number;
    scopeOfWorks: string;
    repairCondition: string;
    repair: number;
    replace: number;
    painting: number;
    saveDate?: string;
    transId?: string;
  }

  interface IShopItemTable {
    shopEstimate: {
      totalParts: string | null;
      totalLabors: string | null;
    } | null;
    headers: string[] | null;
    rows: Object[] | null;
  }

  const [ourOfferGrossTotal, setOurOfferGrossTotal] = React.useState<number>(0);
  const [totalDeductibleAmount, setTotalDeductibleAmount] =
    React.useState<number>(0);
  const [totalAmount, setTotalAmount] = React.useState<number>(0);
  const [laborPlusVat, setLaborPlusVat] = React.useState<number>(0);
  const [laborRows, setLaborRows] = React.useState<LaborRow[]>([]);
  const [cart, setCart] = React.useState<CartItem[]>([]);
  const laborAndMaterials = laborPlusVat;
  const regularParts = totalAmount;
  const [lossReserveParts, setLossReserveParts] = React.useState<number>(0);
  const [lossReserveLabor, setLossReserveLabor] = React.useState<number>(0);
  const location = useLocation();
  const { claimNo, docId } = location.state || {};
  const [deFromDb, setDeFromDb] = React.useState<any>({
    data: {},
  });
  const [shopItemTable, setShopItemTable] = React.useState<IShopItemTable>();
  const { globalData, setGlobalData } = React.useContext(GlobalDataContext);
  const [laborData, setLaborData] = React.useState({
    rows: [],
    laborRate: 410,
    totalTimeHours: 0,
    calculatedLaborCost: 0,
    paintWorkMaterials: 0,
    totalLaborCost: 0,
  });

  const insurerRef = React.useRef<any>(null);

  const handleExternalCall = () => {
    console.log("insurerRef", insurerRef.current); // Check if the ref is available
    if (insurerRef.current) {
      insurerRef.current.generateAndUploadPdf(); // Call the exposed method
    }
  };

  const handleLaborDataChange = (newLaborData: any) => {
    setLaborData(newLaborData);
  };
  const formatNumber = (value: number) => {
    return value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const parseNumber = (value: string) => {
    const parsedValue = parseFloat(value.replace(/,/g, ""));
    return isNaN(parsedValue) ? 0 : parsedValue;
  };

  const [initialTotalParts, setTotalParts] = React.useState<number>(0);
  const [initialTotalLabor, setTotalLabor] = React.useState<number>(0);

  const handleUpdate = (parts: number, labor: number) => {
    setTotalParts(parts);
    setTotalLabor(labor / 1.12);
  };

  const updateTotalDeductible = (amount: number, totalNumber: number) => {
    const totalDeductible = totalNumber * 1;
    setTotalDeductibleAmount(totalDeductible);
  };

  const handleUpdateCart = (newCart: CartItem[]) => {
    setCart(newCart);
  };

  const updateGrossTotal = (amount: number, laborVat: number) => {
    setTotalAmount(amount);
    setLaborPlusVat(laborVat);
    const grossTotal = amount + laborVat;
    setOurOfferGrossTotal(grossTotal);
  };

  const optionsLoss: OptionType[] = [
    { value: 1, label: "Pending for Offer" },
    { value: 2, label: "Third Party Damage" },
    { value: 3, label: "Theft" },
  ];

  // #region mainstate
  const [mainState, setMainState] = React.useState<any>({
    data: {},
    statusOptions:
      globalData?.role === "Head Clec"
        ? [
          { value: 3, label: "For Review by CLEC" },
          { value: 9, label: "Pending for Offer" },
          { value: 18, label: "Pending Adjuster's Report" },
        ]
        : [
          { value: 9, label: "Pending for Offer" },
          { value: 18, label: "Pending Adjuster's Report" },
          { value: 4, label: "Forward To Head CLEC" },
        ],
  });

  const [totalDepreciation, setTotalDepreciation] = React.useState(0);
  const [totalPartsAmount, setTotalOfferAmount] = React.useState(0);

  const handleTotalDepreciationChange = (newTotalDepreciation: number) => {
    setTotalDepreciation(newTotalDepreciation);
  };

  const handleTotalOfferAmountChange = (newTotalOfferAmount: number) => {
    setTotalOfferAmount(newTotalOfferAmount);
  };

  const [userData, setUserData] = React.useState<any>({});
  const [isDisable, setIsDisable] = React.useState<boolean>(false);
  const { transId } = useParams();
  const navigate = useNavigate();

  const handleSelectChange = (
    selection: SingleValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => {
    console.log(selection);
    setMainState({
      ...mainState,
      data: { ...mainState.data, status: selection?.value },
      selections: { status: selection },
    });
  };

  const fetchDeTaskData = async () => {
    try {
      setMainState({ ...mainState, isLoading: true });
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/task/${transId}`
      );
      const data = res.data.data;
      const statusOptions =
        globalData?.role === "Head Clec"
          ? [
            { value: 9, label: "Pending for Offer" },
            { value: 18, label: "Pending Adjuster's Report" },
            { value: 5, label: "Reject" },
          ]
          : [
            { value: 9, label: "Pending for Offer" },
            { value: 18, label: "Pending Adjuster's Report" },
            { value: 4, label: "Forward To Head CLEC" },
            { value: 5, label: "Reject" },
          ];
      setDeFromDb({
        ...deFromDb,
        data: data,
      });
      setIsDisable(globalData?.role === "Clec" && data?.status === 4);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    try {
      setMainState({ ...mainState, isLoading: true });
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/damage-details?transId=${transId}`
      );
      const data = res.data.data;
      const statusOptions: any = {
        0: null,
        1: { value: 1, label: "Approved" },
        2: { value: 2, label: "Reject" },
      };
      setMainState({
        ...mainState,
        data: data,
        selections: { status: statusOptions[data?.status] || optionsLoss[0] },
        isLoading: false,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateData = async () => {
    try {
      setMainState({ ...mainState, isLoading: true });
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/damage-details?transId=${transId}`,
        mainState.data
      );
      const data = res.data;
      const statusOptions: any = {
        0: null,
        1: { value: 1, label: "Approved" },
        2: { value: 2, label: "Reject" },
      };
      setMainState({
        ...mainState,
        data: data,
        selections: { status: statusOptions[data?.status] || optionsLoss[0] },
        isLoading: false,
      });
      navigate(-1);
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const apiSubmitEmail = async (endpoint: string) => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}${endpoint}`);
    } catch (err: any) {
      console.error(err?.response?.data);
    }
  };

  // #region fetchShopItems
  const fetchShopItems = async () => {
    try {
      (async () => {
        axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/api/acap/shop-estimate/single?transId=${transId}`,
          // headers: {
          //   'Authorization': `Bearer ${globalData?.sessionToken}`
          // }
        })
          .then((res: any) => {
            console.log(res.data.data);
            const data = res?.data?.data;
            const tblShopItemsJSON = JSON.parse(data?.tableItems);

            console.log("mvf", data);
            // Convert rows into a list of JSON objects
            const tblShopItemRows = tblShopItemsJSON?.rows.map((row: any) => {
              // Generate a unique ID for each row
              const id = uuidv4();

              // Handle the case where row is empty
              if (row.length === 0) {
                return tblShopItemsJSON?.header.reduce(
                  (acc: any, key: any) => {
                    acc[key] = "";
                    return acc;
                  },
                  { id }
                ); // Include unique id in the object
              }

              return row.reduce(
                (acc: any, value: any, index: any) => {
                  acc[tblShopItemsJSON?.header[index]] = value;
                  return acc;
                },
                { id }
              ); // Include unique id in the object
            });

            console.log("setShopItemTable", data);

            setShopItemTable({
              shopEstimate: data,
              headers: tblShopItemsJSON?.header,
              rows: tblShopItemRows,
            });

            // setTotalRepairersAmount(data?.totalAmount);
            // setTotalLaborAmount(data?.totalLabor);

            console.log("tblShopItemRows", data);
          })
          .catch((err: any) => {
            setShopItemTable({
              shopEstimate: null,
              headers: null,
              rows: null,
            });

            console.error(err);
            let errorMessage = "";
            if (err?.response?.status === 401)
              errorMessage = err?.response?.data;
            if (err?.response?.status === 400)
              errorMessage = err?.response?.data;
          });
      })();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const patchStatusAndUser = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/accounts/all?rolename=Head Clec`
      )
      .then((res: any) => {
        console.log(res?.data?.data);
        // Note: to be changed
        // if user is head clec, temp patch user id to senior clec
        const headClecUser = globalData?.role === "Head Clec" ? 89 : res?.data?.data[0].id;

        const docStatus = mainState?.selections?.status?.value;

        axios
          .patch(
            `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/task?transId=${transId}&id=${docId}`,
            { status: docStatus, userId: headClecUser }
          )
          .then((res: any) => {
            console.log(res?.data?.data);
            const endpoint = globalData?.role === "Head Clec" ? `/api/acap/email/senior-clec` : `/api/acap/email/head-clec`;
            const emailRequest = globalData?.role === "Head Clec" ? { email: "seniorclec@gmail.com", transId: Number(transId) } :
              { email: "headclec@gmail.com", transId: Number(transId) };
            axios.post(`${process.env.REACT_APP_BACKEND_URL}${endpoint}`, {
              email: emailRequest.email,
              transId: emailRequest.transId,
            }).then((res: any) => {
              navigate("/damage-evaluation");
            })
           
          })
          .catch((err: any) => {
            console.log(err);
          });
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  // State to handle snackbar visibility
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = async () => {
    if (
      mainState?.selections?.status?.value === 4 &&
      globalData?.role === "Clec"
    ) {
      patchStatusAndUser();
    } else if (
      mainState?.selections?.status?.value === 3 &&
      globalData?.role === "Head Clec"
    ) {
      patchStatusAndUser();
    } else {
      // Proceed with the existing logic
      await handleExternalCall();

      await apiSubmitEmail(
        `/api/acap/damage-evaluation/task/approve?transId=${transId}`
      );
      navigate("/damage-evaluation");
    }
  };

  React.useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user") as string);
    setUserData(userData);
    fetchDeTaskData();
    fetchShopItems();
  }, []);

  return (
    <Container maxWidth={"xl"} sx={{ my: 12 }}>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="warning"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography align="center">{snackbarMessage}</Typography>
        </Alert>
      </Snackbar>
      <IconButton
        onClick={() => navigate(-1)}
        disableRipple={true}
        aria-label="open"
        sx={{ my: 2, padding: 0 }}
      >
        <ArrowBackIcon sx={{ height: 28, width: 28 }} />
        <Typography sx={{ ml: 1 }}>Back</Typography>
      </IconButton>
      <Card sx={{ my: 4 }}>
        <CardContent className="tp-mlr-50px tp-mtb-20px">
          <Grid container spacing={2}>
            <Grid item xs={9} sm={9} md={9}>
              <Typography variant="h4" className="tp-text-header-primary">
                Damage Evaluation
              </Typography>
              <Typography
                variant="body1"
                display="block"
                className="tp-text-input-sub"
              >
                Note: Please check all Required Fields with (
                <span className="tp-text-required">*</span>) before you submit.
              </Typography>
            </Grid>
          </Grid>
          <hr />
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item>
              <br />
              <Typography variant="h5" className="tp-text-header-primary">
                Type of Loss:{" "}
                <span className="tp-text-default">Own Damage</span>
              </Typography>
            </Grid>
            <Grid item>
              <br />
              <Typography variant="h5" className="tp-text-header-primary">
                Claim No: <span className="tp-text-default">{claimNo}</span>
              </Typography>
            </Grid>
          </Grid>
          <hr className="tp-border-hr_primary" />
          <br />
          <Grid container spacing={2}>
            <Grid item xs={9} sm={9} md={9}>
              <Typography variant="h5" className="tp-text-header-primary">
                Initial Estimate
              </Typography>
              <br />

              <br />
              <Typography variant="h5" className="tp-text-header-primary">
                <span className="tp-text-default">Clec Estimate: &ensp;</span>
              </Typography>

              <UploadClec
                claimNo={claimNo}
                onUpdate={handleUpdate}
                transId={transId}
                onBrandModelUpdate={onBrandModelUpdate}
              />
              <br />
              <br />
              <div className="tp-text-bold">
                Initial Total Parts Amount: ₱ {formatNumber(initialTotalParts)}
              </div>
              <div className="tp-text-bold">
                Initial Total Labor Amount: ₱ {formatNumber(initialTotalLabor)}
              </div>
            </Grid>
          </Grid>
          <hr className="tp-border-hr_primary" />
          <br />
          <br />

          <Grid container spacing={2}>
            <Grid item xs={8} sm={8} md={8}>
              <Typography variant="h5" className="tp-text-header-primary">
                <span className="tp-text-default">Transaction No.: &ensp;</span>
                ACAP - {transId}
              </Typography>
              <Typography variant="body1" className="tp-text-header-primary">
                <span className="tp-text-default">Date Received: &ensp;</span>
                2024-08-09
              </Typography>
              <br />
              <br />

              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Typography variant="body1" className="tp-text-bold">
                    Insured:
                  </Typography>
                </Grid>
                <Grid item md={6} textAlign="right">
                  <Typography variant="body1" className="tp-text-primary">
                    Rolando Dabanilla
                  </Typography>
                </Grid>
              </Grid>

              <hr />

              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Typography variant="body1" className="tp-text-bold">
                    Policy No.:
                  </Typography>
                </Grid>
                <Grid item md={6} textAlign="right">
                  <Typography variant="body1" className="tp-text-primary">
                    {mainState?.data?.policyNo
                      ? mainState?.data?.policyNo
                      : "DP878499"}
                  </Typography>
                </Grid>
              </Grid>
              <hr />
              
            
             
              {/* <br />
              <Typography variant="h6" className="tp-text-header-primary">
                List of Items
                <Typography
                  variant="caption"
                  display="block"
                  className="tp-text-subtitle"
                >
                  Note: These are the available data in OCR Process.
                </Typography>
              </Typography>
              {
                // #region Estimation Table
              }
              {/* Estimation Table */}
            
              <EstimationTable transID={transId} />
              <TextField
                label="Total Parts Amount"
                variant="outlined"
                type="text"
                value={formatNumber(totalRepairersAmount)}
                onChange={(e) => handleNumberChange(e, setTotalRepairersAmount)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Total Labor Amount"
                variant="outlined"
                type="text"
                value={formatNumber(totalLaborAmount)}
                onChange={(e) => handleNumberChange(e, setTotalLaborAmount)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <br />
            {
              // #endregion Estimation Table
            }
            {
              // #region Document Summary
            }
           <Grid item xs={4} sm={4} md={4}>
              {shopItemTable?.shopEstimate !== null && (
                <React.Fragment>
                  {mainState?.data?.esfGdriveField ? (
                    <>
                      <Typography
                        variant="h6"
                        className="tp-text-header-primary"
                      >
                        Document Summary
                      </Typography>
                      <Typography variant="body1" className="tp-mt-15px">
                        Filename: &ensp;
                        <span className="tp-text-primary">Estimation Form</span>
                      </Typography>
                      <Typography variant="body1" className="tp-mb-15px">
                        Date Received: &ensp;
                        <span className="tp-text-primary">
                          {formatDate(mainState?.data?.saveDate)}
                        </span>
                      </Typography>
                      <hr />
                      {mainState?.data?.esfGdriveFileType === "pdf" ? (
                        <iframe
                          width={"100%"}
                          height={"600"}
                          src={`https://drive.google.com/file/d/1PXZ6EljdiB1A2H7P39ePvOli8VN8DH_e/view?usp=sharing`}
                          
                          title="pdf"
                        ></iframe>
                      ) : (
                        <iframe
                          width={"100%"}
                          height={"600"}
                          src={`https://drive.google.com/file/d/1PXZ6EljdiB1A2H7P39ePvOli8VN8DH_e/view?usp=sharing`}
                          title="jpg"
                        ></iframe>
                      )}
                    </>
                  ) : (
                    <>
                      <Typography
                        variant="h6"
                        className="tp-text-header-primary"
                      >
                        Document Summary
                      </Typography>
                      <Typography variant="body1" className="tp-mt-15px">
                        Filename: &ensp;
                        <span className="tp-text-primary">Estimation Form</span>
                      </Typography>
                      <Typography variant="body1" className="tp-mb-15px">
                        Date Received: &ensp;
                        <span className="tp-text-primary">2024-08-16</span>
                        {/* https://drive.google.com/file/d/1D8MvvIZeUlSuQnFBmffXOqpDKpBivo2I/view?usp=sharing */}
                      </Typography>
                      <hr />
                      <iframe
                        width={"100%"}
                        height={"600"}
                        src={`https://drive.google.com/file/d/1PXZ6EljdiB1A2H7P39ePvOli8VN8DH_e/preview`}

                        title="pdf"
                      ></iframe>
                    </>
                  )}
                </React.Fragment>
              )}
            </Grid>
          </Grid>

      

      {/* <br />
              <Typography variant="h6" className="tp-text-header-primary">
                List of Items
                <Typography
                  variant="caption"
                  display="block"
                  className="tp-text-subtitle"
                >
                  Note: These are the available data in OCR Process.
                </Typography>
              </Typography>
              {
                // #region Estimation Table
              }

          





          {
            // #endregion Document Summary
          }
          <hr className="tp-border-hr_primary" />
          <br />
          {
            // #region ClientTransaction
          }
          {/* Client Transaction */}
      <ClientTransaction
        transID={transId}
        data={mainState?.data}
        lossReserveParts={lossReserveParts}
        setLossReserveParts={setLossReserveParts}
        lossReserveLabor={lossReserveLabor}
        setLossReserveLabor={setLossReserveLabor}
        initialLaborAmount={initialTotalLabor}
        initialPartsAmount={initialTotalParts}
        updateGrossTotal={updateGrossTotal}
        onLaborDataChange={handleLaborDataChange}
        updateTotalDeductible={updateTotalDeductible}
        updateCart={handleUpdateCart}
        onTotalDepreciationChange={handleTotalDepreciationChange}
        onTotalOfferAmountChange={handleTotalOfferAmountChange}
        totalRepairersAmount={totalRepairersAmount}
        totalLaborAmount={totalLaborAmount}
        setTotalLaborAmount={setTotalLaborAmount}
        selectedBrand={selectedBrand}
        selectedModel={selectedModel}
        mainState={mainState}
        setMainState={setMainState}
      />
      {
        // #endregion ClientTransaction
      }
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography
            variant="h5"
            className="tp-text-header-primary"
            textAlign="center"
          >
            CLEC Reports
          </Typography>
        </Grid>
      </Grid>
      <br />
      {
        // #region Insurer Report
      }
      <Grid container spacing={2}>
        <Grid item xs={2} sm={2} md={2}>
          <Typography variant="h5" className="tp-text-header-primary">
            Insurer Report
          </Typography>
        </Grid>
        <Grid item xs={3} sm={3} md={3}>
          <GenerateInsurer
            ref={insurerRef}
            repairersParts={totalRepairersAmount}
            repairersLabor={totalLaborAmount}
            laborData={laborData}
            laborPlusVat={laborPlusVat}
            totalAmount={totalAmount}
            totalDepreciation={totalDepreciation}
            totalPartsAmount={totalPartsAmount}
            totalDeductible={totalDeductibleAmount}
            ourOfferGrossTotal={ourOfferGrossTotal}
            partsCart={cart}
            transId={transId}
          />
        </Grid>
      </Grid>
      {
        // #endregion Insurer Report
      }
      {
        // #region Computation Sheet
      }
      <Grid container spacing={2}>
        <Grid item xs={2} sm={2} md={2}>
          <Typography variant="h5" className="tp-text-header-primary">
            Computation Sheet
          </Typography>
        </Grid>
        <Grid item xs={8} sm={8} md={8}>
          <ComputationSheet
            laborAndMaterials={laborAndMaterials + laborAndMaterials * 0.12}
            totalLabor={laborAndMaterials}
            regularParts={regularParts}
            depreciation={totalDepreciation}
            deductible={totalDeductibleAmount}
            totalLossReserve={lossReserveParts + lossReserveLabor}
          />
        </Grid>
      </Grid>
      {
        // #endregion Computation Sheet
      }
      <br />
      <br />
      <hr className="tp-border-hr_primary" />
      <br />
      <FieldChecklistClec />
      {
        // #region For Processor
      }
      <ForProcessor
        mainState={mainState}
        setMainState={setMainState}
        isDisable={isDisable}
        handleSelectChange={handleSelectChange}
      />
      {
        // #endregion For Processor
      }
      <hr className="tp-border-hr_primary" />
      <br />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" className="tp-text-header-primary">
          Login As User: &ensp;
          <span style={{ color: "#525252" }}>
            {toTitleCase(globalData?.firstName)}{" "}
            {toTitleCase(globalData?.lastName)}
          </span>
        </Typography>
        <Button
          disabled={isDisable}
          className="tp-custom-button-primary"
          variant="contained"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Box>
      <br />
    </CardContent>
      </Card >
    </Container >
  );
}
