import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Container,
  IconButton,
  Typography,
  Card,
  CardContent,
  Grid,
  Box,
  Dialog,
  AppBar,
  Toolbar,
  DialogProps,
  Slide,
  Button,
  Chip,
  Stack,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { TransitionProps } from "@mui/material/transitions";
import axios from "axios";
import { GlobalDataContext } from "../contexts/GlobalDataContext";
import FieldChecklist from "../components/processor_validation/FieldChecklist";
import UploadMissing from "./UploadMissing";
import MainModal from "./document_validation/MainModal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClaimRegistration from "./ClaimRegistration";
import { formatDate } from "../api/FormatDate";
import ClientList from "./clientList";
import PolicyChecker from "./PolicyChecker"; // Import the new component
import EmailMissing from "./EmailMissing"; // Import the new component
import { toTitleCase } from "../api/utils";
import TaskRemarks from "./activity_logs/TaskRemarks";
import AuditTrail from "./activity_logs/AuditTrail";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function DocumentValidationTask() {
  const location = useLocation();
  const { sender } = location.state || {};
  const [open, setOpen] = React.useState(false);
  const [disabled, setIsDisabled] = React.useState(false);
  const [forSubmit, setForSubmit] = React.useState(false);
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");
  const [selectedForm, setSelectedForm] = React.useState(0);
  const [selectedDocId, setSelectedDocId] = React.useState(0);
  const [policyNoFromDB, setPolicyFromDB] = React.useState("");
  const [mainState, setMainState] = React.useState<any>({
    data: {},
    attachments: [],
    docStatus: {},
    response: "",
    isLoading: false,
    error: "",
  });
  const [mvfDoc, setMvfDoc] = React.useState<any>(null);
  const { globalData } = React.useContext(GlobalDataContext);
  const [policyNoField, setPolicyNoField] = React.useState("");
  const { transId } = useParams();
  // console.log('transId', transId);
  const [clientCode, setClientCode] = React.useState<string | null>(null);
  const [claimRegistrationData, setClaimRegistrationData] =
    React.useState<any>(null); // State to store claim registration data

  const [clientValidated, setClientValidated] = React.useState(false);
  const [policyValidated, setPolicyValidated] = React.useState(false);

  const navigate = useNavigate();

  const apiHandler = async (endpoint: String) => {
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}${endpoint}`,
    })
      .then((res: any) => {
        const data = res.data;
        const attachmentsData = data["attachments"];
        console.log(attachmentsData);

        setMainState({
          ...mainState,
          data: data,
          attachments: attachmentsData,
        });

        const mvfData = attachmentsData.find(
          (doc: any) => doc.documentType === "Motor Vehicle"
        );
        fetchPolicyNo(mvfData?.docId);

        const forSubmission = attachmentsData.every(
          (item: any) => item?.status === "Approved"
        );
        setForSubmit(forSubmission);

        const forDisable = attachmentsData.every(
          (item: any) => item?.status === "For Policy Checking"
        );
        setIsDisabled(forDisable);
      })
      .catch((err: any) => {
        let errorMessage = "";
        if (err?.response?.status === 401 || 400) {
          errorMessage = err?.response?.data;
        }
      });
  };

  // #region fetchPolicyNo
  const fetchPolicyNo = async (docId: any) => {
    try {
      await axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/motor-vehicle-form?transId=${transId}&id=${docId}`
        )
        .then((res: any) => {
          const data = res?.data?.data;
          setPolicyNoField(data?.policyNo);
        });
    } catch (error) {
      console.error("Error fetching policy number:", error);
    }
  };

  console.log("policyNoField", policyNoField);

  // #region fetchData
  const fetchData = (endpoint: string) => {
    setMainState({
      data: {},
      attachments: [],
      docStatus: {},
      response: "",
      isLoading: false,
      error: "",
    });
    apiHandler(endpoint);
  };

  // #region useEffect
  React.useEffect(() => {
    fetchData(
      `/api/acap/document-validation/document-tracker?transId=${transId}`
    );
    console.log(globalData);
  }, []);

  const columns = [
    {
      field: "docId",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      editable: false,
      renderCell: (params: any) => {
        return (
          <Stack textAlign={"center"}>
            {params.row.docId === null || params.row.docId === null ? (
              <Typography variant="inherit" style={{ color: "#ed6c02" }}>
                Not Available
              </Typography>
            ) : (
              params.row.docId
            )}
          </Stack>
        );
      },
    },
    {
      field: "documentType",
      headerClassName: "super-app-theme--header",
      headerName: "Document Type",
      flex: 1,
      editable: false,
      renderCell: (params: any) => {
        return (
          <Stack textAlign={"center"}>
            {params.row.documentType === null ||
            params.row.documentType === null ? (
              <Typography variant="inherit" style={{ color: "#ed6c02" }}>
                Not Available
              </Typography>
            ) : (
              params.row.documentType
            )}
          </Stack>
        );
      },
    },
    {
      field: "channel",
      headerClassName: "super-app-theme--header",
      headerName: "Channel",
      sortable: true,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Stack textAlign={"center"}>
            {params.row.channel === " " || params.row.channel === null ? (
              <Typography variant="inherit" style={{ color: "#ed6c02" }}>
                Not Available
              </Typography>
            ) : params.row.channel === "2" ? (
              "Form Builder"
            ) : params.row.channel === "1" ? (
              "Email"
            ) : (
              params.row.channel
            )}
          </Stack>
        );
      },
    },
    {
      field: "dateReceived",
      headerClassName: "super-app-theme--header",
      headerName: "Date Received",
      sortable: true,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Stack textAlign={"center"}>
            {params.row.dateReceived === " " ||
            params.row.dateReceived === null ? (
              <Typography variant="inherit" style={{ color: "#ed6c02" }}>
                Not Available
              </Typography>
            ) : (
              formatDate(params.row.dateReceived)
            )}
          </Stack>
        );
      },
    },
    {
      field: "status",
      headerClassName: "super-app-theme--header",
      headerName: "Status",
      flex: 1,
      sortable: true,
      renderCell: (params: any) => {
        let statusChip = <Chip label="Small" size="small" />;
        if (params.row.status === "1") {
          statusChip = (
            <Chip
              label="Open"
              size="small"
              variant="outlined"
              color="primary"
            />
          );
        } else if (params.row.status === "2") {
          statusChip = (
            <Chip
              label="Approve"
              size="small"
              variant="filled"
              color="success"
            />
          );
        } else if (params.row.status === "3") {
          statusChip = (
            <Chip
              label="Rejected"
              size="small"
              variant="filled"
              color="error"
            />
          );
        } else if (
          params.row.status === "For Email" ||
          params.row.status === "Emailed"
        ) {
          statusChip = (
            <Chip
              label="Emailed"
              size="small"
              variant="filled"
              color="warning"
            />
          );
        } else if (params.row.status === "11") {
          statusChip = (
            <Chip
              label="Open"
              size="small"
              variant="outlined"
              color="primary"
            />
          );
        } else if (
          params.row.status === "Submitted" ||
          params.row.status === "For Damage Evaluation" ||
          params.row.status === "For Policy Checking"
        ) {
          statusChip = (
            <Chip
              label="Submitted"
              size="small"
              variant="filled"
              color="primary"
            />
          );
        } else if (params.row.status === "Completed") {
          statusChip = (
            <Chip
              label="Completed"
              size="small"
              variant="filled"
              color="primary"
            />
          );
        } else {
          statusChip = <></>;
        }

        return <Stack textAlign={"center"}>{statusChip}</Stack>;
      },
    },
    {
      field: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "Actions",
      width: 200,
      sortable: false,
      renderCell: (params: any) => {
        const handleClickOpen = (
          scrollType: DialogProps["scroll"],
          documentType: any
        ) => {
          const isCompleted =
            params.row.status === "Approved" ||
            params.row.status === "Submitted" ||
            params.row.status === "For Damage Evaluation" ||
            params.row.status === "For Policy Checking" ||
            params.row.status === "Completed"
              ? true
              : false;

          if (documentType === "Official Receipt") {
            setSelectedForm(0);
          } else if (documentType === "Certificate of Registration") {
            setSelectedForm(1);
          } else if (documentType === "Driver License") {
            setSelectedForm(2);
          } else if (documentType === "Motor Vehicle") {
            setSelectedForm(3);
          } else if (documentType === "Police Report") {
            setSelectedForm(4);
          } else if (documentType === "Shop Estimate") {
            setSelectedForm(5);
          } else if (documentType === "Affidavit") {
            setSelectedForm(6);
          } else if (documentType === "Other Documents") {
            setSelectedForm(7);
          }
          setOpen(true);
          setScroll(scrollType);
        };

        let actionButton = (
          <Button
            variant="contained"
            className="tp-custom-button-primary"
            size="small"
            onClick={() => {
              handleClickOpen("paper", params.row.documentType);
              setSelectedDocId(params.row.docId);
            }}
          >
            Update
          </Button>
        );

        if (
          params.row.status === "2" ||
          params.row.status === "5" ||
          params.row.status === "1" ||
          params.row.status === "11"
        ) {
          actionButton = (
            <Button
              variant="contained"
              className="tp-custom-button-primary"
              size="small"
              onClick={() => {
                handleClickOpen("paper", params.row.documentType);
                setSelectedDocId(params.row.docId);
              }}
            >
              Update
            </Button>
          );
        } else if (
          params.row.status === "11" ||
          params.row.status === "12" ||
          params.row.status === "10"
        ) {
          actionButton = (
            <Button variant="contained" className="tp-custom-button-primary" size="small" disabled>
              Update
            </Button>
          );
        } else if (
          params.row.status === "2" ||
          params.row.status === "3" ||
          params.row.status === "5" ||
          params.row.status === "9" ||
          params.row.status === "8"
        ) {
          actionButton = (
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={() => {
                handleClickOpen("paper", params.row.documentType);
                setSelectedDocId(params.row.docId);
              }}
            >
              View Details
            </Button>
          );
        } else {
          actionButton = <></>;
        }

        return <Stack textAlign={"center"}>{actionButton}</Stack>;
      },
    },
  ];

  // #region handleClose
  const handleClose = () => {
    setOpen(!open);
    fetchData(
      `/api/acap/document-validation/document-tracker?transId=${transId}`
    );
    fetchPolicyNo(mvfDoc?.docId);
  };

  return (
    <Container maxWidth={"lg"} sx={{ my: 12 }}>
      <IconButton
        onClick={() => navigate(-1)}
        disableRipple={true}
        aria-label="open"
        sx={{ my: 2, padding: 0 }}
      >
        <ArrowBackIcon sx={{ height: 28, width: 28 }} />
        <Typography sx={{ ml: 1 }}>Back</Typography>
      </IconButton>
      <Card sx={{ minWidth: 275, my: 2 }}>
        <CardContent className="tp-padding-50px">
          <Grid container spacing={2}>
            <Grid item md={9}>
              <Typography variant="h4" className="tp-text-header-primary">
                Policy/Document Validation
              </Typography>
            </Grid>
            <Grid item md={3} textAlign={"center"}>
              {/* <img src={Image.logo} width={180} height={60} alt='img' /> */}
            </Grid>
          </Grid>
          <hr className="tp-border-hr_primary" />
          <br />
          <br />
          <Grid container spacing={2}>
            <Grid item md={10}>
              <Typography variant="h5" className="tp-text-header-primary">
                <span className="tp-text-default">Transaction No.: &ensp;</span>
                ACAP-{transId}
              </Typography>
              <Typography variant="body1" className="tp-text-header-primary">
                <span className="tp-text-default">Sender: &ensp;</span>
                {sender}
              </Typography>
            </Grid>
            {/* 
              // #region PolicyChecker
            */}
            <Grid item md={6}>
              <PolicyChecker
                setClientCode={setClientCode}
                policyNo={policyNoField}
                setPolicyNoField={setPolicyNoField}
                setPolicyFromDB={setPolicyFromDB}
                claimRegistrationData={claimRegistrationData}
                setClientValidated={setClientValidated}
                policyValidated={policyValidated}
                setPolicyValidated={setPolicyValidated}
              />
            </Grid>
            {/* 
              // #region ClientList
            */}
            <Grid item md={6}>
              <ClientList
                clientCodeField={clientCode || ""}
                claimRegistrationData={claimRegistrationData}
                clientValidated={clientValidated}
                setClientValidated={setClientValidated}
              />
            </Grid>
          </Grid>
          <br />
          <br />
          <Grid container spacing={2}>
            {/* 
             // #region EmailMissing
            */}
            <Grid item md={7}>
              <EmailMissing transId={transId} sender={sender} />
            </Grid>
            {/* 
             // #region UploadMissing
            */}
            <Grid item md={5}>
              <Box display="flex" justifyContent="flex-end">
                <UploadMissing transId={transId || ""} />
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              height: 424,
              width: "100%",
              "& .super-app-theme--header": {
                backgroundColor: "#a42a25",
                color: "#ffffff",
              },
              "& .MuiDataGrid-columnHeaderDraggableContainer": {
                backgroundColor: "#a42a25",
              },
              "&. MuiDataGrid-footerContainer": {
                display: "none !important",
              },
            }}
          >
            {mainState?.attachments ? (
              <DataGrid
                rows={mainState?.attachments}
                columns={columns}
                disableRowSelectionOnClick
                disableColumnMenu
              />
            ) : (
              <></>
            )}
          </Box>
          <hr className="tp-border-hr_primary" />
          {/* 
            // #region FieldCheckList
          */}
          <FieldChecklist />
          <hr className="tp-border-hr_primary" />
          <br />
          {/* 
            // #region ClaimRegistration
          */}
          <Typography
            variant="h5"
            className="tp-text-header-primary"
            textAlign="center"
          >
            Claim Registration
          </Typography>
          <br />
          <ClaimRegistration
            transId={transId}
            policyNo={policyNoFromDB}
            claimRegistrationData={claimRegistrationData}
            setClaimRegistrationData={setClaimRegistrationData}
            isValidated={policyValidated === true && clientValidated === true}
          />
          <br />
          <br />
          <br />
          <Typography variant="h6" className="tp-text-header-primary">
            Logs
          </Typography>
          <hr className="tp-border-hr_primary" />
          <TaskRemarks />
          <AuditTrail />
          <br />
          <br />
          {/* 
            // #region Login As
          */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <Typography variant="h6" className="tp-text-header-primary">
              Login As User: &ensp;
              <span style={{ color: "#525252" }}>
                {toTitleCase(globalData?.firstName)}{" "}
                {toTitleCase(globalData?.lastName)}
              </span>
            </Typography>
          </Box>
          <br />
          <br />
        </CardContent>
      </Card>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        scroll={scroll}
        TransitionComponent={Transition}
      >
        <AppBar color="error" sx={{ position: "relative" }}>
          <Toolbar>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
              align="center"
            >
              Validation Process
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <MainModal
          data={mainState.data}
          attachments={mainState.attachments}
          transID={transId}
          scroll={scroll}
          selectedForm={selectedForm}
          disabled={disabled}
          setIsDisabled={setIsDisabled}
          userName={globalData}
          refereshList={fetchData}
          close={handleClose}
          docId={selectedDocId}
        />
      </Dialog>
    </Container>
  );
}
