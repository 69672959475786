import {
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  Button,
  Box,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Select from "react-select";
import axios from "axios";
import NoImageFound from "../../assets/noFoundImage.json";
import MissingDocument from "../MissingDocument";
import { GlobalDataContext } from "../../contexts/GlobalDataContext";
import TransferButton from "./TransferButton";
import { AuditTrailDataContext } from "../../contexts/AuditTrailContext";

function toTitleCase(str: string | undefined | null): string | undefined {
  if (!str) {
    return str as undefined;
  }

  return str.replace(/\w\S*/g, (word) => {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
  });
}
export default function CRForm({
  option,
  transID,
  disabled,
  handleBack,
  handleNext,
  close,
  userData,
  docId,
}: any) {
  const [mainState, setMainState] = React.useState<any>({
    data: {},
    oriData: {},
    selections: {},
    changed: {},
    formError: {},
    isError: false,
    attachments: [],
    isLoading: false,
  });
  const [isMissing, setIsMissing] = React.useState<boolean>(false);
  // const [isSubmit, setIsSubmit] = React.useState<boolean>(false);
  const { globalData, setGlobalData } = React.useContext(GlobalDataContext);
  const { createAuditTrailData } =
    React.useContext(AuditTrailDataContext);

  const fetchData = async () => {
    try {
      (async () => {
        axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/lto-cr?transId=${transID}&id=${docId}`,
          // headers: {
          //   'Authorization': `Bearer ${globalData?.sessionToken}`
          // }
        })
          .then((res: any) => {
            console.log(res.data.data);
            const data = res.data.data;
            const statusOptions: any = {
              1: { value: 1, label: "Open" },
              2: { value: 2, label: "Approve" },
              3: { value: 3, label: "Reject" },
            };
            setMainState({
              ...mainState,
              data: data,
              selections: { status: statusOptions[data?.status] },
            });

            createAuditTrailData({
              transId: transID,
              taskType: "document_validation",
              event: "view_form",
              description: `View LTO certificate of registration form with document id #${docId}`,
              userId: globalData.id
            })
          })
          .catch((err: any) => {
            console.log(err);
            let errorMessage = "";
            if (err.response.status === 401) errorMessage = err.response.data;
            if (err.response.status === 400) errorMessage = err.response.data;
          });
      })();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateData = async () => {
    try {
      setMainState((prevState: any) => ({ ...prevState, isLoading: true }));
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/lto-cr?transId=${transID}&id=${docId}`,
        mainState.data
      );
      const data = res.data;
      const statusOptions: any = {
        0: null,
        1: { value: 1, label: "Open" },
        2: { value: 2, label: "Approve" },
        3: { value: 3, label: "Reject" },
      };
      setMainState((prevState: any) => ({
        ...prevState,
        data: data,
        selections: { status: statusOptions[data.status] },
        formError: data?.error,
        isLoading: false,
      }));

      createAuditTrailData({
        transId: transID,
        taskType: "document_validation",
        event: "update_form",
        description: `Update LTO certificate of registration form with document id #${docId}`,
        userId: globalData.id
      })
      close();
    } catch (error) {
      console.error("Error updating data:", error);
      setMainState((prevState: any) => ({ ...prevState, isLoading: false }));
    }
  };

  const apiGetDocumentStatus = async () => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/document-tracker/document/${transID}/Certificate of Registration`,
      // headers: {
      //   'Authorization': `Bearer ${globalData?.sessionToken}`
      // }
    })
      .then((res: any) => {
        console.log("Response: ", res.data.status);
        setIsMissing(res.data?.status === "Missing" ? true : false);
      })
      .catch((err: any) => {
        console.error(err?.response?.data);
        let errorMessage = "";
        if (err?.response?.status === 401) errorMessage = err?.response?.data;
        if (err?.response?.status === 400) errorMessage = err?.response?.data;
      });
  };

  const isAnyFieldEmpty = () => {
    console.log("empty:", mainState?.data);
    for (const key in mainState?.data) {
      const fieldValue = mainState?.data[key];
      if (
        key !== "remarks" &&
        key !== "id" &&
        key !== "status" &&
        key !== "sourceId" &&
        key !== "transId" &&
        key !== "saveDate" &&
        key !== "denomination" &&
        key !== "registrationDate" &&
        key !== "netWT" &&
        key !== "noOfCylinders" &&
        key !== "shippingWT" &&
        key !== "netCapacity" &&
        key !== "encumberedTo" &&
        key !== "vin" &&
        key !== "vehicleType" &&
        key !== "vehicleCategory" &&
        key !== "passengerCategory" &&
        key !== "colors" &&
        key !== "classification" &&
        key !== "yearRebuilt" &&
        key !== "maxPower" &&
        key !== "passengerCapacity" &&
        (typeof fieldValue !== "string" || !fieldValue.trim())
      ) {
        console.log("mainState error:", key);
        return true;
      } else if (
        key === "status" &&
        (typeof fieldValue !== "number" || fieldValue === 0)
      ) {
        console.log("mainState error:", key);
        return true;
      }
    }
    return false;
  };

  React.useEffect(() => {
    // apiGetDocumentStatus();
    fetchData();
  }, []);

  const handleInputChange = (e: any) => {
    const { value, id } = e.target;
    setMainState({ ...mainState, data: { ...mainState.data, [id]: value } });
  };

  const handleSelectChange = (selection: any) => {
    // console.log(selection);
    setMainState({
      ...mainState,
      data: { ...mainState.data, status: selection.value },
      selections: { status: selection },
    });
  };

  //#endregion Control Methods

  return (
    <div>
      {isMissing === true ? (
        <MissingDocument />
      ) : (
        <>
          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={9}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Certificate of Registration No.{" "}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="crNo"
                  size="small"
                  type="text"
                  placeholder={`Enter Certificate of Registration No. Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={mainState.crNoConf + "%"} size="small" className={mainState.crNoConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.crNo || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('crNo')}
                  inputProps={{
                    "aria-label": "crNo",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.crNo === ""}
                />
                {mainState?.data?.crNo === "" && (
                  <FormHelperText error id="crNo">
                    {/* {mainState?.data?.crNo} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Date <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="date"
                  size="small"
                  type="text"
                  placeholder={`Enter Date Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={mainState.dateConf + "%"} size="small" className={mainState.dateConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.date || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('date')}
                  inputProps={{
                    "aria-label": "date",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.date === ""}
                />
                {mainState?.data?.date === "" && (
                  <FormHelperText error id="date">
                    {/* {mainState?.data?.orDate} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="tp-mb-10px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  MV File No. <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="mvFileNo"
                  size="small"
                  type="text"
                  placeholder={`Enter MV File No. Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={mainState.mvFileNoConf + "%"} size="small" className={mainState.mvFileNoConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.mvFileNo || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('mvFileNo')}
                  inputProps={{
                    "aria-label": "mvFileNo",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.mvFileNo === ""}
                />
                {(mainState?.data?.mvFileNo ?? "") === "" && (
                  <FormHelperText error id="mvFileNo">
                    {/* {mainState?.data?.mvFileNo} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Year Model <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="yearModel"
                  size="small"
                  type="text"
                  placeholder={`YYYY...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={mainState.yearModelConf + "%"} size="small" className={mainState.yearModelConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.yearModel || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('yearModel')}
                  inputProps={{
                    "aria-label": "yearModel",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.yearModel === ""}
                />
                {(mainState?.data?.yearModel ?? "") === "" && (
                  <FormHelperText error id="yearModel">
                    {/* {mainState?.formError?.yearModel} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Engine No. <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="engineNo"
                  size="small"
                  type="text"
                  placeholder={`Enter Engine No. Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={mainState.engineNoConf + "%"} size="small" className={mainState.engineNoConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.engineNo || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('engineNo')}
                  inputProps={{
                    "aria-label": "engineNo",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.engineNo === ""}
                />
                {(mainState?.data?.engineNo ?? "") === "" && (
                  <FormHelperText error id="engineNo">
                    {/* {mainState?.formError?.engineNo} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Chassis No. <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="chassis"
                  size="small"
                  type="text"
                  placeholder={`Enter Chassis No. Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={mainState.chassisNoConf + "%"} size="small" className={mainState.chassisNoConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.chassis || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('chassisNo')}
                  inputProps={{
                    "aria-label": "chassis",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.chassis === ""}
                />
                {(mainState?.data?.chassis ?? "") === "" && (
                  <FormHelperText error id="chassis">
                    {/* {mainState?.formError?.chassisNo} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Piston Displacement
                </Typography>
                <OutlinedInput
                  id="pistonDisplacement"
                  size="small"
                  type="text"
                  placeholder={`Enter Piston Displacement Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={mainState.pistonDisplacementConf + "%"} size="small" className={mainState.pistonDisplacementConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.pistonDisplacement || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('pistonDisplacement')}
                  inputProps={{
                    "aria-label": "pistonDisplacement",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.pistonDisplacement === ""}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Fuel
                </Typography>
                <OutlinedInput
                  id="fuel"
                  size="small"
                  type="text"
                  placeholder={`Enter Fuel Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={mainState.fuelConf + "%"} size="small" className={mainState.fuelConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.fuel || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('fuel')}
                  inputProps={{
                    "aria-label": "fuel",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.fuel === ""}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="tp-mb-10px">
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Make <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="make"
                  size="small"
                  type="text"
                  className="tp-mb-20px"
                  placeholder={`Enter Make Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={mainState.makeConf + "%"} size="small" className={mainState.makeConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.make || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('make')}
                  inputProps={{
                    "aria-label": "make",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.make === ""}
                />
                {(mainState?.data?.make ?? "") === "" && (
                  <FormHelperText error id="make">
                    {/* {mainState?.formError?.make} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Series <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="series"
                  size="small"
                  type="text"
                  placeholder={`Enter Series Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={mainState.seriesConf + "%"} size="small" className={mainState.seriesConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.series || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('series')}
                  inputProps={{
                    "aria-label": "series",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.series === ""}
                />
                {(mainState?.data?.series ?? "") === "" && (
                  <FormHelperText error id="series">
                    {/* {mainState?.formError?.series} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Body Type
                </Typography>
                <OutlinedInput
                  id="bodyType"
                  size="small"
                  type="text"
                  placeholder={`Enter Body type Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={mainState.bodyTypeConf + "%"} size="small" className={mainState.bodyTypeConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.bodyType || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('bodyType')}
                  inputProps={{
                    "aria-label": "bodyType",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.bodyType === ""}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Gross WT <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="grossWt"
                  size="small"
                  type="text"
                  placeholder={`Enter Gross WT Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={mainState.grossWtConf + "%"} size="small" className={mainState.grossWtConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.grossWt || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('grossWt')}
                  inputProps={{
                    "aria-label": "grossWt",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.grossWt === ""}
                />
                {(mainState?.data?.grossWt ?? "") === "" && (
                  <FormHelperText error id="grossWt">
                    {/* {mainState?.formError?.grossWt} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Net WT
                </Typography>
                <OutlinedInput
                  id="netWt"
                  size="small"
                  type="text"
                  placeholder={`Enter Gross WT Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={mainState.netWtConf + "%"} size="small" className={mainState.netWtConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.netWt || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('netWt')}
                  inputProps={{
                    "aria-label": "netWT",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.netWt === ""}
                />
              </FormControl>
            </Grid>
          </Grid>
          <FormControl variant="outlined" fullWidth className="tp-mb-20px">
            <Typography variant="body1" className="tp-text-bold">
              Denomination
            </Typography>
            <OutlinedInput
              id="denomination"
              size="small"
              type="text"
              placeholder={`Enter Denomination Here...`}
              // endAdornment={<InputAdornment position="end">
              //   <Chip label={mainState.denominationConf + "%"} size="small" className={mainState.denominationConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
              // </InputAdornment>}
              value={mainState?.data?.denomination || ""}
              onChange={handleInputChange}
              // onBlur={() => handleBlur('denomination')}
              inputProps={{
                "aria-label": "denomination",
              }}
              disabled={disabled}
              // error={mainState?.data?.denomination === ""}
            />
          </FormControl>
          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  No. of Cylinders
                </Typography>
                <OutlinedInput
                  id="noCylinders"
                  size="small"
                  type="text"
                  placeholder={`Enter No. of Cylinders Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={mainState.noOfCylindersConf + "%"} size="small" className={mainState.noOfCylindersConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.noCylinders || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('noOfCylinders')}
                  inputProps={{
                    "aria-label": "noCylinders",
                  }}
                  disabled={disabled}
                  // error={!!mainState?.formError?.noOfCylinders}
                />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Shipping WT
                </Typography>
                <OutlinedInput
                  id="shippingWt"
                  size="small"
                  type="text"
                  placeholder={`Enter Shipping WT Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={mainState.shippingWTConf + "%"} size="small" className={mainState.shippingWTConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.shippingWt || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('shippingWT')}
                  inputProps={{
                    "aria-label": "shippingWt",
                  }}
                  disabled={disabled}
                  // error={!!mainState?.formError?.shippingWT}
                />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Net Capacity
                </Typography>
                <OutlinedInput
                  id="netCapacity"
                  size="small"
                  type="text"
                  placeholder={`Enter Net Capacity Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={mainState.netCapacityConf + "%"} size="small" className={mainState.netCapacityConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.netCapacity || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('netCapacity')}
                  inputProps={{
                    "aria-label": "netCapacity",
                  }}
                  disabled={disabled}
                  // error={!!mainState?.formError?.netCapacity}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Vehicle Category 
                </Typography>
                <OutlinedInput
                  id="vehicleCategory"
                  size="small"
                  type="text"
                  placeholder={`Enter Vehicle Category Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={mainState.vehicleCategoryConf + "%"} size="small" className={mainState.vehicleCategoryConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.vehicleCategory || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('vehicleCategory')}
                  inputProps={{
                    "aria-label": "vehicleCategory",
                  }}
                  disabled={disabled}
                  // error={!!mainState?.formError?.vehicleCategory}
                />
                {/* {(mainState?.data?.vehicleCategory ?? "") === "" && (
                  <FormHelperText error id="vehicleCategory">
                    Empty required field. Please input data
                  </FormHelperText>
                )} */}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Vin
                </Typography>
                <OutlinedInput
                  id="vin"
                  size="small"
                  type="text"
                  className="tp-mb-20px"
                  placeholder={`Enter Vin Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={mainState.vinConf + "%"} size="small" className={mainState.vinConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.vin || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('vin')}
                  inputProps={{
                    "aria-label": "vin",
                  }}
                  disabled={disabled}
                  // error={!!mainState?.formError?.vin}
                />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Vehicle Type
                </Typography>
                <OutlinedInput
                  id="vehicleType"
                  size="small"
                  type="text"
                  placeholder={`Enter Vehicle Type Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={mainState.vehicleTypeConf + "%"} size="small" className={mainState.vehicleTypeConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.vehicleType || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('vehicleType')}
                  inputProps={{
                    "aria-label": "vehicleType",
                  }}
                  disabled={disabled}
                  // error={!!mainState?.formError?.vehicleType}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Typography variant="h6" className="tp-text-header-primary">
            Owner's Details
          </Typography>
          <hr />
          <FormControl variant="outlined" fullWidth className="tp-mb-20px">
            <Typography variant="body1" className="tp-text-bold">
              Owner's Name
              {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
              <span className="tp-text-required">*</span>
            </Typography>
            <OutlinedInput
              id="ownersName"
              size="small"
              type="text"
              placeholder={`Enter Owner's Name Here...`}
              // endAdornment={<InputAdornment position="end">
              //   <Chip label={mainState.completeOwnersNameConf + "%"} size="small" className={mainState.completeOwnersNameConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
              // </InputAdornment>}
              value={mainState?.data?.ownersName || ""}
              onChange={handleInputChange}
              // onBlur={() => handleBlur('completeOwnersName')}
              inputProps={{
                "aria-label": "ownersName",
              }}
              disabled={disabled}
              error={mainState?.data?.ownersName === ""}
            />
            {(mainState?.data?.ownersName ?? "") === "" && (
              <FormHelperText error id="ownersName">
                Empty required field. Please input data
              </FormHelperText>
            )}
          </FormControl>
          <FormControl variant="outlined" fullWidth className="tp-mb-20px">
            <Typography variant="body1" className="tp-text-bold">
              Address
              {/* <span className="tp-text-subtitle">(No, Street, City, Municipality, Province, Zip Code)</span>  */}
            </Typography>
            <OutlinedInput
              id="address"
              size="small"
              type="text"
              placeholder={`Enter Address Here...`}
              // endAdornment={<InputAdornment position="end">
              //   <Chip label={mainState.completeAddressConf + "%"} size="small" className={mainState.completeAddressConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
              // </InputAdornment>}
              value={mainState?.data?.address || ""}
              onChange={handleInputChange}
              // onBlur={() => handleBlur('completeAddress')}
              inputProps={{
                "aria-label": "address",
              }}
              disabled={disabled}
              error={mainState?.data?.address === ""}
            />
          </FormControl>
          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  OR Date <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="orDate"
                  size="small"
                  type="text"
                  placeholder={`Enter OR Date Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={mainState.orDateConf + "%"} size="small" className={mainState.orDateConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.orDate || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('orDate')}
                  inputProps={{
                    "aria-label": "orDate",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.orDate === ""}
                />
                {(mainState?.data?.orDate ?? "") === "" && (
                  <FormHelperText error id="orDate">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Amount
                </Typography>
                <OutlinedInput
                  id="amount"
                  size="small"
                  type="text"
                  placeholder={`Enter Amount Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={mainState.crAmountConf + "%"} size="small" className={mainState.crAmountConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.amount || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('crAmount')}
                  inputProps={{
                    "aria-label": "amount",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.amount === ""}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Typography variant="h6" className="tp-text-header-primary">
            For Processor
          </Typography>
          <hr />
          <FormControl variant="outlined" fullWidth className="tp-mb-20px">
            <Typography variant="body1" className="tp-text-bold">
              Status <span className="tp-text-required">*</span>
            </Typography>
            <Select
              id="status"
              placeholder="Please select status here..."
              value={mainState?.selections?.status || null}
              onChange={handleSelectChange}
              isDisabled={disabled}
              options={[
                { value: 1, label: "Open" },
                { value: 2, label: "Approve" },
                { value: 3, label: "Reject" },
              ]}
            />
            {mainState?.data?.status === null && (
              <FormHelperText error id="status">
                Empty required field. Please input data
              </FormHelperText>
            )}
          </FormControl>
          <FormControl variant="outlined" fullWidth className="tp-mb-30px">
            <Typography variant="body1" className="tp-text-bold">
              Remarks
            </Typography>
            <OutlinedInput
              id="remarks"
              size="small"
              type="text"
              multiline
              placeholder={`Enter Remarks Here...`}
              value={mainState?.data?.remarks || ""}
              onChange={handleInputChange}
              // onBlur={() => handleBlur('remarks')}
              inputProps={{
                "aria-label": "remarks",
              }}
              disabled={disabled}
              // error={!!formError?.remarks}
            />
            {/* {!!formError?.remarks && (
            <FormHelperText error id="remarks">
              {formError?.remarks}
            </FormHelperText>
          )} */}
          </FormControl>
          <hr />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <Typography variant="h6" className="tp-text-header-primary">
              Login As User: &ensp;
              <span style={{ color: "#525252" }}>
                {toTitleCase(globalData?.firstName)}{" "}
                {toTitleCase(globalData?.lastName)}
              </span>
            </Typography>
            <Box
              sx={{
                display: "flex",
              }}
            >
              {disabled === true ? (
                <Button
                  onClick={() => {
                    handleNext();
                  }}
                  variant="contained"
                  size="medium"
                  color="primary"
                >
                  {" "}
                  Next{" "}
                </Button>
              ) : (
                <>
                  <TransferButton
                    docId={docId}
                    transId={transID}
                    endpoint={"/api/acap/document-validation/lto-cr"}
                    documentType={"LTO Certificate of Registration"}
                    close={close}
                  />
                  &emsp;
                  <Button
                    // disabled={isAnyFieldEmpty()}
                    onClick={() => {
                      updateData();
                      // handleNext();
                    }}
                    color="error"
                    variant="contained"
                    size="medium"
                  >
                    Save
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </>
      )}
    </div>
  );
}
