import {
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  Button,
  Box,
  ButtonGroup,
  Alert,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Select from "react-select";
import axios from "axios";
import MissingDocument from "../MissingDocument";
import { GlobalDataContext } from "../../contexts/GlobalDataContext";
import TransferButton from "../document_validation/TransferButton";
import { channel } from "diagnostics_channel";
import AlertNotification from "../notifications/AlertNotification";
import { toTitleCase } from "../../api/utils";
import { removeTrailingCommas } from "../../utils/stringUtils";
import { isDisabled } from "@testing-library/user-event/dist/utils";

export default function SalesInvoiceForm({
  transID,
  disabled,
  handleBack,
  handleNext,
  close,
  userData,
  docId,
}: any) {
  const [mainState, setMainState] = React.useState<any>({
    data: {},
    oriData: {},
    selections: {},
    changed: {},
    formError: {},
    isError: false,
    attachments: [],
    isLoading: false,
    isDisabled: false,
    isNotDone: false,
  });
  const [isMissing, setIsMissing] = React.useState<boolean>(false);
  const [isSubmit, setIsSubmit] = React.useState<boolean>(false);
  const { globalData, setGlobalData } = React.useContext(GlobalDataContext);
  const [alertMessage, setAlertMessage] = React.useState<any>(null);
  const [showAlert, setShowAlert] = React.useState(false);
  const [isFieldEmpty, setIsFieldEmpty] = React.useState(true);

  const tablePreProcess = (tableItems: any | null | undefined) => {
    if (tableItems === null || tableItems === undefined || tableItems === "") {
      return null;
    } else {
      const tableItemsPreProcess = removeTrailingCommas(tableItems);
      const parsedTableItems = JSON.parse(tableItemsPreProcess);
      const transformedTable = {
        header: parsedTableItems?.header.map((header: any) =>
          header.replace(/ work/i, " Work")
        ),
        rows: parsedTableItems?.rows.map((row: any) => {
          return parsedTableItems?.header.reduce(
            (acc: any, header: any, index: any) => {
              acc[header.replace(/ work/i, " Work")] = row[index];
              return acc;
            },
            {}
          );
        }),
      };
      return transformedTable;
    }
  };

  const fetchData = async () => {
    try {
      (async () => {
        axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/api/isip/document-validation/sales-invoice?transId=${transID}&id=${docId}`,
          // headers: {
          //   'Authorization': `Bearer ${globalData?.sessionToken}`
          // }
        })
          .then((res: any) => {
            const data = res?.data?.data;
            const errors = res?.data?.errors;
            console.log(res.data);
            // table item processing
            const transformedtable = tablePreProcess(data?.tableItems);
            // status options
            const statusOptions: any = {
              // 0: null,
              // 1: { value: 1, label: "Open" },
              2: { value: 2, label: "Approve" },
              3: { value: 3, label: "Reject" },
            };

            const vatOptions: any = {
              Vat: { value: "Vat", label: "VAT Registered" },
              "Non Vat": { value: "Non Vat", label: "Not VAT Registered" },
            };
            setMainState({
              ...mainState,
              data: data,
              tableItems: transformedtable,
              selections: {
                status: statusOptions[data?.status],
              },
              vatSelections: { vatNonVat: vatOptions[data?.vatNonVat] },
              isDisabled: data?.status === 2 || data?.status === 3,
            });
          })
          .catch((err: any) => {
            console.error(err?.response?.data);
            let errorMessage = "";
            if (err?.response?.status === 401)
              errorMessage = err?.response?.data;
            if (err?.response?.status === 400)
              errorMessage = err?.response?.data;
          });
      })();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // TODO update the endpoint and API
  const updateData = async () => {
    const currentDateTime = new Date()
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/isip/document-validation/sales-invoice?transId=${transID}&id=${docId}`,
      {
        method: "PUT",
        body: JSON.stringify(mainState?.data),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        // setAlertMessage(data.message);
        // setShowAlert(true);
        close();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const isAnyFieldEmpty = (data: any) => {
    const keys = [
      "invoiceNo",
      "invoiceDate",
      "vendorName",
      "customerName",
      "deductible",
      "depreciation",
      "totalDeductibleDepre",
      "atp",
      "netAmount",
      "vatableSales",
      "grossAmount",
      "vatNonVat",
      "status",
    ];
    for (let key of keys) {
      if (key !== "status") {
        if (data[key] === null || data[key] === undefined || data[key] === "") {
          return true;
        }
      } else {
        if (data[key] === null || data[key] === 0 || data[key] === 1) {
          return true;
        }
      }
    }
    return false;
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    setIsFieldEmpty(isAnyFieldEmpty(mainState?.data));
  }, [mainState?.data]);

  const handleInputChange = (e: any) => {
    const { value, id } = e.target;
    setMainState({
      ...mainState,
      data: { ...mainState.data, [id]: value },
    });
  };

  const handleSelectChange = (selection: any) => {
    setMainState({
      ...mainState,
      data: { ...mainState.data, status: selection.value },
      selections: { status: selection },
    });
  };

  const handleVatSelectChange = (selection: any) => {
    setMainState({
      ...mainState,
      data: { ...mainState.data, vatNonVat: selection.value },
      vatSelections: { vatNonVat: selection },
    });
  };

  return (
    <div>
      {isMissing === true ? (
        <MissingDocument />
      ) : (
        <>
          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Invoice No.
                </Typography>
                <OutlinedInput
                  id="invoiceNo"
                  size="small"
                  type="text"
                  placeholder={`Enter Invoice No. Here...`}
                  value={mainState?.data?.invoiceNo || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "invoiceNo",
                  }}
                  disabled={mainState?.isDisabled}
                  error={mainState?.data?.invoiceNo === ""}
                />
                {mainState?.data?.invoiceNo === "" && (
                  <FormHelperText error id="invoiceNo">
                    {/* {mainState?.formError?.orNo} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Invoice Date <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="invoiceDate"
                  size="small"
                  type="text"
                  placeholder={`Enter Invoice Date Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.orDateConf + "%"} size="small" className={data.orDateConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.invoiceDate || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('or_date')}
                  inputProps={{
                    "aria-label": "invoiceDate",
                  }}
                  disabled={mainState?.isDisabled}
                  error={mainState?.data?.invoiceDate === ""}
                />
                {mainState?.data?.invoiceDate === "" && (
                  <FormHelperText error id="invoiceDate">
                    {/* {!!mainState?.formError?.orDate} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="tp-mb-20px tp-mt-15px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Repair Shop Name
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="vendorName"
                  size="small"
                  type="text"
                  placeholder={`Enter Repair Shop Name Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.nameConf + "%"} size="small" className={data.nameConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.vendorName || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('name')}
                  inputProps={{
                    "aria-label": "vendorName",
                  }}
                  disabled={mainState?.isDisabled}
                  error={mainState?.data?.vendorName === ""}
                />
                {mainState?.data?.vendorName === "" && (
                  <FormHelperText error id="vendorName">
                    {/* {!!mainState?.formError?.name} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Customer Name
                  {/* <span className="tp-text-subtitle">(No, Street, City, Municipality, Province, Zip Code)</span>  */}
                </Typography>
                <OutlinedInput
                  id="customerName"
                  size="small"
                  type="text"
                  placeholder={`Enter Customer Name Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.addressConf + "%"} size="small" className={data.addressConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.customerName || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('address')}
                  inputProps={{
                    "aria-label": "customerName",
                  }}
                  disabled={mainState?.isDisabled}
                  error={mainState?.data?.customerName === ""}
                />
                {mainState?.data?.customerName === "" && (
                  <FormHelperText error id="customerName">
                    {/* {!!mainState?.formError?.address} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          {
            // #region Table Items
            // if row have items, loop table items, if not show not available
          }
          {/* <br />
          <Typography variant="h6" className="tp-text-header-primary">
            Items
          </Typography>
          <hr />
          <TableContainer component={Paper} sx={{ marginTop: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="body1" className="tp-text-bold">
                      Scope of Work
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body1" className="tp-text-bold">
                      Labor cost
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body1" className="tp-text-bold">
                      Material cost
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body1" className="tp-text-bold">
                      Total cost
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mainState?.tableItems?.rows ? (
                  mainState?.tableItems?.rows.map(
                    (item: any, index: number) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {item["Scope of Work"]}
                        </TableCell>
                        <TableCell align="left">{item["Labor cost"]}</TableCell>
                        <TableCell align="left">
                          {item["Material cost"]}
                        </TableCell>
                        <TableCell align="right">
                          {item["Total cost"]}
                        </TableCell>

                        <TableCell align="right">
                          <Button
                          // onClick={() => handleDeleteFromCart(index)}
                          >
                            Edit
                          </Button>
                          <Button
                            color="error"
                            // onClick={() => handleDeleteFromCart(index)}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  )
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={12}>
                      No Data Available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <br />
          <br /> */}
          <Grid container spacing={3} className="tp-mb-20px">
            <Grid item md={3}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Deductible
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="deductible"
                  size="small"
                  type="text"
                  placeholder={`Enter Deductible Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.nameConf + "%"} size="small" className={data.nameConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.deductible || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('name')}
                  inputProps={{
                    "aria-label": "deductible",
                  }}
                  disabled={mainState?.isDisabled}
                  error={mainState?.data?.deductible === ""}
                />
                {mainState?.data?.deductible === "" && (
                  <FormHelperText error id="deductible">
                    {/* {!!mainState?.formError?.name} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Depreciation
                  {/* <span className="tp-text-subtitle">(No, Street, City, Municipality, Province, Zip Code)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="depreciation"
                  size="small"
                  type="text"
                  placeholder={`Enter Depreciation Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.addressConf + "%"} size="small" className={data.addressConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.depreciation || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('address')}
                  inputProps={{
                    "aria-label": "depreciation",
                  }}
                  disabled={mainState?.isDisabled}
                  error={mainState?.data?.depreciation === ""}
                />
                {mainState?.data?.depreciation === "" && (
                  <FormHelperText error id="depreciation">
                    {/* {!!mainState?.formError?.address} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Total Deductible and Depreciation
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="totalDeductibleDepre"
                  size="small"
                  type="text"
                  placeholder={`Enter Total Deductible and Depreciation Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.nameConf + "%"} size="small" className={data.nameConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.totalDeductibleDepre || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('name')}
                  inputProps={{
                    "aria-label": "totalDeductibleDepre",
                  }}
                  disabled={mainState?.isDisabled}
                  error={mainState?.data?.totalDeductibleDepre === ""}
                />
                {mainState?.data?.totalDeductibleDepre === "" && (
                  <FormHelperText error id="totalDeductibleDepre">
                    {/* {!!mainState?.formError?.name} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3} className="tp-mb-20px">
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  BIR ATP
                  {/* <span className="tp-text-subtitle">(No, Street, City, Municipality, Province, Zip Code)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="atp"
                  size="small"
                  type="text"
                  placeholder={`Enter BIR ATP Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.addressConf + "%"} size="small" className={data.addressConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.atp || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('address')}
                  inputProps={{
                    "aria-label": "atp",
                  }}
                  disabled={mainState?.isDisabled}
                  error={mainState?.data?.atp === ""}
                />
                {mainState?.data?.atp === "" && (
                  <FormHelperText error id="atp">
                    {/* {!!mainState?.formError?.address} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Net Amount
                  {/* <span className="tp-text-subtitle">(No, Street, City, Municipality, Province, Zip Code)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="netAmount"
                  size="small"
                  type="text"
                  placeholder={`Enter Net Amount Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.addressConf + "%"} size="small" className={data.addressConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.netAmount || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('address')}
                  inputProps={{
                    "aria-label": "netAmount",
                  }}
                  disabled={mainState?.isDisabled}
                  error={mainState?.data?.netAmount === ""}
                />
                {mainState?.data?.netAmount === "" && (
                  <FormHelperText error id="netAmount">
                    {/* {!!mainState?.formError?.address} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Vatable Sales
                  {/* <span className="tp-text-subtitle">(No, Street, City, Municipality, Province, Zip Code)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="vatableSales"
                  size="small"
                  type="text"
                  placeholder={`Enter Vatable Sales Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.addressConf + "%"} size="small" className={data.addressConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.vatableSales || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('address')}
                  inputProps={{
                    "aria-label": "vatableSales",
                  }}
                  disabled={mainState?.isDisabled}
                  error={mainState?.data?.vatableSales === ""}
                />
                {mainState?.data?.vatableSales === "" && (
                  <FormHelperText error id="vatableSales">
                    {/* {!!mainState?.formError?.address} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3} className="tp-mb-20px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Gross Amount
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="grossAmount"
                  size="small"
                  type="text"
                  placeholder={`Enter Gross Amount Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.nameConf + "%"} size="small" className={data.nameConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.grossAmount || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('name')}
                  inputProps={{
                    "aria-label": "grossAmount",
                  }}
                  disabled={mainState?.isDisabled}
                  error={mainState?.data?.grossAmount === ""}
                />
                {mainState?.data?.grossAmount === "" && (
                  <FormHelperText error id="grossAmount">
                    {/* {!!mainState?.formError?.name} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            {/* 
              // #region Vat Non Vat
            */}
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  VAT / Non VAT
                  {/* <span className="tp-text-subtitle">(No, Street, City, Municipality, Province, Zip Code)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <Select
                  id="vatNonVat"
                  placeholder="Please select VAT here..."
                  value={mainState?.vatSelections?.vatNonVat || null}
                  isClearable
                  onChange={handleVatSelectChange}
                  isDisabled={mainState?.isDisabled}
                  options={[
                    { value: "Vat", label: "VAT Registered" },
                    { value: "Non Vat", label: "Not VAT Registered" },
                  ]}
                />
                {mainState?.data?.vatNonVat === null ||
                  (mainState?.data?.vatNonVat === "" && (
                    <FormHelperText error id="vatNonVat">
                      {/* {mainState?.formError?.status} */}
                      Empty required field. Please select an item
                    </FormHelperText>
                  ))}
              </FormControl>
            </Grid>
          </Grid>
          <Typography variant="h6" className="tp-text-header-primary">
            For Processor
          </Typography>
          <hr />
          <FormControl variant="outlined" fullWidth className="tp-mb-20px">
            <Typography variant="body1" className="tp-text-bold">
              Status <span className="tp-text-required">*</span>
            </Typography>
            <Select
              id="status"
              placeholder="Please select status here..."
              value={mainState?.selections?.status || null}
              isClearable
              onChange={handleSelectChange}
              isDisabled={mainState?.isDisabled}
              options={[
                // { value: 1, label: "Open" },
                { value: 2, label: "Approve" },
                { value: 3, label: "Reject" },
              ]}
            />
            {mainState?.data?.status === null ||
              mainState?.data?.status === 0 ||
              (mainState?.data?.status === 1 && (
                <FormHelperText error id="status">
                  {/* {mainState?.formError?.status} */}
                  Required field. Please select an item
                </FormHelperText>
              ))}
          </FormControl>
          <FormControl variant="outlined" fullWidth className="tp-mb-30px">
            <Typography variant="body1" className="tp-text-bold">
              Remarks
            </Typography>
            <OutlinedInput
              id="remarks"
              size="small"
              type="text"
              multiline
              placeholder={`Enter Remarks Here...`}
              value={mainState?.data?.remarks || ""}
              onChange={handleInputChange}
              // onBlur={() => handleBlur('remarks')}
              inputProps={{
                "aria-label": "remarks",
              }}
              disabled={mainState?.isDisabled}
              // error={!!formError?.remarks}
            />
          </FormControl>
          <hr />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <Typography variant="h6" className="tp-text-header-primary">
              Login As User: &ensp;
              <span style={{ color: "#525252" }}>
                {toTitleCase(globalData?.firstName)}{" "}
                {toTitleCase(globalData?.lastName)}
              </span>
            </Typography>
            <Box
              sx={{
                display: "flex",
              }}
            >
              {disabled === true ? (
                <Button
                  onClick={() => {
                    handleNext();
                  }}
                  variant="contained"
                  size="medium"
                  color="primary"
                >
                  {" "}
                  Next{" "}
                </Button>
              ) : (
                <>
                  <TransferButton
                    docId={docId}
                    transId={transID}
                    endpoint={"/api/isip/document-validation/sales-invoice"}
                    documentType={"Sales Invoice"}
                    close={close}
                  />
                  {/* <Button
                    variant="contained"
                    size="medium"
                    color="warning"
                    disabled
                  >
                    Archive
                  </Button> */}
                  &emsp;
                  <Button
                    disabled={isFieldEmpty}
                    onClick={() => {
                      updateData();
                      // handleNext();
                    }}
                    // disabled={mainState?.isNotDone}
                    variant="contained"
                    size="medium"
                    color="error"
                  >
                    Save
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </>
      )}
    </div>
  );
}
