import React from "react";
import { Button, Grid, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";

interface CustomToolbarSelectProps {
  selectedRows: {
    data: { index: number; dataIndex: number }[];
  };
  data: any[];
}

const CustomToolbarSelect: React.FC<CustomToolbarSelectProps> = ({
  selectedRows,
  data,
}) => {
  const navigate = useNavigate();

  const handleEditClick = () => {
    // Assuming the first selected row should be edited
    const rowIndex = selectedRows.data[0].index;
    const user = data[rowIndex];
    navigate(`edit-user/${user.userName}`);
  };

  return (
    <React.Fragment>
      <Button
            variant="contained"
            color="warning"
            size="small"
            startIcon={<EditIcon />}
            onClick={handleEditClick}
              sx={{ my: 1, mx: 2 }}
          >
            Edit
          </Button>
    </React.Fragment>
  );
};

export default CustomToolbarSelect;
