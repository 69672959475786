import { Grid, TextField } from "@mui/material";
import { formatNumber } from "../../utils/stringUtils";

export default function IsipPolicyAsia({ policyAsiaData, setPolicyAsiaData }: any) {

  const handleInputChange = (e: any) => {
    const { value, id } = e.target;
    setPolicyAsiaData({ ...policyAsiaData, [id]: value });
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            id="claimNo"
            label="Claim Number"
            type="text"
            value={policyAsiaData?.claimNo || ""}
            onChange={handleInputChange}
            fullWidth
            size="medium"
            required
            // defaultValue="1"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="clientCode"
            label="Payee Code"
            type="text"
            value={policyAsiaData?.clientCode || ""}
            onChange={handleInputChange}
            fullWidth
            size="medium"
            required
            // defaultValue="1"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="clientName"
            label="Payee Name"
            type="text"
            value={policyAsiaData?.clientName || ""}
            onChange={handleInputChange}
            fullWidth
            size="medium"
            required
            // defaultValue="1"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Gross Amount"
            type="text"
            // onChange={handleInputChange}
            fullWidth
            value={formatNumber(policyAsiaData?.grossAmount) ?? 0}
            size="medium"
            required
            // defaultValue="1"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="vatAmount"
            label="VAT Amount"
            type="text"
            // onChange={handleInputChange}
            value={formatNumber(policyAsiaData?.vatAmount) ?? 0}
            fullWidth
            size="medium"
            required
            // defaultValue="1"
          />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <TextField
            id="witholdingTaxAmount"
            label="Witholding Tax Amount"
            type="text"
            onChange={handleInputChange}
            fullWidth
            size="medium"
            // defaultValue="1"
          />
        </Grid> */}
        <Grid item xs={12} md={6}>
          <TextField
            id="vatableSales"
            label="Vatable Sales"
            type="text"
            // onChange={handleInputChange}
            value={formatNumber(policyAsiaData?.vatableSales) ?? 0}
            fullWidth
            size="medium"
            required
            // defaultValue="1"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="deductible"
            label="Deductible"
            type="text"
            onChange={handleInputChange}
            value={formatNumber(policyAsiaData?.deductible) ?? 0}
            fullWidth
            size="medium"
            required
            // defaultValue="1"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="depreciation"
            label="Depreciation"
            type="text"
            onChange={handleInputChange}
            value={formatNumber(policyAsiaData?.depreciation) ?? 0}
            fullWidth
            size="medium"
            required
            // defaultValue="1"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="totalDeductibleDepre"
            label="Total Deductible and Depreciation"
            type="text"
            onChange={handleInputChange}
            value={formatNumber(policyAsiaData?.totalDeductibleDepre) ?? 0}
            fullWidth
            size="medium"
            required
            // defaultValue="1"
          />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <TextField
            id="category"
            label="Category"
            type="text"
            fullWidth
            // defaultValue="1"
          />
        </Grid> */}
      </Grid>
    </div>
  );
}
