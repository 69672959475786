import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridToolbarContainer,
  GridPreProcessEditCellProps,
} from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";
import * as React from "react";
import { v4 as uuidv4 } from "uuid";
// import { apiFetch } from 'robin-todo-list/src/api/RestApi';
// import estimationTableData from '../../data/estimationTableData.json';
const initialRows: GridRowsProp = [];

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
  headers: string[];
}
function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel, headers } = props;

  const handleClick = () => {
    const id = randomId();

    // Create a new row dynamically based on headers
    const newRow: { [key: string]: any } = { id: id, isNew: true }; // Type newRow to accept any string keys

    headers.forEach((header) => {
      newRow[header] = ""; // Initialize each field with an empty string
    });

    setRows((oldRows) => [...oldRows, newRow]);

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: headers[0] }, // Focus on the first header field
    }));
  };

  return (
    <GridToolbarContainer>
      <Grid container spacing={2}>
        <Grid item md={10}>
          <Typography
            variant="h6"
            className="tp-text-header-primary tp-padding-15px"
          >
            List of Victims
            <Typography
              variant="caption"
              display="block"
              className="tp-text-subtitle"
            ></Typography>
          </Typography>
        </Grid>
        <Grid item md={2} alignSelf="center" textAlign="center">
          <Button
            color="primary"
            size="small"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleClick}
          >
            Add Item
          </Button>
        </Grid>
      </Grid>
      {/* <GridToolbarExport /> */}
    </GridToolbarContainer>
  );
}

export default function VictimsTable({
  transID,
  mainState,
  victimsTable,
  setVictimsTable,
}: any) {
  const [rows, setRows] = React.useState(victimsTable?.rows || []);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  // Function to update rows and save to parent state
  const updateRows = (newRows: GridRowsProp) => {
    setVictimsTable((prev: any) => ({
      ...prev,
      rows: newRows,
    }));
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    const newRows = rows.filter((row: any) => row.id !== id);
    updateRows(newRows);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row: any) => row.id === id);
    if (editedRow!.isNew) {
      const newRows = rows.filter((row: any) => row.id !== id);
      updateRows(newRows);
    }

    console.log("handleCancelClick", rows);
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    const newRows = rows.map((row: any) =>
      row.id === newRow.id ? updatedRow : row
    );
    updateRows(newRows);

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  // const columns: GridColDef[] = [
  //   {
  //     field: "Name",
  //     headerName: "Name",
  //     headerAlign: "left",
  //     flex: 1,
  //     align: "left",
  //     editable: true,
  //   },
  //   {
  //     field: "Age",
  //     headerName: "Age",
  //     headerAlign: "center",
  //     align: "center",
  //     flex: 1,
  //     editable: true,
  //   },
  //   {
  //     field: "Address",
  //     headerName: "Address",
  //     headerAlign: "center",
  //     align: "center",
  //     flex: 1,
  //     editable: true,
  //   },
  //   {
  //     field: "Remarks",
  //     headerName: "Remarks",
  //     headerAlign: "center",
  //     align: "center",
  //     flex: 1,
  //     editable: true,
  //   },
  //   {
  //     field: "actions",
  //     type: "actions",
  //     headerName: "Actions",
  //     flex: 1,
  //     cellClassName: "actions",
  //     getActions: ({ id }) => {
  //       const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

  //       if (isInEditMode) {
  //         return [
  //           <GridActionsCellItem
  //             icon={<SaveIcon className="tp-primary-icon-btn" />}
  //             label="Save"
  //             sx={{
  //               color: "primary.main",
  //             }}
  //             onClick={handleSaveClick(id)}
  //           />,
  //           <GridActionsCellItem
  //             icon={<CancelIcon className="tp-danger-icon-btn" />}
  //             label="Cancel"
  //             className="textPrimary"
  //             onClick={handleCancelClick(id)}
  //             color="inherit"
  //           />,
  //         ];
  //       }

  //       return [
  //         <GridActionsCellItem
  //           icon={<EditIcon className="tp-primary-icon-btn" />}
  //           label="Edit"
  //           className="textPrimary"
  //           onClick={handleEditClick(id)}
  //           color="inherit"
  //         />,
  //         <GridActionsCellItem
  //           icon={<DeleteIcon className="tp-danger-icon-btn" />}
  //           label="Delete"
  //           onClick={handleDeleteClick(id)}
  //           color="inherit"
  //         />,
  //       ];
  //     },
  //   },
  // ];

  const columns: GridColDef[] = Array.isArray(victimsTable?.headers)
    ? victimsTable.headers.map((header: any) => {
        return {
          field: header,
          headerName: header,
          headerAlign: header === "Name" ? "left" : "center",
          align: header === "Name" ? "left" : "center",
          flex: 1,
          editable: true,
        };
      })
    : [];

  // Add the actions column separately if needed
  if (victimsTable?.headers > 0) {
    columns.push({
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon className="tp-primary-icon-btn" />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon className="tp-danger-icon-btn" />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon className="tp-primary-icon-btn" />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon className="tp-danger-icon-btn" />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    });
  }

  React.useEffect(() => {
    if (victimsTable?.rows) {
      setRows(victimsTable.rows);
    }
  }, [victimsTable]);

  return (
    <Box
      sx={{
        height: 424,
        width: "100%",
        "& .MuiDataGrid-columnHeader": {
          backgroundColor: "#a42a25",
          color: "#ffffff",
        },
      }}
    >
      <DataGrid
        rows={rows}
        getRowId={(row) => row.id}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: {
            setRows,
            setRowModesModel,
            headers: victimsTable?.headers,
          },
        }}
        disableRowSelectionOnClick
        disableColumnMenu
      />
    </Box>
  );
}
