import React, { useEffect, useState } from "react";
import AWS from "aws-sdk";
import axios from "axios";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress,
    Box,
    Snackbar,
    Alert
} from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import { formatDate } from '../api/FormatDate';

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET!;
const REGION = process.env.REACT_APP_REGION!;
const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY!;
const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY!;

AWS.config.update({
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
  region: REGION,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

interface UploadMissingProps {
  transId: string;
}

const UploadMissing: React.FC<UploadMissingProps> = ({ transId }) => {
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [uploading, setUploading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [uploadHistory, setUploadHistory] = useState<{ date: string, count: number }[]>([]);
    const currentDateTime = new Date();
    currentDateTime.setHours(currentDateTime.getHours() + 8);
    const formattedDateTime = currentDateTime.toISOString().slice(0, 19).replace('T', ' ');
    const [open, setOpen] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    type DocumentFields = {
        driverLicense: string;
        policeReport: string;
        certificateRegistration: string;
        officialReceipt: string;
        affidavit: string;
        motorvehicleForm: string;
        shopEstimate: string;
        otherDocument: string;
        otherDocument2: string;
    };

    const fetchUploadHistory = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/acap/form-builder?transId=${transId}`);
            const data = response.data.data.filter((item: any) => item.status === 0);
            const history = data.map((item: any) => {
                const urls = [
                    item.driverLicense,
                    item.policeReport,
                    item.certificateRegistration,
                    item.officialReceipt,
                    item.affidavit,
                    item.motorvehicleForm,
                    item.shopEstimate,
                    item.otherDocument,
                    item.otherDocument2,
                ];
                const count = urls.filter((url: string) => url !== "").length;
                return { date: formatDate(item.submittedDate), count };
            });
            setUploadHistory(history);
        } catch (error) {
            console.error('Error fetching upload history', error);
        }
    };

  useEffect(() => {
    fetchUploadHistory();
  }, [transId]);

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files);
      if (filesArray.length > 8) {
        setError("You can upload a maximum of 8 files.");
        return;
      }
      setSelectedFiles(filesArray);
    }
  };

  const getContentType = (file: File): string => {
    switch (file.type) {
      case "application/pdf":
        return "application/pdf";
      case "image/png":
        return "image/png";
      case "image/jpg":
        return "image/jpg";
      case "image/tiff":
        return "image/tiff";
      case "image/tif":
        return "image/tif";
      case "image/jpeg":
        return "image/jpeg";
      default:
        return "application/octet-stream";
    }
  };

  const uploadFiles = async (files: File[]) => {
    setUploading(true);
    const folderName = `ACAP-${transId}/Manual-Uploads`;
    const urls: string[] = [];

        for (const file of files) {
            const contentType = getContentType(file);
            const params = {
                ACL: 'public-read',
                Body: file,
                Bucket: S3_BUCKET,
                Key: `${folderName}/${file.name}`,
                ContentType: contentType,
            };

            await new Promise<void>((resolve, reject) => {
                myBucket.putObject(params).send((err) => {
                    if (err) {
                        reject(err);
                    } else {
                        const fileUrl = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${folderName}/${file.name}`;
                        urls.push(fileUrl);
                        resolve();
                    }
                });
            }).catch(() => {
                setMessage('Error uploading file.');
                setUploading(false);
                return;
            });
        }

        const data: DocumentFields & { transId: string; status: number; submittedDate: any } = {
            transId: transId,
            status: 0,
            driverLicense: urls[0] || '',
            policeReport: urls[1] || '',
            certificateRegistration: urls[2] || '',
            officialReceipt: urls[3] || '',
            affidavit: urls[4] || '',
            motorvehicleForm: urls[5] || '',
            shopEstimate: urls[6] || '',
            otherDocument: urls[7] || '',
            otherDocument2: urls[8] || '',
            submittedDate: formattedDateTime,
        };

        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/acap/form-builder`, data);
            setMessage('Your document(s) are "for Processing".');
            setOpen(false); // Close the modal
            fetchUploadHistory(); // Refresh upload history
        } catch (error) {
            setMessage('Error posting file URL(s).');
        }

    setUploading(false);
  };

  const handleUpload = () => {
    if (selectedFiles.length > 0) {
      uploadFiles(selectedFiles);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedFiles([]);
    setMessage("");
  };

  const handleCloseSnackbar = () => {
    setError("");
  };

  return (
    <div style={{ textAlign: "right" }}>
      <Button
        variant="contained"
        // color="primary"
        startIcon={<UploadIcon />}
        onClick={handleClickOpen}
        sx={{
          border: "1px solid",
          backgroundColor: "#1976d2",
          color: "#fff",
          ":hover": {
            backgroundColor: "#095eb3", // Change this to your desired hover color
          },
        }}
      >
        Upload Document
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Upload Documents</DialogTitle>
        <DialogContent>
          <input
            type="file"
            accept="application/pdf,image/png,image/jpg,image/jpeg,image/tiff,image/tif"
            multiple
            onChange={handleFileInput}
          />
          {uploading && <CircularProgress />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleUpload}
            color="primary"
            disabled={selectedFiles.length === 0 || uploading}
          >
            {uploading ? "Uploading..." : "Upload"}
          </Button>
        </DialogActions>
      </Dialog>
      {message && <p>{message}</p>}
      {uploadHistory.length > 0 && (
        <div>
          {uploadHistory.map((entry, index) => (
            <p key={index}>
              You uploaded {entry.count} document(s) on {entry.date}
            </p>
          ))}
        </div>
      )}
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default UploadMissing;
