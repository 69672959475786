import React, { useState, useRef, useEffect, useImperativeHandle, forwardRef } from 'react';
import { PDFDocument, rgb } from 'pdf-lib';
import { saveAs } from 'file-saver';
import * as pdfjsLib from 'pdfjs-dist';
import fontkit from '@pdf-lib/fontkit';
// import insurerPdf from '../assets/blank_insurer_reportv2.pdf';
import insurerPdf from '../assets/blank_insurer_report.pdf';
import boldFontUrl from '../assets/arial-bold.ttf';
import regularFontUrl from '../assets/arial.ttf';
import AWS from 'aws-sdk';
import axios from 'axios';
// import { RedeemTwoTone } from '@mui/icons-material';

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET!;
const REGION = process.env.REACT_APP_REGION!;
const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY!;
const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY!;

// Configure AWS S3
AWS.config.update({
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
});



const GenerateInsurer = forwardRef(({ repairersParts, repairersLabor, partsCart,
  totalAmount, laborPlusVat, totalPartsAmount, totalDepreciation,
  ourOfferGrossTotal, totalDeductible, laborData, transId }: any, ref) => {


  

  const [pdfBytes, setPdfBytes] = useState<Uint8Array | null>(null);
  const [isRendering, setIsRendering] = useState(false);
  const [uploadUrl, setUploadUrl] = useState<string | null>(null); 
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const repairersEstimate = repairersParts + repairersLabor;
  const repairersLaborVat = repairersLabor * 0.12;
  const repairersGrossAmount = repairersEstimate + repairersLaborVat;
  const ourOfferVat = laborPlusVat * 0.12;
  const approveGrossAmount = ourOfferGrossTotal + ourOfferVat;
  const approveGrossOffer = ourOfferGrossTotal - totalDeductible;

  const diffPartsAmount = repairersParts - totalAmount;
  const diffLaborAmount = repairersLabor - laborPlusVat;
  const diffTotalAmount = repairersEstimate - ourOfferGrossTotal;
  const diffVatAmount = repairersLaborVat - ourOfferVat;
  const diffGrossAmount = repairersGrossAmount - approveGrossAmount;

  const diffPartsPercentage = (diffPartsAmount / repairersParts) * 100;
  const diffLaborPercentage = (diffLaborAmount / repairersLabor) * 100;
  const diffTotalPercentage = (diffTotalAmount / repairersEstimate) * 100;
  const diffVatPercentage = (diffVatAmount / repairersLaborVat) * 100;
  const diffGrossPercentage = (diffGrossAmount / repairersGrossAmount) * 100;

 
  const [currentPage, setCurrentPage] = useState(1); // 


  const generatePdfBytes = async () => {
    try {
      const existingPdfBytes = await fetch(insurerPdf).then((res) => res.arrayBuffer());
      const boldFontBytes = await fetch(boldFontUrl).then((res) => res.arrayBuffer());
      const regularFontBytes = await fetch(regularFontUrl).then((res) => res.arrayBuffer());

      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      pdfDoc.registerFontkit(fontkit)

      const pages = pdfDoc.getPages();
      const firstPage = pages[0];
      const boldFont = await pdfDoc.embedFont(boldFontBytes);
      const regularFont = await pdfDoc.embedFont(regularFontBytes);
      const { width, height } = firstPage.getSize();

      const drawReverseText = (text: any, endX: any, y: any, font = regularFont, color = rgb(0, 0, 0)) => {
        const textWidth = font.widthOfTextAtSize(text, 7);
        firstPage.drawText(text, {
          x: endX - textWidth,
          y,
          size: 7,
          font: font,
          color: color,
        });
      };

      const drawReverseText2ndpage = (text: any, endX: any, y: any, font = regularFont, color = rgb(0, 0, 0)) => {
        const textWidth = font.widthOfTextAtSize(text, 7);
        secondPage.drawText(text, {
          x: endX - textWidth,
          y,
          size: 7,
          font: font,
          color: color,
        });
      };

      firstPage.drawText(`This is a draft Insurer Report.`, {
        x: 265,
        y: height - 94,
        size: 7,
        color: rgb(255 / 255, 1 / 255, 1 / 255),
      });

      firstPage.drawText(`CN208231`, {
        x: 131,
        y: height - 198,
        size: 7,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(`DIAMOND MOTOR CORPORATION`, {
        x: 131,
        y: height - 210,
        size: 7,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(`ROLANDO CABANILLA`, {
        x: 131,
        y: height - 236,
        size: 7,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(`08/06/2024`, {
        x: 131,
        y: height - 248,
        size: 7,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(`MXFE331423`, {
        x: 131,
        y: height - 276,
        size: 7,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(`MITSUBISHI MONTERO`, {
        x: 131,
        y: height - 288,
        size: 7,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(`23VSSGR3`, {
        x: 131,
        y: height - 300,
        size: 7,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(`Senior CLec`, {
        x: 131,
        y: height - 316,
        size: 7,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(`Senior Clec`, {
        x: 131,
        y: height - 328,
        size: 7,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(`08/16/2024`, {
        x: 456,
        y: height - 198,
        size: 7,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(`DP784899`, {
        x: 456,
        y: height - 235,
        size: 7,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(`OD`, {
        x: 456,
        y: height - 247,
        size: 7,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(`SUV - 5 DR`, {
        x: 456,
        y: height - 275,
        size: 7,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(`05/12/24`, {
        x: 456,
        y: height - 287,
        size: 7,
        color: rgb(0, 0, 0),
      });


      firstPage.drawText(`SDFSEBGC213123`, {
        x: 456,
        y: height - 300,
        size: 7,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(`Senior Clec`, {
        x: 456,
        y: height - 316,
        size: 7,
        color: rgb(0, 0, 0),
      });


      firstPage.drawText(`08/16/2024`, {
        x: 456,
        y: height - 328,
        size: 7,
        color: rgb(0, 0, 0),
      });

      // Total Offer Part
      firstPage.drawText(`Senior Clec ON August 16, 2024 9:23`, {
        x: 156,
        y: height - 382,
        size: 7,
        color: rgb(0, 0, 0),
      });


      // Replace 'P' with '₱' in the drawReverseText calls
      drawReverseText(`₱${repairersEstimate.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 246, height - 391, boldFont, rgb(0, 0, 0));
      drawReverseText(`₱${ourOfferGrossTotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 246, height - 403, boldFont, rgb(0, 0, 0));
      drawReverseText(`₱${ourOfferVat.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 246, height - 411, regularFont, rgb(0, 0, 0));
      drawReverseText(`₱${approveGrossAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 246, height - 424, boldFont, rgb(0, 0, 0));
      drawReverseText(`₱${totalDeductible.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 246, height - 433, regularFont, rgb(0, 0, 0));
      drawReverseText(`₱${approveGrossOffer.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 246, height - 444.5, boldFont, rgb(0, 0, 0));
      drawReverseText(`- Depreciation:                                                                      ₱${totalDepreciation.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 246, height - 454.5, regularFont, rgb(0, 0, 0));

      // Cost of claims repairers part
      drawReverseText(`₱${repairersParts.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 386, height - 493, regularFont, rgb(0, 0, 0));
      drawReverseText(`₱${repairersLabor.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 386, height - 502, regularFont, rgb(0, 0, 0));
      drawReverseText(`₱${repairersEstimate.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 386, height - 516, boldFont, rgb(0, 0, 0));
      drawReverseText(`₱${repairersLaborVat.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 386, height - 525, regularFont, rgb(0, 0, 0));
      drawReverseText(`₱${repairersGrossAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 386, height - 540, boldFont, rgb(0, 0, 0));

      // Cost of claims our offer part
      drawReverseText(`₱${totalAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 458, height - 493, regularFont, rgb(0, 0, 0));
      drawReverseText(`₱${laborPlusVat.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 458, height - 502, regularFont, rgb(0, 0, 0));
      drawReverseText(`₱${ourOfferGrossTotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 458, height - 516, boldFont, rgb(0, 0, 0));
      drawReverseText(`₱${ourOfferVat.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 458, height - 525, regularFont, rgb(0, 0, 0));
      drawReverseText(`₱${approveGrossAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 458, height - 540, boldFont, rgb(0, 0, 0));

      // Cost of claims difference part
      drawReverseText(`₱${diffPartsAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 537, height - 493, regularFont, rgb(0, 0, 0));
      drawReverseText(`₱${diffLaborAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 537, height - 502, regularFont, rgb(0, 0, 0));
      drawReverseText(`₱${diffTotalAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 537, height - 516, boldFont, rgb(0, 0, 0));
      drawReverseText(`₱${diffVatAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 537, height - 525, regularFont, rgb(0, 0, 0));
      drawReverseText(`₱${diffGrossAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 537, height - 540, boldFont, rgb(0, 0, 0));



      // Cost of claims difference % part
      drawReverseText(`${diffPartsPercentage.toFixed(2)}`, 578, height - 493, regularFont, rgb(0, 0, 0));
      drawReverseText(`${diffLaborPercentage.toFixed(2)}`, 578, height - 502, regularFont, rgb(0, 0, 0));
      drawReverseText(`${diffTotalPercentage.toFixed(2)}`, 578, height - 516, boldFont, rgb(0, 0, 0));
      drawReverseText(`${diffVatPercentage.toFixed(2)}`, 578, height - 525, regularFont, rgb(0, 0, 0));
      drawReverseText(`${diffGrossPercentage.toFixed(2)}`, 578, height - 540, boldFont, rgb(0, 0, 0));





      // Add a second page
      const secondPage = pages[1];

      let yPosition = height - 173;
      const lineHeight = 10;
      const drawText = (text: any, x: any, y: any, font = regularFont, color = rgb(0, 0, 0)) => {
        secondPage.drawText(text, {
          x,
          y,
          size: 7,
          font: font,
          color: color,
        });
      };

      partsCart.forEach((part: any, index: any) => {
        drawText(`${index + 1}`, 29, yPosition); // No.
        drawText(`${part.qty}`, 44.9, yPosition); // Quantity
        drawText(`${part.name}`, 138, yPosition); // PartName
        drawReverseText2ndpage(`${part.amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 580, yPosition, boldFont, rgb(0, 0, 0)); // Amount

        yPosition -= lineHeight; // Move y position for the next entry
      });

      // Dynamic line positioning based on partsCart length
      const lineYPosition = yPosition - 5;
      secondPage.drawLine({
        start: { x: 29, y: lineYPosition },
        end: { x: 580, y: lineYPosition },
        thickness: 1,
        color: rgb(0, 0, 0),
      });

      // Dynamic positioning for totalPartsAmount
      const totalYPosition = lineYPosition - 15;
      
      // Add "Total Parts (P)" label in red bold font
      drawText("Total Parts (P)", 138, totalYPosition, boldFont, rgb(1, 0, 0));
      
      drawReverseText2ndpage(`₱${totalPartsAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 580, totalYPosition, boldFont, rgb(0, 0, 0));

      const thirdPage = pages[2];

      const drawText3 = (text: any, x: any, y: any, font = regularFont, color = rgb(0, 0, 0)) => {
        thirdPage.drawText(text, {
          x,
          y,
          size: 7,
          font: font,
          color: color,
        });
      };

      const drawReverseText3rdpage = (text: any, endX: any, y: any, font = regularFont, color = rgb(0, 0, 0)) => {
        const textWidth = font.widthOfTextAtSize(text, 7);
        thirdPage.drawText(text, {
          x: endX - textWidth,
          y,
          size: 7,
          font: font,
          color: color,
        });
      };

      let laborYPosition = height - 113; // Adjust this value as needed
      const laborLineHeight = 10;
 

      if (laborData && Array.isArray(laborData.rows)) {
        laborData.rows.forEach((row:any, index:any) => {
          const total = row.repair + row.replace + row.painting;
          drawText3(`${index + 1}`, 29, laborYPosition);
          drawText3(row.scopeOfWorks, 50, laborYPosition);
          drawText3(row.repairCondition, 150, laborYPosition);
          drawReverseText3rdpage(`${row.repair.toFixed(2)}`, 300, laborYPosition);
          drawReverseText3rdpage(`${row.replace.toFixed(2)}`, 400, laborYPosition);
          drawReverseText3rdpage(`${row.painting.toFixed(2)}`, 500, laborYPosition);
          drawReverseText3rdpage(`${total.toFixed(2)}`, 580, laborYPosition);

          laborYPosition -= laborLineHeight;
        });
      }

      // Draw totals
      laborYPosition -= 20;
      drawText3("Total Time (hrs)", 50, laborYPosition);
      drawReverseText3rdpage(`${laborData.totalTimeHours.toFixed(2)}`, 580, laborYPosition);

      laborYPosition -= laborLineHeight;
      drawText3("Labour Rates (₱/hr)", 50, laborYPosition);
      drawReverseText3rdpage(`${laborData.laborRate.toFixed(2)}`, 580, laborYPosition);

      laborYPosition -= laborLineHeight;
      drawText3("Calculated Labour Cost (₱)", 50, laborYPosition);
      drawReverseText3rdpage(`${laborData.calculatedLaborCost.toFixed(2)}`, 580, laborYPosition);

      laborYPosition -= laborLineHeight;
      drawText3("+ Paintwork Materials (₱)", 50, laborYPosition);
      drawReverseText3rdpage(`${laborData.paintWorkMaterials.toFixed(2)}`, 580, laborYPosition);

      laborYPosition -= laborLineHeight;
      drawText3("Total Labour Cost (₱)", 50, laborYPosition, boldFont);
      drawReverseText3rdpage(`${laborData.totalLaborCost.toFixed(2)}`, 580, laborYPosition, boldFont);
     

      const pdfBytes = await pdfDoc.save();
      setPdfBytes(pdfBytes);
      //console.log("PDF Bytes Generated:", pdfBytes);

      // Directly preview the PDF after generating it
      previewPdf(new Uint8Array(pdfBytes), 1);
      return pdfBytes;
    } catch (error) {
      console.error("Error generating PDF bytes:", error);
    }
  };

  const uploadPdfToS3 = async (pdfBytes: Uint8Array) => {
    const s3 = new AWS.S3();
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().replace(/[-:.TZ]/g, '');
    const fileName = `ACAP_Insurer_Report_${formattedDate}.pdf`;
    const folderName = `ACAP-${transId}`;

    const params = {
      Bucket: S3_BUCKET,
      Key:`${folderName}/${fileName}`,
      Body: new Blob([pdfBytes], { type: 'application/pdf' }),
      ACL: 'public-read',
      ContentType: 'application/pdf',
    };

    try {
      const data = await s3.upload(params).promise();
      console.log("File uploaded successfully:", data.Location);
      setUploadUrl(data.Location);
      return data.Location;
    } catch (error) {
      console.error("Error uploading PDF to S3:", error);
      return null;
    }
  };

  const sendPostRequest = async (pdfUrl: string) => {
    const requestBody = {
      transId: transId,
      url: pdfUrl,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/acap/insurer-report`, requestBody);
      console.log("Post request successful:", response.data);
    } catch (error) {
      console.error("Error making post request:", error);
    }
  };

  const handleGenerateAndUploadPdf = async () => {
    
    const pdfBytes = await generatePdfBytes();
    console.log('pdfbytes',pdfBytes);
    if (pdfBytes != null) {
      const pdfUrl = await uploadPdfToS3(pdfBytes);
      if (pdfUrl) {
        await sendPostRequest(pdfUrl);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    generateAndUploadPdf: handleGenerateAndUploadPdf,
  }));


  const previewPdf = async (bytes: Uint8Array, pageNum: number) => {
    if (!bytes) return;

    if (isRendering) return; // Prevent overlapping render operations
    setIsRendering(true);

    const loadingTask = pdfjsLib.getDocument({ data: bytes });
    const pdf = await loadingTask.promise;
    const page = await pdf.getPage(pageNum);

    const viewport = page.getViewport({ scale: 1 });
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');

    if (canvas && context) {
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };
      await page.render(renderContext).promise;
    }

    setIsRendering(false); // Mark rendering as completed
  };

  useEffect(() => {
    if (pdfBytes) {
      previewPdf(new Uint8Array(pdfBytes), currentPage); // Clone the pdfBytes for preview and specify the page number
    }
  }, [pdfBytes, currentPage]);

  const savePdf = () => {
    if (pdfBytes) {
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().replace(/[-:.TZ]/g, '');
      const fileName = `ACAP Insurer Report_${formattedDate}.pdf`;
      saveAs(blob, fileName);
      //console.log("PDF Saved", blob);
    } else {
      console.error("No PDF bytes to save.");
    }
  };

  return (
    <div>
      {/* <h1>Generate PDF</h1> */}
      <form>

        <button type="button" onClick={generatePdfBytes}>Preview PDF</button>
        <button type="button" onClick={() => setCurrentPage(1)}>Preview Page 1</button>
        <button type="button" onClick={() => setCurrentPage(2)}>Preview Page 2</button>
        <button type="button" onClick={() => setCurrentPage(3)}>Preview Page 3</button>
        <button type="button" onClick={savePdf}>Save PDF</button>
        <button type="button" onClick={handleGenerateAndUploadPdf}>Generate, Upload & Send</button>
      </form>
      <canvas ref={canvasRef} style={{ border: '1px solid black', marginTop: '20px' }}></canvas>
      {uploadUrl && <p>Uploaded PDF URL: <a href={uploadUrl} target="_blank" rel="noopener noreferrer">{uploadUrl}</a></p>}
    </div>
  );
});

export default GenerateInsurer;
