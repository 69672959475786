import * as React from 'react';
import {
  IconButton,
  Typography,
  Grid,
  Box,
  TextField,
  MenuItem,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  Select,
  SelectChangeEvent,
  InputLabel,
  CircularProgress,
  FormControl,
  Modal
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import reserveCodes from './reserveCode';
import { GlobalDataContext } from '../contexts/GlobalDataContext';
import { useNavigate } from 'react-router-dom';

function toTitleCase(str: string | undefined | null): string | undefined {
  if (!str) {
    return str as undefined;
  }

  return str.replace(/\w\S*/g, (word) => {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
  });
}

const ClaimRegistration = ({ transId, policyNo, claimRegistrationData, setClaimRegistrationData, isValidated }: any) => {
  const [premiumClass, setPremiumClass] = React.useState('202');
  const [claimStatus, setClaimStatus] = React.useState('Incoming Claim');
  const [reserveCode, setReserveCode] = React.useState('');
  const [estimationAmount, setEstimationAmount] = React.useState('');
  const [entries, setEntries] = React.useState<{ premiumClass: string; reserveCode: string; estimationAmount: string; }[]>([]);
  const [claimNumber, setClaimNumber] = React.useState<string>('');
  const [claimModalOpen, setClaimModalOpen] = React.useState(false);
  const [registering, setRegistering] = React.useState(false);
  const [docComplete, setDocComplete] = React.useState<number>(0);
  const [docStatus, setDocStatus] = React.useState<number>(1);
  const [documentValidationData, setDocumentValidationData] = React.useState<any[]>([]);
  const { globalData } = React.useContext(GlobalDataContext);
  const [claimDescription, setClaimDescription] = React.useState('');
  const [riskNumber, setRiskNumber] = React.useState<string>('1');
  const [majorEventCode, setMajorEventCode] = React.useState<string>('M02');
  const [dateOccurs, setDateOccurs] = React.useState<string>('2024-06-02T02:57:41.473Z');
  const [dateReported, setDateReported] = React.useState<string>('2024-03-01T02:57:41.473Z');

  const handleChange = (event: SelectChangeEvent<string>) => {
    setClaimDescription(event.target.value);
  }; 
  // console.log('policy no from CR', policyNo);

  const premiumClasses = [
    { value: 200, label: '200 - COMPULSORY PASS. LEGAL LIAB' },
    { value: 201, label: '201 - COMPULSORY TPL - BODILY INJURY' },
    { value: 202, label: '202 - OWN DAMAGE' },
    { value: 203, label: '203 - THEFT - Carnap' },
    { value: 203, label: '203 - THEFT - Partial Theft' },
    { value: 205, label: '205 - VTPL - BODILY INJURY' },
    { value: 206, label: '206 - VTPL - PROPERTY DAMAGE' },
    { value: 206, label: '206 - VTPL - PROPERTY DAMAGE Other than Vehicle' },
    { value: 207, label: '207 - AUTO PERSONAL ACCIDENT' },
    { value: 208, label: '208 - FLOOD AND TYPHOON' },
    { value: 209, label: '209 - ACTS OF NATURE' },
    { value: 210, label: '210 - NEW FOR OLD' },
    { value: 211, label: '211 - STRIKES, RIOTS, CIVIL COMMOTIONS' },
    { value: 212, label: '212 - 24-HOUR ROADSIDE ASSISTANCE' },
    { value: 213, label: '213 - EXCESS PASSENGER LIABILITY' },
    { value: 214, label: '214 - ALTERNATIVE TRANSPO ALLOWANCE' },
    { value: 215, label: '215 - Extended Coverage' },
    { value: 216, label: '216 - CSL VTPL-BI/PD' }
  ];

  const navigate = useNavigate();

  const claimDescriptions = [
    "Bumped a pedestrian",
    "Bumped another property",
    "Bumped by another vehicle",
    "Carnap- by use of force",
    "Carnap- while parked",
    "Carnap- failed to return",
    "Collision",
    "Damage due to AON",
    "Damage due to Riot, Strike and Civil Commotion",
    "Damage while parked unattended",
    "Damage windshield",
    "Hit another vehicle",
    "Animal Bites",
    "Hit an Animal",
    "Partial theft",
    "Self-accident",
    "Fire loss",
    "Vandalism",
    "Others"
  ];

  const claimStatuses = [
    { value: "Incoming Claim", label: "Incoming Claim" },
    { value: "Pending Inhouse Survey Clec", label: "Pending Inhouse Survey Clec" },
    { value: "Pending Inhouse Survey IMSI", label: "Pending Inhouse Survey IMSI" },
    { value: "Pending Documents", label: "Pending Documents" },
    { value: "Pending for Offer", label: 'Pending for Offer' },
  ]


  const docStatusOptions = [
    { value: 1, label: 'Pending Documents' },
    { value: 2, label: "Pending Inhouse Survey Clec" },
    { value: 5, label: "Pending Inhouse Survey IMSI" },
    { value: 6, label: 'Pending for Offer' },
    { value: 3, label: "Pending Adjuster's Report" },
  ];

  const handlePremiumClassChange = (event: any) => {
    setPremiumClass(event.target.value);
    setReserveCode(''); // Reset reserve code when premium class changes
  };

  const handleClaimStatusChange = (event: any) => {
    setClaimStatus(event.target.value);
  }

  const handleReserveCodeChange = (event: any) => {
    setReserveCode(event.target.value);
  };

  const handleEstimationAmountChange = (event: any) => {
    setEstimationAmount(event.target.value);
  };

  const handleAddEntry = () => {
    if (entries.length < 8) {
      setEntries([
        ...entries,
        {
          premiumClass: premiumClass,
          reserveCode: reserveCode,
          estimationAmount: parseFloat(estimationAmount).toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
        }
      ]);
      setPremiumClass('');
      setReserveCode('');
      setEstimationAmount('');
    }
  };

  const handleDeleteEntry = (index: any) => {
    setEntries(entries.filter((entry, i) => i !== index));
  };

  const filteredReserveCodes = reserveCodes.filter(code => code.premiumClass === Number(premiumClass));

  const isDisabled = entries.length >= 8;

  const handleSubmit = async () => {
    setRegistering(true);

    const validPremiumClasses = [202, 203, 206, 208, 209, 211, 216];
    const allValid = entries.every(entry => validPremiumClasses.includes(Number(entry.premiumClass)));

    const currentDateTime = new Date();
    currentDateTime.setHours(currentDateTime.getHours() + 8);
    const formattedDateTime = currentDateTime.toISOString().slice(0, 19).replace('T', ' ');

    // Constructing the claims payload dynamically
    const claimsPayload = {
      claimRiskNumber: riskNumber,
      policyNumber: policyNo,
      claimStatus: claimStatus,
      dateOccurs: dateOccurs,
      claimDescription: claimDescription,
      dateReported: dateReported,
      subrogation: "N",
      claimMajoreventCode: majorEventCode,
      balanceOutstanding01: entries[0]?.estimationAmount || "",
      balanceOutstanding02: entries[1]?.estimationAmount || "",
      balanceOutstanding03: entries[2]?.estimationAmount || "",
      balanceOutstanding04: entries[3]?.estimationAmount || "",
      balanceOutstanding05: entries[4]?.estimationAmount || "",
      balanceOutstanding06: entries[5]?.estimationAmount || "",
      balanceOutstanding07: entries[6]?.estimationAmount || "",
      balanceOutstanding08: entries[7]?.estimationAmount || "",
      premium01: entries[0]?.premiumClass || "",
      premium02: entries[1]?.premiumClass || "",
      premium03: entries[2]?.premiumClass || "",
      premium04: entries[3]?.premiumClass || "",
      premium05: entries[4]?.premiumClass || "",
      premium06: entries[5]?.premiumClass || "",
      premium07: entries[6]?.premiumClass || "",
      premium08: entries[7]?.premiumClass || "",
      reserveCode01: entries[0]?.reserveCode || "",
      reserveCode02: entries[1]?.reserveCode || "",
      reserveCode03: entries[2]?.reserveCode || "",
      reserveCode04: entries[3]?.reserveCode || "",
      reserveCode05: entries[4]?.reserveCode || "",
      reserveCode06: entries[5]?.reserveCode || "",
      reserveCode07: entries[6]?.reserveCode || "",
      reserveCode08: entries[7]?.reserveCode || ""
    };

    try {
      const signatureResponse = await fetch(`${process.env.REACT_APP_SIGNATURE_ENDPOINT}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data: {
            claims: claimsPayload
          },
          signature: ""
        })
      });

      if (!signatureResponse.ok) {
        throw new Error(`Error: ${signatureResponse.statusText}`);
      }

      const signatureData = await signatureResponse.json();
      const signature = signatureData.data;

      const claimRegister = await fetch(`${process.env.REACT_APP_CLAIM_ENDPOINT}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data: {
            claims: claimsPayload
          },
          signature: signature
        })
      });

      if (!claimRegister.ok) {
        throw new Error(`Error: ${claimRegister.statusText}`);
      }

      const claimRegisterData = await claimRegister.json();
      setClaimNumber(claimRegisterData.data.claimNumber);
      setClaimModalOpen(true);

      // Additional POST request after claim registration
      const claimRegistrationPayload = {
        transId: transId,
        saveDate: formattedDateTime,
        status: 1, // Set the status to 1 as default
        policyNumber: policyNo,
        claimNumber: claimRegisterData.data.claimNumber,
        riskNumber: riskNumber,
        dateLoss: dateOccurs,
        dateReported: dateReported,
        claimDesc: claimDescription,
        claimMajoreventcode: majorEventCode,
        claimStatus: claimStatus,
        subrogation: "N",
        balanceOutstanding01: entries[0]?.estimationAmount || "",
        balanceOutstanding02: entries[1]?.estimationAmount || "",
        balanceOutstanding03: entries[2]?.estimationAmount || "",
        balanceOutstanding04: entries[3]?.estimationAmount || "",
        balanceOutstanding05: entries[4]?.estimationAmount || "",
        balanceOutstanding06: entries[5]?.estimationAmount || "",
        balanceOutstanding07: entries[6]?.estimationAmount || "",
        balanceOutstanding08: entries[7]?.estimationAmount || "",
        premiumClass01: entries[0]?.premiumClass || "",
        premiumClass02: entries[1]?.premiumClass || "",
        premiumClass03: entries[2]?.premiumClass || "",
        premiumClass04: entries[3]?.premiumClass || "",
        premiumClass05: entries[4]?.premiumClass || "",
        premiumClass06: entries[5]?.premiumClass || "",
        premiumClass07: entries[6]?.premiumClass || "",
        premiumClass08: entries[7]?.premiumClass || "",
        reserveCode01: entries[0]?.reserveCode || "",
        reserveCode02: entries[1]?.reserveCode || "",
        reserveCode03: entries[2]?.reserveCode || "",
        reserveCode04: entries[3]?.reserveCode || "",
        reserveCode05: entries[4]?.reserveCode || "",
        reserveCode06: entries[5]?.reserveCode || "",
        reserveCode07: entries[6]?.reserveCode || "",
        reserveCode08: entries[7]?.reserveCode || "",
        claimFilingapprovaltime: formattedDateTime // Default to null
      };

      const claimRegistrationResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/claim-registration`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(claimRegistrationPayload)
      });

      if (!claimRegistrationResponse.ok) {
        throw new Error(`Error: ${claimRegistrationResponse.statusText}`);
      }

    

      if (allValid) {
        // Handle valid premium classes
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/task`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              "transId": transId,
              "deId": transId,
              "sender": "peterbf221@gmail.com",
              "saveDate": formattedDateTime,
              "taskType": "Damage Evaluation",
              "peril": null,
              "channel": 1,
              "priority": 0,
              "priorityDesc": null,
              "policyNo": claimRegisterData.data.policyNumber,
              "claimNo": claimRegisterData.data.claimNumber,
              "status": 3,
              "userDetails": {
                "id": 89
              }
            })
          });
          await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap-task/${transId}`, {
            method: 'PUT',
            body: JSON.stringify({ status: 30, claimNo: claimRegisterData.data.claimNumber }),
            headers: {
              'Content-Type': 'application/json',
            },
          });
    
          await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap-task/approve?id=${transId}`, {
            method: 'POST',
          });

          if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
          }

          console.log('Task created successfully');
        } catch (error) {
          console.error('Error creating task:', error);
        }
      } else {
        await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap-task/${transId}`, {
          method: 'PUT',
          body: JSON.stringify({ status: 2, claimNo: claimRegisterData.data.claimNumber }),
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
        await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap-task/approve?id=${transId}`, {
          method: 'POST',
        });
      }

      // POST request for document validation
      const uniquePerilCodes = Array.from(new Set(entries.map(entry => Number(entry.premiumClass))));
      // console.log('Unique peril codes:', uniquePerilCodes);

      for (const perilCode of uniquePerilCodes) {
        try {
          const docValidationResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/docvalidation`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              transId: transId,
              perilCode: perilCode,
              perilType: premiumClasses.find(pc => pc.value === Number(perilCode))?.label || '',
              docComplete: 0, // Default value
              status: 1 // Default value
            })
          });

          if (!docValidationResponse.ok) {
            throw new Error(`Error: ${docValidationResponse.statusText}`);
          }
        } catch (error) {
          console.error('Error during document validation POST request:', error);
        }
      }

      // GET request to fetch document validation data
      try {
        const docValidationGetResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/docvalidation/${transId}`);
        if (!docValidationGetResponse.ok) {
          throw new Error(`Error: ${docValidationGetResponse.statusText}`);
        }
        const validationData = await docValidationGetResponse.json();
        console.log('Document validation data:', validationData);
        setDocumentValidationData(validationData);
      } catch (error) {
        console.error('Error fetching document validation data:', error);
      }

      // Fetch claim registration data after successful submission
      try {
        const claimRegistrationResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/claim-registration?transId=${transId}`);
        if (!claimRegistrationResponse.ok) {
          throw new Error(`Error: ${claimRegistrationResponse.statusText}`);
        }
        const claimData = await claimRegistrationResponse.json();
        console.log('Claim registration data:', claimData);
        setClaimRegistrationData(claimData);
      } catch (error) {
        console.error('Error fetching claim registration data:', error);
      }
    } catch (error) {
      console.error('Error claim registration:', error);
      alert('Failed to register the claim');
    } finally {
      await fetchClaimRegistrationData();
      setRegistering(false);
    }
  };

  // Fetch document validation data on component mount
  const fetchClaimRegistrationData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/claim-registration?transId=${transId}`);
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const data = await response.json();
      setClaimRegistrationData(data.data);
      console.log('Claim registration data:', data.data);
    } catch (error) {
      console.error('Error fetching claim registration data:', error);
    }
  };

  const fetchDocumentValidationData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/docvalidation/${transId}`);
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const data = await response.json();
      setDocumentValidationData(data);
    } catch (error) {
      console.error('Error fetching document validation data:', error);
    }
  };
  React.useEffect(() => {
    

    fetchDocumentValidationData();
    fetchClaimRegistrationData();
  }, [transId]); // Run only when transId changes

  // Handle document validation submission for each row
  const handleDocumentValidationSubmit = async (data: any) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/docvalidation/${data.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          transId: transId,
          docComplete: data.docComplete,
          status: data.status,
          perilCode: data.perilCode,
          perilType: data.perilType
        })
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      alert('Document validation updated successfully');
      navigate(-1);

      // Refresh the data after successful update
      await fetchDocumentValidationData();
    } catch (error) {
      console.error('Error submitting document validation:', error);
      alert('Failed to submit document validation');
    }
  };

  // Map claimRegistrationData to entries for table display
  React.useEffect(() => {
    if (claimRegistrationData) {
      const newEntries = [];
      for (let i = 1; i <= 8; i++) {
        const premiumClassKey = `premiumClass0${i}`;
        const reserveCodeKey = `reserveCode0${i}`;
        const balanceOutstandingKey = `balanceOutstanding0${i}`;

        const premiumClass = claimRegistrationData[premiumClassKey];
        const reserveCode = claimRegistrationData[reserveCodeKey];
        const balanceOutstanding = claimRegistrationData[balanceOutstandingKey];

        if (premiumClass && reserveCode && balanceOutstanding) {
          newEntries.push({
            premiumClass,
            reserveCode,
            estimationAmount: balanceOutstanding,
          });
        }
      }
      setEntries(newEntries);
    }
  }, [claimRegistrationData]);

  return (
    <div>
      {claimRegistrationData ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="body1">Date of Loss: {new Date(claimRegistrationData.dateLoss).toLocaleDateString()}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1">Time of Loss: {new Date(claimRegistrationData.dateLoss).toLocaleTimeString()}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1">Report Date: {new Date(claimRegistrationData.dateReported).toLocaleDateString()}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1">Claim Description: {claimRegistrationData.claimDesc}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1">Risk Number: {claimRegistrationData.riskNumber}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1">Major Event Code: {claimRegistrationData.claimMajoreventcode}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1">Claim Status: {claimRegistrationData.claimStatus}</Typography>
            </Grid>
          </Grid>
          <br />

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Premium Class</TableCell>
                  <TableCell>Reserve Code</TableCell>
                  <TableCell>Estimation Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {entries.map((entry, index) => (
                  <TableRow key={index}>
                    <TableCell>{premiumClasses.find(pc => pc.value === Number(entry.premiumClass))?.label}</TableCell>
                    <TableCell>{reserveCodes.find(rc => rc.value === entry.reserveCode)?.label}</TableCell>
                    <TableCell>{entry.estimationAmount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 2,
            }}
          >
            <Typography variant="h6" color="primary">
              Claim Number: {claimRegistrationData.claimNumber}
            </Typography>
          </Box>
        </>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Date of Loss"
                type="date"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setDateOccurs(e.target.value ? new Date(e.target.value).toISOString() : "")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Time of Loss"
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Report Date"
                type="date"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setDateReported(e.target.value ? new Date(e.target.value).toISOString() : "")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Claim Description</InputLabel>
                <Select
                  value={claimDescription}
                  onChange={handleChange}
                  label="Claim Description"
                  fullWidth
                >
                  {claimDescriptions.map((description) => (
                    <MenuItem key={description} value={description}>
                      {description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Risk Number"
                type="text"
                fullWidth
                defaultValue="1"
                onChange={(e) => setRiskNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Major Event Code"
                type="text"
                fullWidth
                defaultValue="M02"
                onChange={(e) => setMajorEventCode(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                label="Premium Class"
                value={premiumClass}
                onChange={handlePremiumClassChange}
                fullWidth
                disabled={isDisabled}
              >
                {premiumClasses.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                label="Reserve Code"
                value={reserveCode}
                onChange={handleReserveCodeChange}
                fullWidth
                disabled={!premiumClass || isDisabled}
              >
                {filteredReserveCodes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Estimation Amount"
                type="number"
                fullWidth
                value={estimationAmount}
                onChange={handleEstimationAmountChange}
                disabled={isDisabled}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                color="error"
                variant="contained"
                onClick={handleAddEntry}
                disabled={!premiumClass || !reserveCode || !estimationAmount || isDisabled}
              >
                Add
              </Button>
              <Typography variant="body2" color="blue" sx={{ ml: 2 }}>
                Maximum Entry: {8 - entries.length}
              </Typography>
            </Grid>
          </Grid>
          <br />

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Premium Class</TableCell>
                  <TableCell>Reserve Code</TableCell>
                  <TableCell>Estimation Amount</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {entries.map((entry, index) => (
                  <TableRow key={index}>
                    <TableCell>{premiumClasses.find(pc => pc.value === Number(entry.premiumClass))?.label}</TableCell>
                    <TableCell>{reserveCodes.find(rc => rc.value === entry.reserveCode)?.label}</TableCell>
                    <TableCell>{entry.estimationAmount}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleDeleteEntry(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid item xs={12} md={6} className="tp-mb-20px" style={{ marginTop: '20px' }}>
            <TextField
              select
              label="Claim Status"
              value={claimStatus}
              onChange={handleClaimStatusChange}
              fullWidth
            >
              {claimStatuses.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 2,
            }}
          >
            <Button
              className='tp-custom-button-primary'
              variant="contained"
              disabled={!isValidated}
              onClick={handleSubmit}
              fullWidth
              sx={{ maxWidth: 300 }}
            >
              Submit
            </Button>
          </Box>
        </>
      )}

      <hr className="tp-border-hr_primary" /><br />

      {documentValidationData && (
        <Typography variant="h6" className="tp-text-header-primary" sx={{ mb: 2 }}>
          Document Validation Details
        </Typography>
      )}

      {documentValidationData && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Peril Type</TableCell>
       
                <TableCell>Status</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documentValidationData.map((data: any, index: number) => (
                <TableRow key={data.id}>
                  <TableCell>{data.perilType}</TableCell>
                  <TableCell>
                    <Select
                      value={data.status}
                      onChange={(e) => {
                        const newValue = Number(e.target.value);
                        setDocumentValidationData(prevData =>
                          prevData.map(item =>
                            item.id === data.id ? { ...item, status: newValue } : item
                          )
                        );
                      }}
                      fullWidth
                    >
                      {docStatusOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <Button
                      color="error"
                      variant="contained"
                      onClick={() => handleDocumentValidationSubmit(data)}
                    >
                      Submit Document Validation
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* 
        // #region Submit
      */}
      {/* <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mt: 2
        }}
      >
        <Typography variant="h6" className="tp-text-header-primary">
          Login As User: &ensp;
          <span style={{ color: "#525252" }}>
            {toTitleCase(globalData?.firstName)} {toTitleCase(globalData?.lastName)}
          </span>
        </Typography>
      </Box> */}
      <Modal
        open={claimModalOpen}
        onClose={() => {
          setClaimModalOpen(false);
          setRegistering(false); // Ensure registering is false when closing
        }}
        aria-labelledby="claim-modal-title"
        aria-describedby="claim-modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
          <Typography id="claim-modal-title" variant="h6" component="h2">
            Claim Registered
          </Typography>
          <Typography id="claim-modal-description" sx={{ mt: 2 }}>
            Your claim has been registered successfully. Claim Number: {claimNumber}
          </Typography>
          <Button color="error" onClick={() => {
            setClaimModalOpen(false);
            setRegistering(false); // Ensure registering is false when closing
          }}>Close</Button>
        </Box>
      </Modal>
      {registering && (
        <Box color="error" sx={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center'
        }}>
          <CircularProgress />
          <Typography sx={{ mt: 2 }}>Registering the Claim</Typography>
        </Box>
      )}
    </div>
  );
};

export default ClaimRegistration;
