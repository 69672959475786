import * as React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Container,
  IconButton,
  Typography,
  Card,
  CardContent,
  Grid,
  Box,
  Button,
  FormHelperText,
  CircularProgress,
  OutlinedInput,
  FormControl,
  Modal,
  MenuItem,
  Select,
  InputAdornment,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { GlobalDataContext } from '../contexts/GlobalDataContext';
import GenerateNCC from './GenerateNCC';
import GenerateCNC from './GenerateCNC';

export default function CNCValidationTask() {

  function formatDate(dateString: any) {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    const date = new Date(`${year}-${month}-${day}`);
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  const handleInputChange = (e: any) => {
    const { value, id } = e.target;
    setMainState({ ...mainState, data: { ...mainState.data, [id]: value } });
  };

  const handleReset = () => {
    setMainState({ ...mainState, data: { ...mainState.data, policyNo: '' } });
    setValidated(false);
    setSelectedComponent(null);
  };

  const location = useLocation();
  const { sender } = location.state || {};
  const [mainState, setMainState] = React.useState<any>({
    data: {},
    attachments: [],
    docStatus: {},
    response: '',
    isLoading: false,
    error: ""
  });
  const { globalData } = React.useContext(GlobalDataContext);
  const { transId } = useParams();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [policyData, setPolicyData] = React.useState<PolicyData | null>(null);
  const navigate = useNavigate();
  const [validated, setValidated] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selectedComponent, setSelectedComponent] = React.useState<string | null>(null);

  interface PolicyData {
    policyNumber: string;
    contractTy: string;
    contractTyp: string;
    policyStatus: string;
    clientCode: string;
    longName: string;
    inceptionDate: string;
    expiryDate: string;
    agentCode: string;
    agentName: string;
    issuingBranch: string;
    makerModelCode: string;
    interestDescription: string;
    vehicleChassis: string;
    yearManufactured: number;
    numberofSeat: number;
    deductiblePercentage: number;
    deductible: number;
    paliabilityPerson: number;
    vehicleRegistrationNumber: string;
    vehicleEngine: string;
    towingAmount: number;
    repair: number;
    motorMaker: string;
    model: string;
    itemCoverages: { itemCoverage: string; sumInsuredperItemCoverage: number; }[];
    risk: { genp: string; clausecode: string; };
    payment: { orno: string; trandate: string; effectivedate: string; amount: string; }[];
    replacementNumber: string;
  }
  const isEmptyObject = (obj: any) => obj && Object.keys(obj).length === 0;

  const handleModalOpen = async () => {
    const policyNo = mainState.data.policyNo;
    if (!policyNo) {
      alert('Please enter a policy number');
      return;
    }
    setLoading(true);

    try {
      const signatureResponse = await fetch(`${process.env.REACT_APP_SIGNATURE_ENDPOINT}`, {
        method: 'POST',
        body: JSON.stringify({
          data: {
            policy: {
              contractOwnerSelection: policyNo,
              sfl: {
                screenSelect: "1"
              },
              sflOfS4817: {
                action: "1"
              }
            }
          }
        })
      });

      if (!signatureResponse.ok) {
        throw new Error(`Error: ${signatureResponse.statusText}`);
      }

      const signatureData = await signatureResponse.json();
      const signature = signatureData.data;

      const policyEnquiryResponse = await fetch(`${process.env.REACT_APP_POLICY_ENDPOINT}`, {
        method: 'POST',
        body: JSON.stringify({
          data: {
            policy: {
              contractOwnerSelection: policyNo,
              sfl: {
                screenSelect: "1"
              },
              sflOfS4817: {
                action: "1"
              }
            }
          },
          signature: signature
        })
      });

      if (!policyEnquiryResponse.ok) {
        throw new Error(`Error: ${policyEnquiryResponse.statusText}`);
      }

      const policyData = await policyEnquiryResponse.json();
      if (policyData.data && !isEmptyObject(policyData.data)) {
        setPolicyData(policyData.data);
      } else {
        setPolicyData(null);
      }
      setModalOpen(true);
    } catch (error) {
      console.error('Error fetching policy data:', error);
      setPolicyData(null);
      setModalOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleConfirm = () => {
    setValidated(true);
    setModalOpen(false);
  };

  React.useEffect(() => {}, []);

  function toTitleCase(str: string | undefined | null): string | undefined {
    if (!str) {
      return str as undefined;
    }

    return str.replace(/\w\S*/g, (word) => {
      return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    });
  }

  return (
    <Container maxWidth={'lg'} sx={{ my: 12 }}>
      <IconButton onClick={() => navigate(-1)} disableRipple={true} aria-label="open" sx={{ my: 2, padding: 0 }}>
        <ArrowBackIcon sx={{ height: 28, width: 28 }} />
        <Typography sx={{ ml: 1 }}>Back</Typography>
      </IconButton>
      <Card sx={{ minWidth: 275, my: 2 }}>
        <CardContent className="tp-padding-50px">
          <Grid container spacing={2}>
            <Grid item md={9}>
              <Typography variant="h4" className="tp-text-header-primary">CNC/NCC Validation</Typography>
            </Grid>
            <Grid item md={3} textAlign={"center"}>
              {/* <img src={Image.logo} width={180} height={60} alt='img' /> */}
            </Grid>
          </Grid>
          <hr className="tp-border-hr_primary" /><br /><br />
          <Grid container spacing={2} justifyContent="center">
            <Grid item md={10} textAlign="center">
              <Typography variant="h5" className="tp-text-header-primary">
                <span className="tp-text-default">Transaction No.: &ensp;</span>
                ACAP-{transId}
              </Typography>
              <Typography variant="body1" className="tp-text-header-primary">
                <span className="tp-text-default">Sender: &ensp;</span>
                {sender}
              </Typography>
            </Grid>
            <Grid item md={6} textAlign="center">
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">Policy No. <span className="tp-text-required">*</span></Typography>
                <OutlinedInput
                  id="policyNo"
                  size="small"
                  type="text"
                  placeholder={`Enter Policy No. Here...`}
                  value={mainState?.data?.policyNo || ''}
                  onChange={handleInputChange}
                  inputProps={{ "aria-label": "policyNo" }}
                  disabled={false}
                  error={mainState?.data?.policyNo === ""}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={handleReset} edge="end">
                        <RefreshIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleModalOpen}
                  style={{ backgroundColor: validated ? 'green' : 'red' }}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : validated ? 'Validated / View Details' : 'Check Policy Details'}
                </Button>
                {(mainState?.data?.policyNo ?? "") === "" && (
                  <FormHelperText error id="policyNo">
                    Empty required field. Please validate Policy to generate CNC/NCC
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <hr className="tp-border-hr_primary" /><br />
          <Typography variant="h5" className="tp-text-header-primary" textAlign="center">Generate CNC/NCC</Typography>
          {!validated &&
                      <Typography variant="body1" className="tp-text-required" textAlign="center">Please Validate the Policy No. first to genereate CNC or NCC</Typography>}
          {validated && (
            <FormControl variant="outlined" fullWidth sx={{ mt: 2, textAlign: 'center' }}>
              <Typography variant="body1" className="tp-text-bold">Select NCC or CNC</Typography>
              <Select
                value={selectedComponent}
                onChange={(e) => setSelectedComponent(e.target.value)}
                displayEmpty
              >
                <MenuItem value="" disabled>Select NCC or CNC</MenuItem>
                <MenuItem value="GenerateNCC">Generate NCC</MenuItem>
                <MenuItem value="GenerateCNC">Generate CNC</MenuItem>
              </Select>
            </FormControl>
          )}
          <Grid container spacing={2} className="tp-mb-20px" justifyContent="center" mt={2}>
            {selectedComponent === "GenerateNCC" && (
              <Grid item xs={12} textAlign="center">
                <Typography variant="h6" className="tp-text-header-primary">Generate NCC</Typography>
                <GenerateNCC
                  policyNo={policyData?.policyNumber}
                  longName={policyData?.longName}
                  inceptionDate={formatDate(policyData?.inceptionDate || '')}
                  expiryDate={formatDate(policyData?.expiryDate || '')}
                  model={policyData?.model}
                  motorMaker={policyData?.motorMaker}
                  interestDescription={policyData?.interestDescription}
                  motorNo={policyData?.vehicleEngine}
                  vehicleChassis={policyData?.vehicleChassis}
                  vehicleRegistrationNumber={policyData?.vehicleRegistrationNumber}
                />
              </Grid>
            )}
            {selectedComponent === "GenerateCNC" && (
              <Grid item xs={12} textAlign="center">
                <Typography variant="h6" className="tp-text-header-primary">Generate CNC</Typography>
                <GenerateCNC
                  policyNo={policyData?.policyNumber}
                  longName={policyData?.longName}
                  inceptionDate={formatDate(policyData?.inceptionDate || '')}
                  expiryDate={formatDate(policyData?.expiryDate || '')}
                  model={policyData?.model}
                  motorMaker={policyData?.motorMaker}
                  interestDescription={policyData?.interestDescription}
                  motorNo={policyData?.vehicleEngine}
                  vehicleChassis={policyData?.vehicleChassis}
                  vehicleRegistrationNumber={policyData?.vehicleRegistrationNumber}
                />
              </Grid>
            )}
          </Grid>
          <hr className="tp-border-hr_primary" /><br />
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h6" className="tp-text-header-primary">
              Login As User: &ensp;
              <span style={{ color: "#525252" }}>
                {toTitleCase(globalData?.firstName)} {toTitleCase(globalData?.lastName)}
              </span>
            </Typography>
          </Box>
          <br /><br />
        </CardContent>
      </Card>
      <Modal open={modalOpen} onClose={handleModalClose} sx={{ overflowY: "scroll" }}>
        <Box sx={{ padding: 4, backgroundColor: 'white', margin: 'auto', width: '50%', marginTop: '10%', position: 'relative' }}>
          <IconButton onClick={handleModalClose} sx={{ position: 'absolute', top: 8, right: 8 }}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" gutterBottom>Policy Details</Typography>
          {policyData && !isEmptyObject(policyData) ? (
            <>
              <Typography variant="body2"><strong>Policy Number:</strong> {policyData.policyNumber}</Typography>
              <Typography variant="body2"><strong>Contract Type:</strong> {policyData.contractTy}</Typography>
              <Typography variant="body2"><strong>Policy Status:</strong> {policyData.policyStatus}</Typography>
              <Typography variant="body2"><strong>Client Code:</strong> {policyData.clientCode}</Typography>
              <Typography variant="body2"><strong>Long Name:</strong> {policyData.longName}</Typography>
              <Typography variant="body2"><strong>Inception Date:</strong> {policyData.inceptionDate}</Typography>
              <Typography variant="body2"><strong>Expiry Date:</strong> {policyData.expiryDate}</Typography>
              <Typography variant="body2"><strong>Agent Code:</strong> {policyData.agentCode}</Typography>
              <Typography variant="body2"><strong>Agent Name:</strong> {policyData.agentName}</Typography>
              <Typography variant="body2"><strong>Issuing Branch:</strong> {policyData.issuingBranch}</Typography>
              <Typography variant="body2"><strong>Maker Model Code:</strong> {policyData.makerModelCode}</Typography>
              <Typography variant="body2"><strong>Interest Description:</strong> {policyData.interestDescription}</Typography>
              <Typography variant="body2"><strong>Vehicle Chassis:</strong> {policyData.vehicleChassis}</Typography>
              <Typography variant="body2"><strong>Vehicle Engine:</strong> {policyData.vehicleEngine}</Typography>
              <Typography variant="body2"><strong>Vehicle Registration Number:</strong> {policyData.vehicleRegistrationNumber}</Typography>
              <Typography variant="body2"><strong>Year Manufactured:</strong> {policyData.yearManufactured}</Typography>
              <Typography variant="body2"><strong>Number of Seats:</strong> {policyData.numberofSeat}</Typography>
              <Typography variant="body2"><strong>Deductible Percentage:</strong> {policyData.deductiblePercentage}</Typography>
              <Typography variant="body2"><strong>Deductible:</strong> {policyData.deductible}</Typography>
              <Typography variant="body2"><strong>Liability Person:</strong> {policyData.paliabilityPerson}</Typography>
              <Typography variant="body2"><strong>Towing Amount:</strong> {policyData.towingAmount}</Typography>
              <Typography variant="body2"><strong>Repair:</strong> {policyData.repair}</Typography>
              <Typography variant="body2"><strong>Motor Maker:</strong> {policyData.motorMaker}</Typography>
              <Typography variant="body2"><strong>Model:</strong> {policyData.model}</Typography>
              {policyData.itemCoverages && policyData.itemCoverages.map((coverage, index) => (
                <Typography key={index} variant="body2">
                  <strong>Item Coverage {coverage.itemCoverage}:</strong> {coverage.sumInsuredperItemCoverage}
                </Typography>
              ))}
              {policyData.risk && (
                <Typography variant="body2">
                  <strong>Risk {policyData.risk.genp}:</strong> {policyData.risk.clausecode}
                </Typography>
              )}
              {policyData.payment && policyData.payment.map((pay, index) => (
                <Typography key={index} variant="body2">
                  <strong>Payment:</strong> OR No: {pay.orno}, Transaction date: {pay.trandate},
                  Effective date: {pay.effectivedate}, Amount: {pay.amount}
                </Typography>
              ))}
              <Typography variant="body2"><strong>Replacement Number:</strong> {policyData.replacementNumber}</Typography>
              {!validated && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                  <Button variant="contained" color="secondary" onClick={handleModalClose}>Cancel</Button>
                  <Button variant="contained" onClick={handleConfirm}>Confirm</Button>
                </Box>
              )}
            </>
          ) : (
            <Typography variant="body2" color="error">Policy Doesn't Exist</Typography>
          )}
        </Box>
      </Modal>
    </Container>
  );
}
