import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Container,
  IconButton,
  Typography,
  Card,
  CardContent,
  Grid,
  Box,
  Button,
  FormHelperText,
  CircularProgress,
  OutlinedInput,
  FormControl,
  Modal,
  MenuItem,
  Select,
  InputAdornment,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { GlobalDataContext } from "../contexts/GlobalDataContext";
import axios from "axios";
import PolicyAsiaPaymentTable from "./document_validation/tables/PolicyAsiaPaymentTable";
import { v4 as uuidv4 } from "uuid";
import { AuditTrailDataContext } from "../contexts/AuditTrailContext";

interface PolicyNumberCheckerProps {
  setClientCode: (clientCode: string) => void;
  policyNo: string;
  setPolicyNoField: (policyNo: string) => void;
  setPolicyFromDB: (policyNo: string) => void;
  claimRegistrationData: any;
  setClientValidated: (clientValidated: boolean) => void;
  policyValidated: boolean;
  setPolicyValidated: (policyValidated: boolean) => void;
}

const PolicyChecker: React.FC<PolicyNumberCheckerProps> = ({
  setClientCode,
  policyNo,
  setPolicyNoField,
  setPolicyFromDB,
  claimRegistrationData,
  setClientValidated,
  policyValidated,
  setPolicyValidated,
}: any) => {
  function formatDate(dateString: any) {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    const date = new Date(`${year}-${month}-${day}`);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  }

  const handleInputChange = (e: any) => {
    const { value, id } = e.target;
    // setMainState({ ...mainState, data: { ...mainState.data, [id]: value } });
    if (!policyNoFromDB) {
      setPolicyNoField(value);
    } else {
      setPolicyNoFromDB(value);
      setPolicyFromDB(value);
    }
  };

  const handleReset = () => {
    // setMainState({ ...mainState, data: { ...mainState.data, policyNo: "" } });
    deletePolicyAsiaRecord(transId);
    deleteClientRecord(transId);
  };

  const location = useLocation();
  const { sender } = location.state || {};
  const [mainState, setMainState] = React.useState<any>({
    data: {
      policyNo: policyNo,
    },
    attachments: [],
    docStatus: {},
    response: "",
    isLoading: false,
    error: "",
  });
  const { globalData } = React.useContext(GlobalDataContext);
  const { transId } = useParams();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [policyData, setPolicyData] = React.useState<PolicyData | null>(null);
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [selectedComponent, setSelectedComponent] = React.useState<
    string | null
  >(null);
  const domain = window.location.origin;
  const [policyNoFromDB, setPolicyNoFromDB] = React.useState("");
  const { createAuditTrailData } =
    React.useContext(AuditTrailDataContext);

  interface PolicyData {
    policyNumber: string;
    contractTy: string;
    contractTyp: string;
    policyStatus: string;
    clientCode: string;
    longName: string;
    inceptionDate: string;
    expiryDate: string;
    agentCode: string;
    agentName: string;
    issuingBranch: string;
    makerModelCode: string;
    interestDescription: string;
    vehicleChassis: string;
    yearManufactured: number;
    numberofSeat: number;
    deductiblePercentage: number;
    deductible: number;
    paliabilityPerson: number;
    vehicleRegistrationNumber: string;
    vehicleEngine: string;
    towingAmount: number;
    repair: number;
    motorMaker: string;
    model: string;
    interestedParty: string[] | string;
    interestedPartyName: string[] | string;
    interestedPartyRole: string[] | string;
    contractHeader: { genp: string; clausecode: string } | string;
    itemCoverages:
      | {
          itemCoverage: string;
          sumInsuredperItemCoverage: number;
        }[]
      | string;
    risk: { genp: string; clausecode: string } | string;
    payment:
      | {
          orno: string;
          trandate: string;
          effectivedate: string;
          amount: string;
        }[]
      | string;
    replacementNumber: string;
    url: string;
    status: number;
    saveDate?: string;
    transId?: number;
  }
  const isEmptyObject = (obj: any) => obj && Object.keys(obj).length === 0;

  // #region handleModalOpen
  const handleModalOpen = async () => {
    if (policyNo === null || policyNoFromDB === null) {
      alert("Please enter a policy number");
      return;
    }
    setLoading(true);

    const newPolicyNo = policyNoFromDB ? policyNoFromDB : policyNo;

    // fetch signature
    try {
      const signatureResponse = await fetch(
        `${process.env.REACT_APP_SIGNATURE_ENDPOINT}`,
        {
          method: "POST",
          body: JSON.stringify({
            data: {
              policy: {
                contractOwnerSelection: newPolicyNo,
                sfl: {
                  screenSelect: "1",
                },
                sflOfS4817: {
                  action: "1",
                },
              },
            },
          }),
        }
      );

      if (!signatureResponse.ok) {
        throw new Error(`Error: ${signatureResponse.statusText}`);
      }

      const signatureData = await signatureResponse.json();
      const signature = signatureData.data;

      const policyEnquiryResponse = await fetch(
        `${process.env.REACT_APP_POLICY_ENDPOINT}`,
        {
          method: "POST",
          body: JSON.stringify({
            data: {
              policy: {
                contractOwnerSelection: newPolicyNo,
                sfl: {
                  screenSelect: "1",
                },
                sflOfS4817: {
                  action: "1",
                },
              },
            },
            signature: signature,
          }),
        }
      );

      if (!policyEnquiryResponse.ok) {
        throw new Error(`Error: ${policyEnquiryResponse.statusText}`);
      }

      const policyDataJSON = await policyEnquiryResponse.json();
      if (policyDataJSON?.data && !isEmptyObject(policyDataJSON?.data)) {
        setPolicyData(policyDataJSON?.data);
      } else {
        setPolicyData(null);
      }
      setModalOpen(true);
    } catch (error) {
      console.error("Error fetching policy data:", error);
      setPolicyData(null);
      setModalOpen(true);
    } finally {
      setLoading(false);
    }
  };

  // convert json string to array
  const handleJSONStringArray = (item: any) => {
    let arr = [];

    if (typeof item === "string") {
      try {
        arr = JSON.parse(item);
      } catch (error) {
        console.error(`Failed to parse ${item} string:`, error);
      }
    } else {
      arr = item;
    }

    return Array.isArray(arr) ? arr : [];
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  // #region getPolicyAsiaRecord
  // if the
  const getPolicyAsiaRecord = async (
    policyNoParam: string,
    transactionId: any
  ) => {
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/policy-asia?transId=${transactionId}`
      )
      .then((res: any) => {
        console.log(res?.data?.data);
        const policyDataRes = res?.data?.data;
        setPolicyNoFromDB(policyDataRes?.policyNumber);
        setPolicyFromDB(policyDataRes?.policyNumber);
        setPolicyValidated(policyDataRes?.status === 1 ? true : false);
        setClientCode(policyDataRes?.clientCode);
      })
      .catch((err: any) => {
        // if motor vehicle has policy no but not validated
        // set validate to false
        console.error(err);
        if (err?.response?.status === 404) {
          setPolicyNoField(policyNoParam);
          setPolicyValidated(false);
        }
      });
  };

  // #region createPolicyAsiaRecord
  const createPolicyAsiaRecord = async (policyDataReq: any) => {
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/policy-asia`,
        JSON.stringify(policyDataReq),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res: any) => {
        console.log(res?.data?.data);

        createAuditTrailData({
          transId: Number(transId),
          taskType: "document_validation",
          event: "validate_policy_details",
          description: `Validate policy details with #${policyData?.policyNumber}`,
          userId: globalData.id
        })

        setPolicyValidated(true);
        setClientCode(policyData?.clientCode);
        setModalOpen(false);
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  // #region deletePolicyAsiaRecord
  const deletePolicyAsiaRecord = async (transactionId: any) => {
    await axios
      .delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/policy-asia?transId=${transactionId}`
      )
      .then((res: any) => {
        console.log(res?.data?.data);

        createAuditTrailData({
          transId: Number(transId),
          taskType: "document_validation",
          event: "reset_policy_details",
          description: `Reset policy details with #${policyData?.policyNumber}`,
          userId: globalData.id
        })
        setPolicyNoField("");
        setPolicyNoFromDB("");
        setPolicyValidated(false);
        setSelectedComponent(null);
        setClientCode("");
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  const deleteClientRecord = async (transactionId: any) => {
    await axios
      .delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/client-list?transId=${transactionId}`
      )
      .then((res: any) => {
        console.log(res?.data?.message);
        if (res?.data?.data) {
          setClientCode("");
          setMainState({
            data: { clientNo: "" },
            clientData: {},
          });
          setClientValidated(false);
        }
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  // #region handleConfirm
  const handleConfirm = () => {
    policyData!.url = `${domain}/policyenquiry?no=${policyNo}&token=${uuidv4()}`;
    policyData!.status = 1;
    policyData!.itemCoverages = JSON.stringify(policyData?.itemCoverages);
    policyData!.payment = JSON.stringify(policyData?.payment);
    policyData!.interestedParty = JSON.stringify(policyData?.interestedParty);
    policyData!.interestedPartyName = JSON.stringify(
      policyData?.interestedPartyName
    );
    policyData!.interestedPartyRole = JSON.stringify(
      policyData?.interestedPartyRole
    );
    policyData!.contractHeader = JSON.stringify(policyData?.contractHeader);
    policyData!.risk = JSON.stringify(policyData?.risk);
    policyData!.transId = Number(transId);
    // NOTE: remove this if the db is okay
    policyData!.agentName = policyData?.agentName.slice(0, 10) as string;

    createPolicyAsiaRecord(policyData);
  };

  // #region useEffect
  React.useEffect(() => {
    getPolicyAsiaRecord(policyNo, transId);
  }, []);

  return (
    <>
      <FormControl variant="outlined" fullWidth>
        <Typography variant="body1" className="tp-text-bold">
          Policy No. <span className="tp-text-required">*</span>
        </Typography>
        <OutlinedInput
          id="policyNo"
          size="small"
          type="text"
          placeholder={`Enter Policy No. Here...`}
          value={policyNoFromDB ? policyNoFromDB : policyNo}
          onChange={handleInputChange}
          inputProps={{ "aria-label": "policyNo" }}
          disabled={
            !!(claimRegistrationData && claimRegistrationData.claimNumber)
          }
          error={policyNo === ""}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={handleReset}
                edge="end"
                disabled={
                  !!(claimRegistrationData && claimRegistrationData.claimNumber)
                }
              >
                <RefreshIcon />
              </IconButton>
            </InputAdornment>
          }
        />
        <Button
          variant="contained"
          color={policyValidated ? "error" : "warning"}
          onClick={handleModalOpen}
          // style={{ backgroundColor: policyValidated ? "green" : "red" }}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : policyValidated ? (
            "Validated / View Details"
          ) : (
            "Check Policy Details"
          )}
        </Button>
        {(mainState?.data?.policyNo ?? "") === "" && (
          <FormHelperText error id="policyNo">
            {/* Empty required field. Please validate Policy to generate CNC/NCC */}
          </FormHelperText>
        )}
      </FormControl>

      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        sx={{ overflowY: "scroll" }}
      >
        <Box
          sx={{
            padding: 4,
            backgroundColor: "white",
            margin: "auto",
            width: "50%",
            marginTop: "10%",
            position: "relative",
          }}
        >
          <IconButton
            onClick={handleModalClose}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" gutterBottom>
            Policy Details
          </Typography>
          {policyData && !isEmptyObject(policyData) ? (
            <>
              <Typography variant="body2">
                <strong>Policy Number:</strong> {policyData.policyNumber}
              </Typography>
              <Typography variant="body2">
                <strong>Contract Type:</strong> {policyData.contractTy}
              </Typography>
              <Typography variant="body2">
                <strong>Policy Status:</strong> {policyData.policyStatus}
              </Typography>
              <Typography variant="body2">
                <strong>Client Code:</strong> {policyData.clientCode}
              </Typography>
              <Typography variant="body2">
                <strong>Long Name:</strong> {policyData.longName}
              </Typography>
              <Typography variant="body2">
                <strong>Inception Date:</strong> {policyData.inceptionDate}
              </Typography>
              <Typography variant="body2">
                <strong>Expiry Date:</strong> {policyData.expiryDate}
              </Typography>
              <Typography variant="body2">
                <strong>Agent Code:</strong> {policyData.agentCode}
              </Typography>
              <Typography variant="body2">
                <strong>Agent Name:</strong> {policyData.agentName}
              </Typography>
              <Typography variant="body2">
                <strong>Issuing Branch:</strong> {policyData.issuingBranch}
              </Typography>
              <Typography variant="body2">
                <strong>Maker Model Code:</strong> {policyData.makerModelCode}
              </Typography>
              <Typography variant="body2">
                <strong>Motor Maker:</strong> {policyData.motorMaker}
              </Typography>
              <Typography variant="body2">
                <strong>Model:</strong> {policyData.model}
              </Typography>
              <Typography variant="body2">
                <strong>Interest Description:</strong>{" "}
                {policyData.interestDescription}
              </Typography>
              <Typography variant="body2">
                <strong>Vehicle Chassis:</strong> {policyData.vehicleChassis}
              </Typography>
              <Typography variant="body2">
                <strong>Vehicle Engine:</strong> {policyData.vehicleEngine}
              </Typography>
              <Typography variant="body2">
                <strong>Vehicle Registration Number:</strong>{" "}
                {policyData.vehicleRegistrationNumber}
              </Typography>
              <Typography variant="body2">
                <strong>Year Manufactured:</strong>{" "}
                {policyData.yearManufactured}
              </Typography>
              <Typography variant="body2">
                <strong>Number of Seats:</strong> {policyData.numberofSeat}
              </Typography>
              <Typography variant="body2">
                <strong>Deductible Percentage:</strong>{" "}
                {policyData.deductiblePercentage}
              </Typography>
              <Typography variant="body2">
                <strong>Deductible:</strong> {policyData.deductible}
              </Typography>
              <Typography variant="body2">
                <strong>Liability Person:</strong>{" "}
                {policyData.paliabilityPerson}
              </Typography>
              <Typography variant="body2">
                <strong>Towing Amount:</strong> {policyData.towingAmount}
              </Typography>
              <Typography variant="body2">
                <strong>Repair:</strong> {policyData.repair}
              </Typography>

              {policyData.itemCoverages &&
                handleJSONStringArray(policyData?.itemCoverages).map(
                  (coverage: any, index: any) => (
                    <Typography key={index} variant="body2">
                      <strong>Sum Insured {coverage.itemCoverage}:</strong>{" "}
                      {coverage.sumInsuredperItemCoverage}
                    </Typography>
                  )
                )}
              {policyData.risk && (
                <Typography variant="body2">
                  <strong>
                    Risk{" "}
                    {
                      (typeof policyData.risk === "string"
                        ? JSON.parse(policyData.risk)
                        : policyData.risk
                      ).genp
                    }
                    :
                  </strong>{" "}
                  {
                    (typeof policyData.risk === "string"
                      ? JSON.parse(policyData.risk)
                      : policyData.risk
                    ).clausecode
                  }
                </Typography>
              )}
              <Typography variant="body2">
                <strong>Replacement Number:</strong>{" "}
                {policyData.replacementNumber}
              </Typography>
              {policyData.payment && (
                <>
                  <Typography variant="body2">
                    <strong>Payment:</strong>
                  </Typography>
                  <PolicyAsiaPaymentTable
                    rows={handleJSONStringArray(policyData?.payment)}
                  />
                </>
              )}
              {/* 
                // #region Buttons
              */}
              {!policyValidated && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleModalClose}
                  >
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={handleConfirm}>
                    Confirm
                  </Button>
                </Box>
              )}
            </>
          ) : (
            <Typography variant="body2" color="error">
              Policy Doesn't Exist
            </Typography>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default PolicyChecker;
