import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { Typography, Box, CircularProgress, Grid } from "@mui/material";
import PolicyAsiaPaymentTable from "../components/document_validation/tables/PolicyAsiaPaymentTable";

interface PolicyData {
  policyNumber: string;
  contractTy: string;
  contractTyp: string;
  policyStatus: string;
  clientCode: string;
  longName: string;
  inceptionDate: string;
  expiryDate: string;
  agentCode: string;
  agentName: string;
  issuingBranch: string;
  makerModelCode: string;
  interestDescription: string;
  vehicleChassis: string;
  yearManufactured: number;
  numberofSeat: number;
  deductiblePercentage: number;
  deductible: number;
  paliabilityPerson: number;
  vehicleRegistrationNumber: string;
  vehicleEngine: string;
  towingAmount: number;
  repair: number;
  motorMaker: string;
  model: string;
  itemCoverages: { itemCoverage: string; sumInsuredperItemCoverage: number }[];
  risk: { genp: string; clausecode: string };
  payment: {
    orno: string;
    trandate: string;
    effectivedate: string;
    amount: string;
  }[];
  replacementNumber: string;
}

export default function PolicyEnquiryPage() {
  const [policyData, setPolicyData] = React.useState<PolicyData | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [searchParams] = useSearchParams();

  const policyNo = searchParams.get("no");

  const isEmptyObject = (obj: any) => obj && Object.keys(obj).length === 0;

  const fetchGetPolicyData = async () => {
    setLoading(true);

    try {
      const signatureResponse = await fetch(
        `${process.env.REACT_APP_SIGNATURE_ENDPOINT}`,
        {
          method: "POST",
          body: JSON.stringify({
            data: {
              policy: {
                contractOwnerSelection: policyNo,
                sfl: {
                  screenSelect: "1",
                },
                sflOfS4817: {
                  action: "1",
                },
              },
            },
          }),
        }
      );

      if (!signatureResponse.ok) {
        throw new Error(`Error: ${signatureResponse.statusText}`);
      }

      const signatureData = await signatureResponse.json();
      const signature = signatureData.data;

      const policyEnquiryResponse = await fetch(
        `${process.env.REACT_APP_POLICY_ENDPOINT}`,
        {
          method: "POST",
          body: JSON.stringify({
            data: {
              policy: {
                contractOwnerSelection: policyNo,
                sfl: {
                  screenSelect: "1",
                },
                sflOfS4817: {
                  action: "1",
                },
              },
            },
            signature: signature,
          }),
        }
      );

      if (!policyEnquiryResponse.ok) {
        throw new Error(`Error: ${policyEnquiryResponse.statusText}`);
      }

      const policyData = await policyEnquiryResponse.json();
      if (policyData.data && !isEmptyObject(policyData.data)) {
        setPolicyData(policyData.data);
      } else {
        setPolicyData(null);
      }
    } catch (error) {
      console.error("Error fetching policy data:", error);
      setPolicyData(null);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchGetPolicyData();
  }, []);

  return (
    <Box
      sx={{
        padding: 4,
        backgroundColor: "white",
        mt: 12,
        mx: "auto",
        width: "50%",
        position: "relative",
      }}
    >
      <Grid container spacing={2}>
        <Grid item md={9}>
          <Typography variant="h4" className="tp-text-header-primary">
            Policy Details
          </Typography>
        </Grid>
        <Grid item md={3} textAlign={"center"}>
          {/* <img src={Image.logo} width={180} height={60} alt='img' /> */}
        </Grid>
      </Grid>
      <hr className="tp-border-hr_primary" />
      <br />
      <br />
      {loading ? (
        <CircularProgress color="secondary" />
      ) : policyData && !isEmptyObject(policyData) ? (
        <>
          <Typography variant="body2" sx={{ mb: 1 }}>
            <strong>Policy Number:</strong> {policyData.policyNumber}
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            <strong>Long Name:</strong> {policyData.longName}
          </Typography>
          {policyData.payment && (
            <>
              <Typography variant="body2" sx={{ mb: 1 }}>
                <strong>Payment:</strong>
              </Typography>
              <PolicyAsiaPaymentTable
                rows={
                  typeof policyData.payment === "string"
                    ? JSON.parse(policyData.payment)
                    : policyData.payment
                }
              />
            </>
          )}
        </>
      ) : (
        <Typography variant="body2" color="error">
          Policy Doesn't Exist
        </Typography>
      )}
    </Box>
  );
}
