import React, { useState, useRef, useEffect } from 'react';
import { PDFDocument, rgb } from 'pdf-lib';
import { saveAs } from 'file-saver';
import * as pdfjsLib from 'pdfjs-dist';
import fontkit from '@pdf-lib/fontkit';
import insurerPdf from '../assets/CNCDraft.pdf';
import boldFontUrl from '../assets/arial-bold.ttf';
import regularFontUrl from '../assets/arial.ttf';
import ximage from '../assets/x_image.png';
import { TextField, Button, Select, MenuItem, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, InputLabel, FormControl, SelectChangeEvent } from '@mui/material';

function getCurrentFormattedDate(): string {
  const currentDate = new Date();
  return currentDate.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });
}

const GenerateCNC = ({
  policyNo, longName, inceptionDate, expiryDate,
  model, motorMaker, interestDescription,
  motorNo, vehicleChassis, vehicleRegistrationNumber
}: any) => {

  const [pdfBytes, setPdfBytes] = useState<Uint8Array | null>(null);
  const [isRendering, setIsRendering] = useState(false);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const [formData, setFormData] = useState({
    insurance: 'Adverse Insurance',
    dateIncident: 'July 08, 2024',
    placeIncident: 'PGA MOTORCARS INC 201 EDSA, Mandaluyong City 1554 Philippines',
    claimNumber: '',
    dateOfLoss: '',
    damagePortion: '',
    status: 'Settled and closed',
  });

  const [claims, setClaims] = useState<any[]>([]);

  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { name, value } = event.target as HTMLInputElement;
    setFormData((prev) => ({ ...prev, [name as string]: value }));
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const addClaim = () => {
    setClaims((prev) => [...prev, { ...formData }]);
    setFormData({
      ...formData,
      claimNumber: '',
      dateOfLoss: '',
      damagePortion: '',
      status: 'Settled and closed'
    });
  };

  const generatePdfBytes = async () => {
    try {
      const existingPdfBytes = await fetch(insurerPdf).then((res) => res.arrayBuffer());
      const boldFontBytes = await fetch(boldFontUrl).then((res) => res.arrayBuffer());
      const regularFontBytes = await fetch(regularFontUrl).then((res) => res.arrayBuffer());
      const imageBytes = await fetch(ximage).then((res) => res.arrayBuffer());

      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      pdfDoc.registerFontkit(fontkit);

      const pages = pdfDoc.getPages();
      const firstPage = pages[0];
      const boldFont = await pdfDoc.embedFont(boldFontBytes);
      const regularFont = await pdfDoc.embedFont(regularFontBytes);
      const { width, height } = firstPage.getSize();

      const image = await pdfDoc.embedPng(imageBytes);
      const imageDims = image.scale(0.3);

      const textDate = getCurrentFormattedDate();

      const text1 = 'This is to certify that ';
      const text2 = longName;
      const text3 = ', whose vehicle described below, is insured with us under';

      const textWidth1 = regularFont.widthOfTextAtSize(text1, 10);
      const textWidth2 = boldFont.widthOfTextAtSize(text2, 10);
      const textWidth3 = regularFont.widthOfTextAtSize(text3, 10);

      firstPage.drawText(textDate, {
        x: 452,
        y: height - 153,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(text1, {
        x: 65,
        y: height - 219,
        size: 10,
        font: regularFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(text2, {
        x: 65 + textWidth1,
        y: height - 219,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(text3, {
        x: 65 + textWidth1 + textWidth2,
        y: height - 219,
        size: 10,
        font: regularFont,
        color: rgb(0, 0, 0),
      });

      const text4 = 'Motor Policy No. ';
      const text5 = policyNo;
      const text6 = ` from ${inceptionDate} to ${expiryDate}.`

      const textWidth4 = regularFont.widthOfTextAtSize(text4, 10);
      const textWidth5 = boldFont.widthOfTextAtSize(text5, 10);

      firstPage.drawText(formData.insurance, {
        x: 67,
        y: height - 178,
        size: 12,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(text4, {
        x: 66,
        y: height - 231,
        size: 10,
        font: regularFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(text5, {
        x: 66 + textWidth4,
        y: height - 231,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(text6, {
        x: 66 + textWidth4 + textWidth5,
        y: height - 231,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(`${model} ${motorMaker} ${interestDescription}`, {
        x: 148,
        y: height - 269,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(vehicleChassis, {
        x: 148,
        y: height - 283,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(motorNo, {
        x: 148,
        y: height - 297,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(vehicleRegistrationNumber, {
        x: 148,
        y: height - 311,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      let currentHeight = height - 520;

      if (claims.length > 0) {
        // Draw the 'X'
        firstPage.drawText("X", {
          x: 70,
          y: currentHeight + 15,
          size: 10,
          font: regularFont,
          color: rgb(0, 0, 0),
        });

        // Draw table headers
        const headers = ["Claim Number", "Date of Loss", "Damage Portion", "Status"];
        const headerXPositions = [101, 201, 301, 401];
        headers.forEach((header, index) => {
          firstPage.drawText(header, {
            x: headerXPositions[index],
            y: currentHeight,
            size: 9,
            font: boldFont,
            color: rgb(0, 0, 0),
          });
        });

        // Draw table rows
        claims.forEach((claim, rowIndex) => {
          const rowYPosition = currentHeight - 20 - rowIndex * 20;
          const values = [
            claim.claimNumber,
            claim.dateOfLoss,
            claim.damagePortion.split(',').map((portion: string) => portion.trim()).join('\n'),
            claim.status
          ];

          values.forEach((value, colIndex) => {
            firstPage.drawText(value, {
              x: headerXPositions[colIndex],
              y: rowYPosition,
              size: 9,
              font: regularFont,
              color: rgb(0, 0, 0),
            });
          });

          const numLines = claim.damagePortion.split(',').length;
          currentHeight -= numLines > 1 ? 20 + (numLines - 1) * 15 : 20;
        });

        currentHeight -= 20; // Additional space after the table
      }

      // Adjust other elements based on the table height
      firstPage.drawImage(image, {
        x: 65,
        y: currentHeight - imageDims.height - 20,
        width: imageDims.width,
        height: imageDims.height,
      });

      firstPage.drawText(`did not file a claim with us in connection with the accident occur on`, {
        x: 97,
        y: currentHeight - imageDims.height - 25,
        size: 9,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(`${formData.dateIncident}`, {
        x: 392,
        y: currentHeight - imageDims.height - 25,
        size: 9,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(`along ${formData.placeIncident}`, {
        x: 97,
        y: currentHeight - imageDims.height - 35,
        size: 9,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      const pdfBytes = await pdfDoc.save();
      setPdfBytes(pdfBytes);
      previewPdf(new Uint8Array(pdfBytes), 1);
    } catch (error) {
      console.error("Error generating PDF bytes:", error);
    }
  };

  const previewPdf = async (bytes: Uint8Array, pageNum: number) => {
    if (!bytes) return;
    if (isRendering) return;

    setIsRendering(true);
    const loadingTask = pdfjsLib.getDocument({ data: bytes });
    const pdf = await loadingTask.promise;
    const page = await pdf.getPage(pageNum);

    const viewport = page.getViewport({ scale: 1 });
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');

    if (canvas && context) {
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };
      await page.render(renderContext).promise;
    }

    setIsRendering(false);
  };

  useEffect(() => {
    if (pdfBytes) {
      previewPdf(new Uint8Array(pdfBytes), currentPage);
    }
  }, [pdfBytes, currentPage]);

  const savePdf = () => {
    if (pdfBytes) {
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().replace(/[-:.TZ]/g, '');
      const fileName = `CNC Report_${formattedDate}.pdf`;
      saveAs(blob, fileName);
    } else {
      console.error("No PDF bytes to save.");
    }
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Insurance Third Party"
            name="insurance"
            value={formData.insurance}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Date Incident"
            name="dateIncident"
            value={formData.dateIncident}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Place Incident"
            name="placeIncident"
            value={formData.placeIncident}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="Claim Number"
            name="claimNumber"
            value={formData.claimNumber}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="Date of Loss"
            type="date"
            name="dateOfLoss"
            value={formData.dateOfLoss}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="Damage Portion"
            name="damagePortion"
            value={formData.damagePortion}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              name="status"
              value={formData.status}
              onChange={handleSelectChange}
            >
              <MenuItem value="Settled and closed">Settled and closed</MenuItem>
              <MenuItem value="Pending">Pending</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={addClaim} fullWidth>
            Add Claim
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button variant="contained" color="secondary" onClick={generatePdfBytes} fullWidth>
            Preview PDF
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button variant="contained" color="secondary" onClick={savePdf} fullWidth>
            Save PDF
          </Button>
        </Grid>
      </Grid>

      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Claim Number</TableCell>
              <TableCell>Date of Loss</TableCell>
              <TableCell>Damage Portion</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {claims.map((claim, index) => (
              <TableRow key={index}>
                <TableCell>{claim.claimNumber}</TableCell>
                <TableCell>{claim.dateOfLoss}</TableCell>
                <TableCell>
                  {claim.damagePortion.split(',').map((portion: string, i: number) => (
                    <div key={i}>{portion.trim()}</div>
                  ))}
                </TableCell>
                <TableCell>{claim.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <canvas ref={canvasRef} style={{ border: '1px solid black', marginTop: '20px' }}></canvas>
    </div>
  );
};

export default GenerateCNC;
