import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { formatNumber } from "../../utils/stringUtils";

export default function LoaDetails({
  fetchLoaDetails,
  sfSearchResult,
  setSfSearchResult,
  searchParams,
  setSearchParams,
  sfLoaDetails,
  setSfLoaDetails,
  setPolicyAsiaData
}: any) {
  const handleInputChange = (e: any) => {
    const { value, id } = e.target;
    setSearchParams({
      ...searchParams,
      [id]: value,
    });
  };

  return (
    <div>
      {/* 
        TODO: make a condition if there is load details from db show it and hide search,
        if not show search and show details 
    */}
      <Grid container spacing={2}>
        {/* <Grid item xs={2.4} md={2.4}>
          <TextField
            id="status"
            label="Status"
            type="text"
            fullWidth
            size="small"
            value={searchParams?.status}
            onChange={handleInputChange}
            // defaultValue="1"
          />
        </Grid> */}
        <Grid item xs={3} md={3}>
          <TextField
            id="plateNo"
            label="Plate Number"
            type="text"
            fullWidth
            size="small"
            value={searchParams?.plateNo ?? ""}
            onChange={handleInputChange}
            // defaultValue="1"
          />
        </Grid>
        <Grid item xs={3} md={3}>
          <TextField
            id="policyNo"
            label="Policy Number"
            type="text"
            fullWidth
            size="small"
            value={searchParams?.policyNo ?? ""}
            onChange={handleInputChange}
            // defaultValue="1"
          />
        </Grid>
        <Grid item xs={3} md={3}>
          <TextField
            id="claimNo"
            label="Claim Number"
            type="text"
            fullWidth
            size="small"
            value={searchParams?.claimNo ?? ""}
            onChange={handleInputChange}
            // defaultValue="1"
          />
        </Grid>
        <Grid item xs={3} md={3}>
          <Button
            variant="contained"
            className="tp-custom-button-primary"
            fullWidth
            onClick={() => fetchLoaDetails()}
          >
            Search
          </Button>
        </Grid>
      </Grid>
      {/* 
        TODO make a table or text field for LOA details from Salesforce 
    */}
      <Modal sx={{ overflowY: "scroll" }} open={searchParams?.isShowResults}>
        <Box
          sx={{
            padding: 4,
            backgroundColor: "white",
            margin: "auto",
            width: "60%",
            my: 12,
            position: "relative",
          }}
        >
          <Typography variant="h6" className="tp-text-header-primary">
            Search result for LOA details
          </Typography>
          <hr />
          <br />
          {searchParams?.isLoading !== true ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 1500 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <Typography variant="body1" className="tp-text-bold">
                        Claim Number
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1" className="tp-text-bold">
                        Policy Number
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1" className="tp-text-bold">
                        Claimant Email
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1" className="tp-text-bold">
                        Insured Name
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1" className="tp-text-bold">
                        In House Estimate Amount
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1" className="tp-text-bold">
                        Repair Shop
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1" className="tp-text-bold">
                        Depreciation Value
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1" className="tp-text-bold">
                        Deductible
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1" className="tp-text-bold">
                        Total
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1" className="tp-text-bold">
                        Make
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1" className="tp-text-bold">
                        Model
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1" className="tp-text-bold">
                        Body
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1" className="tp-text-bold">
                        Plate Number
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1" className="tp-text-bold">
                        Status
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sfSearchResult?.map((row: any, idx: number) => (
                    <TableRow
                      key={idx}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                        ":hover": { backgroundColor: "#f2f2f2" },
                      }}
                      onClick={() => {
                        console.log(row?.claimNo)
                        setSfLoaDetails({ ...row });
                        setPolicyAsiaData((prev: any) => ({
                          ...prev,
                          claimNo: row?.claimNo
                        }))
                        setSearchParams({
                          ...searchParams,
                          isShowResults: false,
                          isLoading: false,
                        });
                      }}
                    >
                      <TableCell align="center">{row.claimNo}</TableCell>
                      <TableCell align="center">{row.policyNo}</TableCell>
                      <TableCell align="center">{row.claimantEmail}</TableCell>
                      <TableCell align="center">{row.insuredName}</TableCell>
                      <TableCell align="center">
                        {row.inHouseEstimateAmount}
                      </TableCell>
                      <TableCell align="center">{row.vendorName}</TableCell>
                      <TableCell align="center">
                        {row.depreciationValue}
                      </TableCell>
                      <TableCell align="center">{row.deductible}</TableCell>
                      <TableCell align="center">{row.total}</TableCell>
                      <TableCell align="center">{row.make}</TableCell>
                      <TableCell align="center">{row.model}</TableCell>
                      <TableCell align="center">{row.unit}</TableCell>
                      <TableCell align="center">{row.plateNo}</TableCell>
                      <TableCell align="center">{row.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <br />
          <br />
          <Grid item xs={12} md={12}>
            <Button
              disabled={searchParams?.isLoading}
              onClick={() => {
                setSearchParams({
                  ...searchParams,
                  isShowResults: false,
                  isLoading: false,
                });
              }}
              variant="contained"
              size="medium"
              className="tp-custom-button-primary"
              fullWidth
            >
              Close
            </Button>
          </Grid>
        </Box>
      </Modal>
      <br />
      {/* LOA Details */}
      {sfLoaDetails ? (
        <>
          <Grid container spacing={2} sx={{ my: 1 }}>
            <Grid item xs={6} md={6}>
              <Typography
                variant="body2"
                sx={{ color: "#8a8888" }}
                // className="tp-text-bold"
              >
                Claim Number
              </Typography>
              <Typography variant="h6" gutterBottom>
                {sfLoaDetails?.claimNo ?? "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography
                variant="body2"
                sx={{ color: "#8a8888" }}
                // className="tp-text-bold"
              >
                Policy Number
              </Typography>
              <Typography variant="h6" gutterBottom>
                {sfLoaDetails?.policyNo ?? "N/A"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ my: 1 }}>
            <Grid item xs={6} md={6}>
              <Typography
                variant="body2"
                sx={{ color: "#8a8888" }}
                // className="tp-text-bold"
              >
                Claimant Email
              </Typography>
              <Typography variant="h6" gutterBottom>
                {sfLoaDetails?.claimantEmail ?? "N/A"}
              </Typography>
            </Grid>

            <Grid item xs={6} md={6}>
              <Typography
                variant="body2"
                sx={{ color: "#8a8888" }}
                // className="tp-text-bold"
              >
                Insured Name
              </Typography>
              <Typography variant="h6" gutterBottom>
                {sfLoaDetails?.insuredName ?? "N/A"}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ my: 1 }}>
            <Grid item xs={6} md={6}>
              <Typography
                variant="body2"
                sx={{ color: "#8a8888" }}
                // className="tp-text-bold"
              >
                In House Estimate
              </Typography>
              <Typography variant="h6" gutterBottom>
                {sfLoaDetails?.inHouseEstimateAmount ?? "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography
                variant="body2"
                sx={{ color: "#8a8888" }}
                // className="tp-text-bold"
              >
                Repair Shop
              </Typography>
              <Typography variant="h6" gutterBottom>
                {sfLoaDetails?.vendorName ?? "N/A"}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ my: 1 }}>
            <Grid item xs={6} md={6}>
              <Typography
                variant="body2"
                sx={{ color: "#8a8888" }}
                // className="tp-text-bold"
              >
                Depreciation
              </Typography>
              <Typography variant="h6" gutterBottom>
                {formatNumber(sfLoaDetails?.depreciationValue) ?? "0.00"}
              </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography
                variant="body2"
                sx={{ color: "#8a8888" }}
                // className="tp-text-bold"
              >
                Deductible
              </Typography>
              <Typography variant="h6" gutterBottom>
                {formatNumber(sfLoaDetails?.deductible) ?? "0.00"}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ my: 1 }}>
            <Grid item xs={6} md={6}>
              <Typography
                variant="body2"
                sx={{ color: "#8a8888" }}
                // className="tp-text-bold"
              >
                Make
              </Typography>
              <Typography variant="h6" gutterBottom>
                {sfLoaDetails?.make ?? "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography
                variant="body2"
                sx={{ color: "#8a8888" }}
                // className="tp-text-bold"
              >
                Model
              </Typography>
              <Typography variant="h6" gutterBottom>
                {sfLoaDetails?.model ?? "N/A"}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ my: 1 }}>
            <Grid item xs={6} md={6}>
              <Typography
                variant="body2"
                sx={{ color: "#8a8888" }}
                // className="tp-text-bold"
              >
                Unit
              </Typography>
              <Typography variant="h6" gutterBottom>
                {sfLoaDetails?.unit ?? "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography
                variant="body2"
                sx={{ color: "#8a8888" }}
                // className="tp-text-bold"
              >
                Total
              </Typography>
              <Typography variant="h6" gutterBottom>
                {formatNumber(sfLoaDetails?.total) ?? "0.00"}
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
