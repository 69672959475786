import React, { useState, useRef, useEffect } from 'react';
import { PDFDocument, rgb } from 'pdf-lib';
import { saveAs } from 'file-saver';
import * as pdfjsLib from 'pdfjs-dist';
import fontkit from '@pdf-lib/fontkit';
import insurerPdf from '../assets/nccdraft.pdf';
import boldFontUrl from '../assets/arial-bold.ttf';
import regularFontUrl from '../assets/arial.ttf';
// import { RedeemTwoTone } from '@mui/icons-material';


function getCurrentFormattedDate(): string {
  const currentDate = new Date();
  return currentDate.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });
}
const GenerateNCC = ({policyNo, longName, inceptionDate, expiryDate,
  model,
  motorMaker,
  interestDescription,
  motorNo,
  vehicleChassis,
  vehicleRegistrationNumber
}:any) => {
 
  const [pdfBytes, setPdfBytes] = useState<Uint8Array | null>(null);
  const [isRendering, setIsRendering] = useState(false);
  


  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [currentPage, setCurrentPage] = useState(1); // 

  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //   setFormData((prev) => ({ ...prev, [name]: value }));
  // };
    // console.log("partsCart", partsCart);
    
  const generatePdfBytes = async () => {
    try {
      const existingPdfBytes = await fetch(insurerPdf).then((res) => res.arrayBuffer());
      const boldFontBytes = await fetch(boldFontUrl).then((res) => res.arrayBuffer());
      const regularFontBytes = await fetch(regularFontUrl).then((res) => res.arrayBuffer());

      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      pdfDoc.registerFontkit(fontkit)
     
      const pages = pdfDoc.getPages();
      const firstPage = pages[0];
      const boldFont = await pdfDoc.embedFont(boldFontBytes);
      const regularFont = await pdfDoc.embedFont(regularFontBytes);
      const { width, height } = firstPage.getSize();
      
      const textDate = getCurrentFormattedDate();
      const text1 = 'This is to certify that the vehicle of ';
      const text2 = longName;
      const text3 = ' was insured with our company under ';

      const textWidth1 = regularFont.widthOfTextAtSize(text1, 10);
      const textWidth2 = boldFont.widthOfTextAtSize(text2, 10);

     
      firstPage.drawText(textDate, {
        x: 487,
        y: height - 121,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(text1, {
        x: 66,
        y: height - 231,
        size: 10,
        font: regularFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(text2, {
        x: 66 + textWidth1,
        y: height - 231,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(text3, {
        x: 66 + textWidth1 + textWidth2,
        y: height - 231,
        size: 10,
        font: regularFont,
        color: rgb(0, 0, 0),
      });

      const text4 = 'Comprehensive Motor Car Policy ';
      const text5 = policyNo;
      const text6 = ' (Own Damage/ Theft and Voluntary Third Party Liability ';

      const textWidth4 = regularFont.widthOfTextAtSize(text4, 10);
      const textWidth5 = boldFont.widthOfTextAtSize(text5, 10);

     

      firstPage.drawText(text4, {
        x: 66,
        y: height - 242,
        size: 10,
        font: regularFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(text5, {
        x: 66 + textWidth4,
        y: height - 242,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(text6, {
        x: 66 + textWidth4 + textWidth5,
        y: height - 242,
        size: 10,
        font: regularFont,
        color: rgb(0, 0, 0),
      });

      const text7 = 'coverage) from ';
      const text8 = inceptionDate + " to ";
      const text9 = expiryDate;
      const text10 = '. The vehicle insured is described as follows:';

      const textWidth7 = regularFont.widthOfTextAtSize(text7, 10);
      const textWidth8 = boldFont.widthOfTextAtSize(text8, 10);
      const textWidth9 = boldFont.widthOfTextAtSize(text9, 10);


      
     

      firstPage.drawText(text7, {
        x: 66,
        y: height - 253,
        size: 10,
        font: regularFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(text8, {
        x: 66 + textWidth7,
        y: height - 253,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(text9, {
        x: 66 + textWidth7 + textWidth8,
        y: height - 253,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(text10, {
        x: 66 + textWidth7 + textWidth8 + textWidth9,
        y: height - 253,
        size: 10,
        font: regularFont,
        color: rgb(0, 0, 0),
      });


      // bullet points

      firstPage.drawText(`${model} ${motorMaker} ${interestDescription}`, {
        x: 250,
        y: height - 327,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(motorNo, {
        x: 250,
        y: height - 349,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(vehicleChassis, {
        x: 250,
        y: height - 374,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(vehicleRegistrationNumber, {
        x: 250,
        y: height - 399,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
      });



      

     
      const pdfBytes = await pdfDoc.save();
      setPdfBytes(pdfBytes);
      //console.log("PDF Bytes Generated:", pdfBytes);

      // Directly preview the PDF after generating it
      previewPdf(new Uint8Array(pdfBytes), 1);
    } catch (error) {
      console.error("Error generating PDF bytes:", error);
    }
  };

  const previewPdf = async (bytes: Uint8Array, pageNum: number) => {
    if (!bytes) return;

    if (isRendering) return; // Prevent overlapping render operations
    setIsRendering(true);

    const loadingTask = pdfjsLib.getDocument({ data: bytes });
    const pdf = await loadingTask.promise;
    const page = await pdf.getPage(pageNum);

    const viewport = page.getViewport({ scale: 1 });
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');

    if (canvas && context) {
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };
      await page.render(renderContext).promise;
    }

    setIsRendering(false); // Mark rendering as completed
  };

  useEffect(() => {
    if (pdfBytes) {
      previewPdf(new Uint8Array(pdfBytes), currentPage); // Clone the pdfBytes for preview and specify the page number
    }
  }, [pdfBytes, currentPage]);

  const savePdf = () => {
    if (pdfBytes) {
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().replace(/[-:.TZ]/g, '');
      const fileName = `NCC Report_${formattedDate}.pdf`;
      saveAs(blob, fileName);
      //console.log("PDF Saved", blob);
    } else {
      console.error("No PDF bytes to save.");
    }
  };

  return (
    <div>
      {/* <h1>Generate PDF</h1> */}
      <form>
     
        <button type="button" onClick={generatePdfBytes}>Preview PDF</button>

        <button type="button" onClick={savePdf}>Save PDF</button>
      </form>
      <canvas ref={canvasRef} style={{ border: '1px solid black', marginTop: '20px' }}></canvas>
    </div>
  );
};

export default GenerateNCC;
