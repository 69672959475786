function toTitleCase(str: string | undefined | null): string | undefined {
    if (!str) {
        return str as undefined;
    }
  
    return str.replace(/\w\S*/g, (word) => {
        return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    });
}

export {
    toTitleCase
}