import axios from 'axios';

const apiClient = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/acap`, // Change this to your actual backend URL
    headers: {
        'Content-Type': 'application/json',
    },
});

export const getPartsByTransId = async (transId: number) => {
    const response = await apiClient.get(`/parts?transId=${transId}`);
    return response.data;
};

export const postParts = async (part: any) => {
    return apiClient.post('/parts', part);
};

export const updatePartsById = async (id: number, part: any) => {
    return apiClient.put(`/parts?id=${id}`, part);
};

export const deletePartsById = async (id: number) => {
    return apiClient.delete(`/parts/${id}`);
};
