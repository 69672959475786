export function formatDate(dateString: any): string {
  if (typeof dateString !== 'string') {
    return 'Invalid date';
  }

  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Check if the dateString contains 'T', indicating an ISO format with T separator
  const isIsoFormat = dateString.includes('T');

  // Parsing the input date string considering possible formats
  const [datePart, timePart] = isIsoFormat ? dateString.split('T') : dateString.split(' ');

  const [year, month, day] = datePart.split('-').map(Number);
  const [hours, minutes, seconds] = timePart.split(':').map(Number);

  // Create a new Date object with the parsed values
  const date = new Date(year, month - 1, day, hours, minutes, seconds);

  const formattedMonth = months[date.getMonth()];
  const formattedDay = date.getDate();
  const formattedYear = date.getFullYear();

  let formattedHours = date.getHours();
  const formattedMinutes = date.getMinutes();
  const ampm = formattedHours >= 12 ? 'pm' : 'am';

  formattedHours = formattedHours % 12;
  formattedHours = formattedHours ? formattedHours : 12; // the hour '0' should be '12'
  const strMinutes = formattedMinutes < 10 ? `0${formattedMinutes}` : formattedMinutes;

  return `${formattedMonth} ${formattedDay}, ${formattedYear} | ${formattedHours}:${strMinutes}${ampm}`;
}

export function isValidDateTime (dateTimeToString: any) {
  const dateTimeRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;

  if (!dateTimeRegex.test(dateTimeToString)) {
    return false;
  }

  return true;
}
