import * as React from 'react'
import MUIDataTable, { SelectableRows, Responsive } from "mui-datatables";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { CircularProgress, Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { formatDate } from '../api/FormatDate';
import { GlobalDataContext } from '../contexts/GlobalDataContext';
import { log } from 'console';
import { getCurrentUser } from "../utils/GetCurrentUser";
import secureLocalStorage from "react-secure-storage";

export function ViewButton({ url, sender }: any) {
    const navigate = useNavigate()

    return (
        <Button 
                className="tp-custom-button-primary"
                variant="contained"
                size="small" 
                onClick={() => navigate(url, { state: { sender } })}
        >View</Button>
    )
}

export default function TaskTable({ task }: any) {
    const {globalData, setGlobalData, updateGlobalData} = React.useContext(GlobalDataContext);
    const [rows, setRows] = React.useState<any>([]);
    const [columns, setColumns] = React.useState<any>([]);
    const [mainState, setMainState] = React.useState<any>({
        error: "",
        isLoading: false
    });

    const convertStatus = (status: number) => {
        if (status === 0) {
            return { label: 'Bot Processing', component: <Chip label="Bot Processing" size="small" variant="outlined"/> };
        } else if (status === 11) {
            return { label: 'Missing Documents', component: <Chip label="Missing Documents" size="small" variant="outlined" color="primary" /> };
        } else if (status === 10) {
            return { label: 'Manual', component: <Chip label="Manual" size="small" variant="outlined" color="warning" /> };
        } else if (status === 17 || status === 1) {
            return { label: 'Open', component: <Chip label="Open" size="small" variant="outlined" color="success" /> };
        } else if (status === 3) {
            return { label: 'For Damage Evaluation', component: <Chip label="For Damage Evaluation" size="small" variant="outlined" color="success" /> };
        } else {
            return { label: '', component: '' };
        }
    }
    
    // const convertPriority = (priority: number) => {
    //     if (priority == 1) {
    //         return { label: 'High', component: <Chip label="High" size="small" variant="outlined" color="warning"/> };
    //     } else if (priority == 0) {
    //         return { label: 'Low', component: <Chip label="Low" size="small" variant="outlined" color="primary" /> };
    //     } else {
    //         return { label: '', component: '' };
    //     }
    // }
    
    const convertChannel = (channel: number) => {
        if (channel === 1) {
            return { label: 'Email', component: <Chip label="Email" size="small" variant="outlined" color="primary"/> };
        } else if (channel === 2) {
            return { label: 'Web Form', component: <Chip label="Web Form" size="small" variant="outlined" color="primary" /> };
        } else {
            return { label: '', component: '' };
        }
    }
    
    const getAllTaskByTaskType = async (taskType: String) => {
        setMainState({ ...mainState, isLoading: true });
        const loggedInUser = await getCurrentUser(globalData);
        if (loggedInUser?.status === 0) {
            updateGlobalData({ role: null, username: null, idToken: null, isUserLogOut: true, lastLogin: null, lastLogout: null });
            secureLocalStorage.removeItem('username');
            secureLocalStorage.removeItem('role');
            secureLocalStorage.removeItem('idToken');
            secureLocalStorage.removeItem('lastLogin');
            secureLocalStorage.removeItem('lastLogout');
            secureLocalStorage.removeItem('status');
            alert("Deactivated user cannot get CNC / NCC info.");
            const cognitoLoginUrl = process.env.COGNITO_LOGIN_PAGE || 'https://parallax-dev.auth.ap-southeast-1.amazoncognito.com/login?client_id=7eh1v67d71ttli5civvt4rm73n&response_type=code&scope=email+openid+phone+profile&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2F';
            window.location.href = cognitoLoginUrl;
            return;
        } else {
            //console.log("taskType", taskType)
            try {
                const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/acap-task`, {
                    headers: {
                        'Authorization': `Bearer ${globalData?.accessToken}`
                    }
                });
                const data = res.data.filter((item: any) => item.status === 17);
                
        
                if (Array.isArray(data)) {
                    const transformedData = data.map((item: any, index: number) => {
                        const numericStatus = item.status;
                        const displayStatus = convertStatus(item.status);
                        const username = item.userDetails ? item.userDetails.username : 'In Progress';
                        //console.log("acap -", data);
                        
                        return {
                            transId:"ACAP-" + item.id,
                            sender: item.sender,
                            dateCreated: formatDate(item.saveDate),
                            channel: item.channel,
                            channel_display: convertChannel(item.channel).component,
                            // priority: item.priority,
                            // priority_display: convertPriority(item.priority).component,
                            // priority_desc: item.priorityDesc,
                            status: numericStatus,
                            status_display: displayStatus.component,
                            username: username,
                            action: (numericStatus !== 0 && username !== 'In Progress') ? <ViewButton url={`${item.id}`} sender={item.sender} /> : null
                        };
                    });
        
                    setRows(transformedData);
        
                    const channelMap: { [key: string]: string } = { '1': 'Email', '2': 'Web Form' };

                    const statusMap: { [key: string]: string } = { '0': 'Bot Processing', '1': 'Open', '3': 'For Damage Evaluation', '10': 'Manual', '11': 'Missing Documents' };
        
                    const transformedCols = [
                        { name: 'transId', label: 'Trans ID', options: { filter: true, sort: true } },
                        { name: 'sender', label: 'Sender', options: { filter: true, sort: true } },
                        { name: 'dateCreated', label: 'Date Created', options: { filter: true, sort: true } },
                        { 
                            name: 'channel_display', 
                            label: 'CHANNEL', 
                            options: { 
                                filter: false, 
                                sort: false,
                                customBodyRender: (value: any) => value,
                                setCellHeaderProps: () => ({
                                    style: { textAlign: 'center', fontWeight: 'bold' }
                                }),
                                setCellProps: () => ({
                                    style: { textAlign: 'center' }
                                })
                            } 
                        },
                        { 
                            name: 'channel', 
                            label: 'Channel', 
                            options: { 
                                display: false, 
                                filter: true,
                                filterOptions: {
                                    names: Object.keys(channelMap).map(key => channelMap[key]),
                                    logic(channel:any, filterVal:any) {
                                        return !filterVal.includes(channelMap[channel]);
                                    },
                                    display: (filterList:any, onChange:any, index:any, column:any) => (
                                        <div>
                                            <label>Channel</label>
                                            <select 
                                                onChange={event => {
                                                    filterList[index] = event.target.value;
                                                    onChange(filterList[index], index, column);
                                                }}
                                                style={{ width: '100%' }}
                                            >
                                                <option value="">All</option>
                                                {Object.entries(channelMap).map(([value, label]) => (
                                                    <option key={value} value={value}>{label}</option>
                                                ))}
                                            </select>
                                        </div>
                                    )
                                }
                            } 
                        },
                    
                
                        { 
                            name: 'status_display', 
                            label: 'STATUS', 
                            options: { 
                                filter: false, 
                                sort: false,
                                customBodyRender: (value: any) => value,
                                setCellHeaderProps: () => ({
                                    style: { textAlign: 'center', fontWeight: 'bold' }
                                }),
                                setCellProps: () => ({
                                    style: { textAlign: 'center' }
                                })
                            } 
                        },
                        { 
                            name: 'status', 
                            label: 'Status', 
                            options: { 
                                display: false, 
                                filter: true,
                                filterOptions: {
                                    names: Object.keys(statusMap).map(key => statusMap[key]),
                                    logic(status:any, filterVal:any) {
                                        return !filterVal.includes(statusMap[status]);
                                    },
                                    display: (filterList:any, onChange:any, index:any, column:any) => (
                                        <div>
                                            <label>Status</label>
                                            <select 
                                                onChange={event => {
                                                    filterList[index] = event.target.value;
                                                    onChange(filterList[index], index, column);
                                                }}
                                                style={{ width: '100%' }}
                                            >
                                                <option value="">All</option>
                                                {Object.entries(statusMap).map(([value, label]) => (
                                                    <option key={value} value={value}>{label}</option>
                                                ))}
                                            </select>
                                        </div>
                                    )
                                }
                            } 
                        },
                        { name: 'username', label: 'Username', options: { filter: true, sort: true } },
                        { name: 'action', label: 'ACTION', options: { filter: false, sort: false,
                            setCellHeaderProps: () => ({
                                style: { textAlign: 'center', fontWeight: 'bold' }
                            }),
                            setCellProps: () => ({
                                style: { textAlign: 'center' }
                            })

                        } }
                    ];
        
                    setColumns(transformedCols);
                } else {
                    console.error("Unexpected response format:", res.data);
                }
        
                setMainState({ ...mainState, isLoading: false });
            } catch (err: any) {
                console.error("Error:", err);
        
                let errorMessage = "";
                if (err?.response?.status === 401) errorMessage = err?.response?.data;
                if (err?.response?.status === 400) errorMessage = err?.response?.data;
        
                setMainState({ error: errorMessage, isLoading: false });
            }   
        }
    };
    
    
    

    const options = {
        download: false,
        print: false,
        selectableRows: 'none' as SelectableRows,
        responsive: 'standard' as Responsive,
        resizableColumns: true
    }

    const getMuiTheme = () => createTheme({
        components: {
            MUIDataTableHeadCell: {
                styleOverrides:{
                    root: {
                        padding: "5px !important",
                        backgroundColor: "#a42a25 !important",
                        color: "white !important"
                    },
                    toolButton: {
                        fontWeight: "bold !important",
                        width: '100%',
                        justifyContent: 'center'
                    }
                }
            },
            MUIDataTableBodyCell: {
                styleOverrides:{
                    root: {
                        padding: "2px !important",
                        marginLeft: "12px !important",
                        marginRight: "12px !important",
                        textAlign: 'center'
                    }
                }
            },
            MUIDataTableResize: {
                styleOverrides:{
                    resizer: {
                        border: "0.05px solid rgba(0, 0, 0, 0.1) !important"
                    }
                }
            }
        }
    })

    
    React.useEffect(() => {
        getAllTaskByTaskType(task);   
    }, [])

    return (
        <Box sx={{
            my: 4
        }}>
            {
                !mainState.isLoading ? (
                    <ThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable
                            title={"Task List"}
                            data={rows}
                            columns={columns}
                            options={options}
                        />
                    </ThemeProvider>
                ) : (
                    <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )
            }
        </Box>
    )
}