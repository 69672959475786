import React from "react";
import Logs from "./Logs";
import { Grid, IconButton, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useParams } from "react-router-dom";

function ActivityLogs() {
  const [selectedLogs, setSelectedLogs] = React.useState<boolean>(true);
  const { transId } = useParams();

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={11} sm={11} md={11}>
          <Typography variant="h5" className="tp-text-header-primary">
            Activity Logs
          </Typography>
          <Typography
            variant="caption"
            display="block"
            className="tp-text-input-sub"
          >
            Show all status of the transaction.
          </Typography>
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <IconButton color="primary" aria-label="colapse">
            {selectedLogs ? (
              <KeyboardArrowUpIcon
                fontSize="large"
                color="primary"
                onClick={() => setSelectedLogs(!selectedLogs)}
                className="tp-cursor-pointer"
              />
            ) : (
              <KeyboardArrowDownIcon
                fontSize="large"
                color="primary"
                onClick={() => setSelectedLogs(!selectedLogs)}
                className="tp-cursor-pointer"
              />
            )}
          </IconButton>
        </Grid>
      </Grid>
      <hr className="tp-border-hr_primary" />
      <br />
      <br />
      {selectedLogs ? <Logs transID={transId} /> : ""}
    </div>
  );
}

export default ActivityLogs;
