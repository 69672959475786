
const toSnakeCase = (str: string) => {
    return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
  };
  
export function convertKeysToSnakeCase (obj: any): any {
if (Array.isArray(obj)) {
    return obj.map(item => convertKeysToSnakeCase(item));
} else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
    const snakeCaseKey = toSnakeCase(key);
    acc[snakeCaseKey] = convertKeysToSnakeCase(obj[key]);
    return acc;
    }, {} as any);
}
return obj;
};