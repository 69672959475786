import * as React from 'react';
import TaskCard from '../components/TaskCard'
import { Container } from '@mui/material';
import homeData from '../data/homeData.json';
import acaplogo from "../assets/acap.png";

export function HomeData() {
    const [projects, setProjects] = React.useState<any>([]);
    
    React.useEffect(() => {
        const data = homeData;
        setProjects(data);
    },[])

    return (
        <div>
            {
                projects.map((task: any, index: number) => (
                    <TaskCard key={index} task={task}/>
                ))
            }
        </div>
    )
} 


export default function HomePage() {

    return (
        <Container maxWidth="lg" sx={{
            mt: 12
        }}>
            <img src={acaplogo} width={180} height={60} alt='img' />
            <HomeData/>
        </Container>
    )
}