import React, { useContext, useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import { Box, Tooltip, Button } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import LogoutIcon from "@mui/icons-material/Logout";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Container } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import bpiMsLogo from "../assets/bpims_logo.png";
import { formatDate, isValidDateTime } from "../api/FormatDate";
import { convertKeysToSnakeCase } from "../utils/SnakeCaseConverter";
import { GlobalDataContext } from "../contexts/GlobalDataContext";
import dayjs from 'dayjs';
import { UpdateUserApi } from "../api/UpdateUserApi";

declare global {
  interface Window {
    idleTimer?: ReturnType<typeof setTimeout>; // Using ReturnType<typeof setTimeout> for precise typing
  }
}

interface UserData {
  lastLogin: string;
  lastLogout: string;
  userName: string;
  roleName?: string;
}

interface GlobalData {
  username: string | null;
  token: string | null;
  role: string | null;
  firstName: string | null;
  lastName: string | null;
  sessionToken: string | null;
}

export default function ButtonAppBar() {
  // Assuming the secureLocalStorage.getItem method returns a string that you then parse:

  const { globalData, setGlobalData, updateGlobalData } = useContext(GlobalDataContext);
  const navigate = useNavigate();

  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [logoutDueToInactivityOpen, setLogoutDueToInactivityOpen] =
    useState(false);
  const handleDlgCloseInactivity = (event: any, reason: any) => {
    if (reason && reason == "backdropClick") {
      console.log("backdropClicked. Not closing dialog.");
      return;
    }
    setLogoutDueToInactivityOpen(false);
  };

  const handleDlgCloseConcurrent = (event: any, reason: any) => {
    if (reason && reason == "backdropClick") {
      console.log("backdropClicked. Not closing dialog.");
      return;
    }

    setLogoutDialogOpen(false);
  };

  // Function to get last login and logout times from secureLocalStorage
  const getLastLoginLogoutTimes = (): UserData => {
    const userData = secureLocalStorage.getItem("me");
    //console.log('User data:', userData);
    if (typeof userData === "string") {
      try {
        const parsedData = JSON.parse(userData);
        //console.log('Parsed user data:', parsedData);
        return parsedData;
      } catch (error) {
        //console.error('Error parsing user data:', error);
      }
    }
    return { lastLogin: "", lastLogout: "", userName: "" };
  };

  const formatTime = (timeString: string): string => {
    if (!timeString) return "N/A"; // Return 'N/A' if timeString is falsy
    const parts = timeString.split(":");
    if (parts.length < 2) return "Invalid time format"; // Check if the format is valid
    const [hoursString, minutesString] = parts;
    const hours = parseInt(hoursString, 10);
    const minutes = parseInt(minutesString, 10);
    const period = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    return `${formattedHours}:${minutes < 10 ? "0" : ""}${minutes}${period}`;
  };

  const formatDateTime = (dateTimeString: string): string => {
    if (!dateTimeString) return "N/A"; // Check if the string is falsy
    const parts = dateTimeString.split(" ");
    if (parts.length < 2) return "Invalid date-time format"; // Ensure the format is correct
    const date = parts[0];
    const time = formatTime(parts[1]);
    return `${date} ${time}`;
  };

  const { lastLogin, lastLogout, userName } = getLastLoginLogoutTimes();

  const formattedLastLogin = formatDateTime(lastLogin);
  const formattedLastLogout = formatDateTime(lastLogout);

  const getUser = async (username: string | null) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/accounts/${username}`, {
        headers: {
          Authorization: `Bearer ${globalData?.accessToken}`,
        },
      });
      const resData = res.data.data;
      const snakeCaseResponse = convertKeysToSnakeCase(resData);

      return snakeCaseResponse;
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  const handleLogout = async (
    eventOrDueToInactivity?: boolean | MouseEvent
  ) => {
    let dueToInactivity = false;
    const confirmLogout = window.confirm("Are you sure you want to log out?");

    // Determine if the logout was due to inactivity or a button click
    if (typeof eventOrDueToInactivity === "boolean") {
      dueToInactivity = eventOrDueToInactivity;
    }

    try {
      if (dueToInactivity) {        
        setLogoutDueToInactivityOpen(true);
      } else {
        if (confirmLogout) {
          const userToUpdate = await getUser(globalData?.username);
          
          await UpdateUserApi(userToUpdate, globalData, globalData?.username, globalData?.accessToken);
          await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/authentication/logout`, {
            username: globalData?.username,
            accessToken: globalData?.accessToken
          }
          ).then((res) => {
            updateGlobalData({ 
              firstName: null,
              lastName: null,
              role: null, 
              username: null, 
              idToken: null, 
              isUserLogOut: true, 
              lastLogin: null, 
              lastLogout: null 
            });
              secureLocalStorage.removeItem('firstName');
              secureLocalStorage.removeItem('lastName');
              secureLocalStorage.removeItem('username');
              secureLocalStorage.removeItem('role');
              secureLocalStorage.removeItem('idToken');
              secureLocalStorage.removeItem('accessToken');
              secureLocalStorage.removeItem('lastLogin');
              secureLocalStorage.removeItem('lastLogout');
              secureLocalStorage.removeItem('status');
              secureLocalStorage.removeItem('challengeName');
              secureLocalStorage.removeItem('issuer');
              secureLocalStorage.removeItem('refreshToken');
              secureLocalStorage.removeItem('secretKey');
              secureLocalStorage.removeItem('session');
              secureLocalStorage.removeItem('tokenExpiration');
              navigate('/');
          })
        }
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  useEffect(() => {
    const checkSessionValidity = () => {
      axios 
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/api/auth/check-status/${globalData.username}`
        )
        .then((response) => {
          if (response.data.isLogged) {
            setLogoutDialogOpen(true); // Show the logout dialog
            // console.log('dialogopen');
          }
        })
        .catch((error) => {
          console.error("Error checking session status:", error);
        });
    };

    const intervalId = setInterval(checkSessionValidity, 991800000); // Poll every 10 seconds

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [globalData.username]);

  const handleConcurrentLogoutConfirm = () => {
    handleLogout().finally(() => {
      setLogoutDialogOpen(false);
      navigate("/");
    });
  };

  // useEffect(() => {
  //   // Auto log-out when idle
  //   const handleActivity = () => {
  //     clearTimeout(window.idleTimer);
  //     window.idleTimer = setTimeout(() => {
  //       // Auto log-out logic here
  //       handleLogout(true);
  //     }, 1800000); // 30 minutes of inactivity, 30 seconds (30000)
  //   };

  //   document.addEventListener("mousemove", handleActivity);
  //   document.addEventListener("keypress", handleActivity);

  //   // Function to be called on tab/window close
  //   function preventPageUnload(event: BeforeUnloadEvent) {
  //     if (!isLoggingOut) {
  //       event.preventDefault();
  //       event.returnValue = ''; // Show confirmation modal
  //     }
  //   }
  //   window.addEventListener("beforeunload", preventPageUnload);

  //   // Cleanup
  //   return () => {
  //     document.removeEventListener("mousemove", handleActivity);
  //     document.removeEventListener("keypress", handleActivity);

  //     clearTimeout(window.idleTimer); // Make sure to clear the timer on component unmount
  //   };
  // }, []);

  const handleInactivityLogoutClose = () => {
    handleLogout().finally(() => {
      setLogoutDueToInactivityOpen(false);
      navigate("/");
    });
  };

  return (
    <Box>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          backgroundColor: "white",
          borderBottom: "3px solid #ddbc19"
        }}
      >
        <Container maxWidth={"xl"}>
          <Toolbar disableGutters>
            <Box>
              <img src={bpiMsLogo} width={150} alt='img' />
            </Box>
            {/* <Box>
              <Typography variant="h4">ACAP/ ISIP</Typography>
            </Box> */}
            {(globalData?.role !== "Isip Processor" && globalData?.role !== "Isip Manager" && globalData?.role !== "Admin") ? (
              <Button
                component="a"
                href="https://form.123formbuilder.com/6689778/bpims-web-form-internal"
                target="_blank"
                rel="noopener noreferrer"
                color="primary"
                sx={{ ml: 5 }}
              >
                File a Claim
                <OpenInNewIcon />
              </Button>
            ) : (
              <></>
            )}

            <Box sx={{ flexGrow: 1 }} />

            <Tooltip
              title={
                <Typography>
                  Username: {globalData?.username}
                  <br />
                  Last login: {isValidDateTime(globalData?.lastLogin) ? formatDate(globalData?.lastLogin): "N/A"}
                  <br />
                  Last logout: {
                    isValidDateTime(globalData?.lastLogout) ? formatDate(globalData?.lastLogout): "N/A"
                  }
                </Typography>
              }
              placement="bottom"
              arrow
            >
              <AccountCircleIcon sx={{ 
                color: "#a42a25", 
                height: 28, 
                width: 28, 
                cursor: "pointer" 
              }} />
            </Tooltip>
            <Button
              size="small"
              disableRipple={true}
              onClick={() => handleLogout()}
            >
              <LogoutIcon sx={{ 
                color: "#a42a25", 
                height: 28, 
                width: 28 
                }} />
              {/* <Typography sx={{ ml: 1 }}>Logout</Typography> */}
            </Button>
          </Toolbar>
        </Container>
      </AppBar>
      <Dialog
        open={logoutDueToInactivityOpen}
        // onClose={() => handleDlgCloseInactivity}
        aria-labelledby="logout-dialog-title-inactivity"
        aria-describedby="logout-dialog-description-inactivity"
      >
        <DialogTitle id="logout-dialog-title-inactivity">
          {"Logged Out"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="logout-dialog-description-inactivity">
            Due to inactivity, you have been logged out.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleInactivityLogoutClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for logout for other reasons */}
      <Dialog
        open={logoutDialogOpen}
        onClose={() => handleDlgCloseConcurrent}
        aria-labelledby="logout-dialog-title"
        aria-describedby="logout-dialog-description"
        // onClose={handleDlgClose}
      >
        <DialogTitle id="logout-dialog-title">{"Logged Out"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="logout-dialog-description">
            You have been logged out because your account was logged in from
            another device.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConcurrentLogoutConfirm} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* 
      // #region Alert Notification
      */}
      {/* <AlertNotification /> */}
    </Box>
  );
}
