import axios from "axios";
import dayjs from 'dayjs';
import { isValidDateTime, formatDate } from "./FormatDate";

export const UpdateUserApi = async (
  userToUpdate: any,
  globalData: any,
  username: string | null,
  accessToken: string | null
) => {
  const dateTimeString = dayjs().format('YYYY-MM-DD HH:mm:ss');
  const userPayload =  {
    email: userToUpdate?.email,
    givenName: userToUpdate?.given_name === null ? "N/A" : userToUpdate?.given_name,
    familyName: userToUpdate?.family_name === null ? "N/A" : userToUpdate?.family_name,
    lastLogin: isValidDateTime(globalData?.lastLogin) ? formatDate(globalData?.lastLogin) : dateTimeString,
    lastLogout: isValidDateTime(dateTimeString) ? dateTimeString : "N/A",
    roleName: userToUpdate?.role_name === null ? "N/A" : userToUpdate?.role_name,
    passwordAttempt: userToUpdate?.password_attempt === null ? 0 : userToUpdate?.password_attempt,
    lastPasswordUpdate: userToUpdate?.last_password_update === null ? "N/A" : userToUpdate?.last_password_update,
    passwordExpiry: 90,
    maxPasswordAttempts: 5,
    roleLevel: userToUpdate.data?.role_level === null ? "1" : userToUpdate?.role_level,
    status: userToUpdate?.status === null ? "0" : userToUpdate?.status
  };

  await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/accounts/${username}`, userPayload, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
  }).catch((err) => {
    console.error(err)
  })
}