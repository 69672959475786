import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
} from "react";
import axios from "axios";

export type AuditLogData = {
  id?: number | null;
  transId?: number | null;
  taskType: string | null;
  event: string | null;
  description: string | null;
  ipAddress?: string | null;
  saveDate?: string | null;
  userDetails: {
    id: number | null;
    userName: string | null;
    firstName: string | null;
    lastName: string | null;
    roleName: string | null;
  };
};

type AuditLogDataRequest = {
  transId: number
  taskType: string
  event: string
  description: string
  userId?: number | null
};

interface AuditTrailState {
  data: AuditLogData[];
  isLoading: boolean;
  pagination: {
    page: number;
    count: number;
    size: number;
  };
}

interface AuditLogDataContextInterface {
  auditTrailData: AuditTrailState;
  setAuditTrailData: React.Dispatch<React.SetStateAction<AuditTrailState>>;
  updateAuditTrailData: (data: Partial<AuditLogData>[]) => void;
  fetchAuditTrailData: (
    transId: number,
    page: number,
    size: number
  ) => Promise<void>;
  createAuditTrailData: (dataRequest: AuditLogDataRequest) => Promise<void>;
}

const initialState: AuditTrailState = {
  data: [],
  isLoading: false,
  pagination: {
    page: 1,
    count: 0,
    size: 3,
  },
};

const initialContextState: AuditLogDataContextInterface = {
  auditTrailData: initialState,
  setAuditTrailData: () => {},
  updateAuditTrailData: () => {},
  fetchAuditTrailData: async () => {},
  createAuditTrailData: async () => {},
};

export const AuditTrailDataContext =
  createContext<AuditLogDataContextInterface>(initialContextState);
export const useAuditTrailData = () => useContext(AuditTrailDataContext);

type AuditTrailDataProviderProps = {
  children: ReactNode;
};

export default function AuditTrailDataStore({
  children,
}: AuditTrailDataProviderProps) {
  const [auditTrailData, setAuditTrailData] =
    useState<AuditTrailState>(initialState);

  // Function to update parts of the global data
  const updateAuditTrailData = useCallback((data: Partial<AuditLogData>[]) => {
    setAuditTrailData((prev) => ({
      ...prev,
      data: prev.data.map((item, index) =>
        data[index] ? { ...item, ...data[index] } : item
      ),
    }));
  }, []);

  const fetchAuditTrailData = useCallback(
    async (transId: number, page: number, size: number) => {
      try {
        setAuditTrailData((prev) => ({
          ...prev,
          isLoading: true,
        }));
        
        const paginatedResponse = await axios.get(
          `${
            process.env.REACT_APP_BACKEND_URL
          }/api/audit-trail/pagination/${transId}?page=${page - 1}&size=${size}`
        );
        const paginatedData = paginatedResponse?.data?.data?.content;

        const totalItems = paginatedResponse?.data?.data?.totalPages;
        const totalPages = Math.ceil(totalItems / size);

        setAuditTrailData((prev) => ({
          ...prev,
          data: paginatedData,
          isLoading: false,
          pagination: {
            ...prev.pagination,
            page: page,
            count: totalPages,
            size: size,
          },
        }));
      } catch (err) {
        console.error(err);
        setAuditTrailData((prev) => ({
          ...prev,
          isLoading: false,
        }));
      }
    },
    []
  );

  const createAuditTrailData = useCallback(async (dataRequest: AuditLogDataRequest) => {
    setAuditTrailData((prev) => ({
      ...prev,
      isLoading: true,
    }));
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/audit-trail/`,
        dataRequest
      );
      await fetchAuditTrailData(dataRequest.transId, 1, 3);
    } catch (err) {
      console.error(err);
    } finally {
      setAuditTrailData((prev) => ({
        ...prev,
        isLoading: false,
      }));
    }
  }, [fetchAuditTrailData]);

  return (
    <AuditTrailDataContext.Provider
      value={{
        auditTrailData,
        setAuditTrailData,
        updateAuditTrailData,
        fetchAuditTrailData,
        createAuditTrailData,
      }}
    >
      {children}
    </AuditTrailDataContext.Provider>
  );
}
