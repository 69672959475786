import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  FormGroup,
  FormControlLabel,
  Typography,
  Box,
  Grid,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import EmailIcon from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { formatDateEmail } from '../api/FormatDateEmail';
import { GlobalDataContext } from "../contexts/GlobalDataContext";

interface EmailMissingProps {
  transId: any; // Ensure transId is a number
  sender: string;
}

interface EmailHistoryEntry {
  id: number;
  trans_id: number;
  peril_type: string;
  documents: string;
  sent_date: string | null; 
}

const perilTypes: Record<string, string[]> = {
  'Own Damage': [
    'Police Report/ Accident Report Form/ Notarized Affidavit',
    "Photocopy of Driver's License",
    "Photocopy of Driver's License Current Official Receipt",
    "Photocopy of Vehicle's Certificate of Registration (CR)",
    "Photocopy of Current Official Receipt (OR) of Vehicle's Certificate of Registration (CR)",
    'Pictures of the scene of accident, if available',
    'Estimate from preferred shop, if available',
    'Unit inspection',
  ],
  'Carnap Claim': [
    "Photocopy of Vehicle's Certificate of Registration (CR)",
    "Photocopy of Current Official Receipt (OR) of Vehicle's Certificate of Registration (CR)",
    'Police Report',
    'Alarm Sheet',
    'Complaint Sheet',
    'Certificate of Non-Recovery',
  ],
  'Third Party- Property Damage involving another vehicle': [
    "Photocopy of Vehicle's Certificate of Registration (CR)",
    "Photocopy of Current Official Receipt (OR) of Vehicle's Certificate of Registration (CR)",
    'Certificate of No Claim (CNC) from the Third Party Insurer',
    'Pictures of damaged unit, if available',
    'Unit inspection',
  ],
  'Third Party- Property Damage other than to a Vehicle': [
    'Proof of ownership of damaged property',
    'Colored photos of damaged property',
    'Repair estimate/ quotation from at least two (2) contractors, engineers, architects &/or suppliers',
  ],
  'Unnamed Passenger Personal Accident (UPPA)/ Bodily Injury': [
    'Police Report/ Accident Report Form/ Notarized Affidavit',
    "Photocopy of Driver's License",
    "Photocopy of Driver's License Current Official Receipt",
    "Photocopy of Vehicle's Certificate of Registration (CR)",
    "Photocopy of Current Official Receipt (OR) of Vehicle's Certificate of Registration (CR)",
    'Medical Certificate',
    'Hospital Statement of Account',
    'Original Medical Receipts/ Hospital Receipts',
  ],
  'Third Party Death Claim for CTPL/VTPL': [
    'Death Certificate',
    'Original Receipt of Funeral/ Burial expenses',
    "Valid ID's of injured party",
  ],
  'UPPA with Death Claim': [
    'Death Certificate',
    'Original Receipt of Funeral/ Burial expenses',
    "Valid ID's of injured party",
  ],
};

const EmailMissing = ({ transId, sender }: EmailMissingProps) => {
  const [open, setOpen] = useState(false);
  const [perilType, setPerilType] = useState<string>('');
  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
  const [emailHistory, setEmailHistory] = useState<EmailHistoryEntry[]>([]);
  const [loading, setLoading] = useState(false);
  const { globalData } = React.useContext(GlobalDataContext);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handlePerilTypeChange = (event: SelectChangeEvent<string>) => {
    setPerilType(event.target.value);
    setSelectedDocuments([]);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const document = event.target.name;
    if (event.target.checked) {
      setSelectedDocuments([...selectedDocuments, document]);
    } else {
      setSelectedDocuments(selectedDocuments.filter((doc) => doc !== document));
    }
  };

  const handleSendEmail = async () => {
    setLoading(true);

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/email/send`, {
        transId,
        perilType,
        sender,
        documents: selectedDocuments,
      }, {
        headers: {
          'Authorization': `Bearer ${globalData?.accessToken}`
        }
      });

      // Fetch the updated email history
      await fetchEmailHistory();
    } catch (error) {
      console.error('Error sending email:', error);
    } finally {
      setLoading(false);
      setSelectedDocuments([]);
      setPerilType('');
    }
  };

  const fetchEmailHistory = async () => {
    try {
      const response = await axios.get<EmailHistoryEntry[]>(`${process.env.REACT_APP_BACKEND_URL}/api/email/history/${transId}`);
      setEmailHistory(response.data);
    } catch (error) {
      console.error('Error fetching email history:', error);
    }
  };

  useEffect(() => {
    fetchEmailHistory();
  }, [transId]);

  return (
    <div>
      <Button variant="contained" color="warning" onClick={handleOpen} startIcon={<EmailIcon />}>
        Send Missing Documents Email
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 2, backgroundColor: 'white', maxWidth: 800, margin: 'auto', marginTop: '5%', position: 'relative' }}>
          <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 8, right: 8 }}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Send Missing Documents Email</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="peril-type-label">Choose Peril Type</InputLabel>
                <Select labelId="peril-type-label" value={perilType} onChange={handlePerilTypeChange}>
                  {Object.keys(perilTypes).map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormGroup>
                {perilTypes[perilType]?.map((document) => (
                  <FormControlLabel
                    key={document}
                    control={<Checkbox checked={selectedDocuments.includes(document)} onChange={handleCheckboxChange} name={document} />}
                    label={document}
                  />
                ))}
              </FormGroup>
              <Box display="flex" justifyContent="space-between" mt={2}>
                <Button variant="contained" color="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSendEmail}
                  disabled={selectedDocuments.length === 0 || loading}
                  startIcon={loading ? <CircularProgress size={24} /> : undefined}
                >
                  {loading ? 'Sending...' : 'Send Email'}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" textAlign="center">
                Email History
              </Typography>
              <Box sx={{ maxHeight: 400, overflowY: 'auto', pr: 1 }}>
                <List>
                  {emailHistory.map((email) => (
                    <ListItem key={email.id} alignItems="flex-start">
                      <ListItemText
                       primary={`Email sent: ${email.sent_date ? formatDateEmail(email.sent_date) : 'No date available'}`}
                        secondary={
                          <>
                            <Typography variant="body2" color="textPrimary">
                              Peril Type: {email.peril_type}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              Missing Documents:
                              <List sx={{ listStyleType: 'disc', pl: 4 }}>
                                {email.documents.split(', ').map((doc, idx) => (
                                  <ListItem key={idx} sx={{ display: 'list-item', p: 0 }}>
                                    {doc}
                                  </ListItem>
                                ))}
                              </List>
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default EmailMissing;
