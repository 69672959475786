import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {
    DataGrid,
    GridActionsCellItem,
    GridColDef,
    GridEventListener,
    GridRowEditStopReasons,
    GridRowId,
    GridRowModel,
    GridRowModes,
    GridRowModesModel,
    GridRowsProp,
    GridToolbarContainer,
} from '@mui/x-data-grid';

import {
    randomId
} from '@mui/x-data-grid-generator';
import * as React from 'react';
// import { apiFetch } from 'robin-todo-list/src/api/RestApi';
// import estimationTableData from '../../data/estimationTableData.json';
const initialRows: GridRowsProp = [
    {
        id: 1,
        desc:'ADDITIONAL LABOR FOR REPLACE RH STEP BOARD AND COVER',
        qty: "2,400.00",
        partNo: "",
        unitPrice: "",
        totalPrice: ""
    },
    {
        id: 3,
        desc:'ADDITIONAL LABOR FOR PAINT RRH DOOR',
        qty: "2,600.00",
        partNo: "",
        unitPrice: "",
        totalPrice: ""
    },

    {
        id: 4,
        desc:'RH STEP BOARD AND COVER',
        qty: "",
        partNo: "3,900.00",
        unitPrice: "3,900.00",
        totalPrice: "3,900.00"
    },
];

interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
        newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
}

export default function EstimationTable({ transID, disabled }: any) {
    const [rows, setRows] = React.useState(initialRows);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    function EditToolbar(props: EditToolbarProps) {
        const { setRows, setRowModesModel } = props;
    
        const handleClick = () => {
            const id = randomId();
            setRows((oldRows) => [...oldRows, { id, name: '', age: '', isNew: true }]);
            setRowModesModel((oldModel) => ({
                ...oldModel,
                [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
            }));
        };
    
        return (
            <GridToolbarContainer>
                <Grid container spacing={2}>
                    <Grid item md={10}>
                        <Typography variant="h6" className="tp-text-header-primary tp-padding-15px">
                            List of Items
                            <Typography variant="caption" display="block" className="tp-text-subtitle">
                                Note: Please add atleast one item.
                            </Typography>
                        </Typography>
                    </Grid>
                    {disabled === true ? <></> :
                        <Grid item md={2} alignSelf="center" textAlign="center">
                            <Button color="primary" size='small' variant='contained' startIcon={<AddIcon />} onClick={handleClick}>
                                Add Item
                            </Button>
                    </Grid>}
                </Grid>
                {/* <GridToolbarExport /> */}
            </GridToolbarContainer>
        );
    }

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        setRows(rows.filter((row) => row.id !== id));
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow!.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = (newRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false };
        console.log(newRow)
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const columns: GridColDef[] = [
      
        {
            field: 'desc',
            headerName: 'Job Description',
            headerAlign: 'left',
            flex: 1,
            align: 'left',
            editable: true,
        },
        {
            field: 'qty',
            headerName: 'Cost of Labor',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            editable: true,
        },
        {
            field: 'partNo',
            headerName: 'Cost of Part/Materials',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            editable: true,
        },
        {
            field: 'actions',
            type: 'actions',
            hideable: true,
            headerName: 'Actions',
            width: 150,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <>
                        <GridActionsCellItem
                        icon={<SaveIcon className="tp-primary-icon-btn" />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                        icon={<CancelIcon className="tp-danger-icon-btn" />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        /></>,
                    ];
                }

                return [
                    <GridActionsCellItem
                    icon={<EditIcon className="tp-primary-icon-btn" />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                    icon={<DeleteIcon className="tp-danger-icon-btn" />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Box
        sx={{
            height: 424,
            width: '100%',
            '& .MuiDataGrid-columnHeader': {
                backgroundColor: '#2683e0',
                color: '#ffffff'
            },
        }}
        ><DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
            toolbar: EditToolbar,
        }}
        slotProps={{
            toolbar: { setRows, setRowModesModel },
        }}
        disableRowSelectionOnClick
        disableColumnMenu
        initialState={{
            columns: {
              columnVisibilityModel: {
                actions: disabled === true ? false : true,
              },
            },
        }}
    />
        </Box>
    );
}
