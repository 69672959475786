import React, { useState, ChangeEvent, useEffect, useMemo } from 'react';
import {
  TextField,
  Autocomplete,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Checkbox,
  Select,
  MenuItem,
} from '@mui/material';
import vehiclePartsDataJson from './vehiclePartsData.json';
import { postParts, deletePartsById, getPartsByTransId } from './ApiService'; // Import the API functions
import { useParams } from 'react-router-dom';
import { useRefresh } from '../RefreshContext';

type VehiclePart = {
  VehicleBrand: string;
  VehicleModel: string;
  Parts: string;
  Price: string;
};

type CartItem = {
  id?: number; // Make id optional because it will be assigned by the backend
  deId?: string;
  transId?: string;
  name: string;
  amount: number;
  qty: number;
  price: number;
  saveDate?: string;
  applyDepreciation?: boolean; // New field to track if depreciation is applied
  depreciatedAmount?: number;
  status?: number | null;   // New field to store the depreciated amount
};

type ListOfPartsProps = {
  onTotalAmountChange: (total: string) => void;
  updateCart: (cart: CartItem[]) => void;
  onTotalDepreciationChange: (totalDepreciation: number) => void;
  onTotalOfferAmountChange: (totalOfferAmount: number) => void;
  initialBrand: string | null;
  initialModel: string | null;
};

const formatNumber = (value: number) => {
  return value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

const vehiclePartsData: VehiclePart[] = vehiclePartsDataJson as VehiclePart[];

const ListOfParts: React.FC<ListOfPartsProps> = ({ onTotalAmountChange, onTotalDepreciationChange,
  onTotalOfferAmountChange, updateCart, initialBrand, initialModel }) => {
  const [brand, setBrand] = useState<string | null>(initialBrand);
  const [model, setModel] = useState<string | null>(initialModel);
  const [part, setPart] = useState<string>('');
  const [price, setPrice] = useState<string>('');
  const { transId } = useParams<{ transId: string }>();
  const [qty, setQty] = useState<string>('1');
  const [cart, setCart] = useState<CartItem[]>([]);
  const [filteredModels, setFilteredModels] = useState<string[]>([]);
  const [filteredParts, setFilteredParts] = useState<string[]>([]);
  const { refresh: contextRefresh } = useRefresh();
  const [totalDepreciation, setTotalDepreciation] = useState<number>(0);
  const [globalDepreciationPercentage, setGlobalDepreciationPercentage] = useState<number>(0);

  useEffect(() => {
    const fetchParts = async () => {
      try {
        const response = await getPartsByTransId(Number(transId));
        const parts = Array.isArray(response.data) ? response.data : [];
        console.log('Fetched parts:', parts);
        setCart(
          parts.map((part: any) => {
            const status = Number(part.status);
            const isDepreciationApplied = parseInt(part.status) === 1;
            const depreciationAmount = isDepreciationApplied
              ? (parseFloat(part.amount) * globalDepreciationPercentage) / 100
              : 0;
  
            console.log(`Part ${part.id}: status = ${part.status}, isDepreciationApplied = ${isDepreciationApplied}`);
  
            return {
              id: part.id,
              deId: part.deId,
              transId: part.transId,
              status: status,
              name: part.name,
              amount: parseFloat(part.amount),
              qty: part.qty,
              price: parseFloat(part.amount),
              applyDepreciation: isDepreciationApplied,  // This should now correctly reflect the status
              depreciatedAmount: depreciationAmount,
            };
          })
        );
      } catch (error) {
        console.error('Error fetching parts:', error);
      }
    };
  
    if (transId) {
      fetchParts();
    }
  }, [transId, contextRefresh, globalDepreciationPercentage]);


  useEffect(() => {
    const total = cart.reduce((acc, curr) => acc + curr.amount, 0).toFixed(2);
    onTotalAmountChange(total); // Call the passed function to update parent's state
    updateCart(cart);

  }, [cart, onTotalAmountChange, updateCart, onTotalDepreciationChange]); // Depend on cart

  useEffect(() => {
    const totalOfferAmount = cart.reduce((acc, curr) => acc + curr.amount, 0);
    onTotalOfferAmountChange(totalOfferAmount);
    onTotalDepreciationChange(totalDepreciation);
  }, [cart, totalDepreciation, onTotalOfferAmountChange, onTotalDepreciationChange]);

  useEffect(() => {
    if (brand) {
      const models = Array.from(
        new Set(
          vehiclePartsData
            .filter((item: VehiclePart) => item.VehicleBrand.toUpperCase() === brand.toUpperCase())
            .map((item: VehiclePart) => item.VehicleModel)
        )
      );
      models.sort(); // Sort models alphabetically
      setFilteredModels(models);
    }
  }, [brand]);

  useEffect(() => {
    let parts: string[] = [];
    if (brand && model) {
      parts = Array.from(
        new Set(
          vehiclePartsData
            .filter((item) => item.VehicleBrand.toUpperCase() === brand.toUpperCase() && item.VehicleModel === model)
            .map((item) => item.Parts)
        )
      );
    } else if (brand) {
      parts = Array.from(
        new Set(
          vehiclePartsData
            .filter((item) => item.VehicleBrand.toUpperCase() === brand.toUpperCase())
            .map((item) => item.Parts)
        )
      );
    } else if (model) {
      parts = Array.from(
        new Set(
          vehiclePartsData.filter((item) => item.VehicleModel === model).map((item) => item.Parts)
        )
      );
    }
    parts.sort(); // Sort parts alphabetically
    setFilteredParts(parts);
  }, [brand, model]);

  useEffect(() => {
    if (part) {
      updatePriceForPart(part);
    }
  }, [part]);

  useEffect(() => {
    // Update the brand state when initialBrand changes
    if (initialBrand) {
      setBrand(initialBrand);
      setModel(null); // Set model to null when brand changes
    }
  }, [initialBrand]);

  const cleanPrice = (priceString: string) => {
    return priceString.replace(/[^\d.-]/g, '');
  };

  const updatePriceForPart = (inputPart: string) => {
    const item = vehiclePartsData.find(
      (item) =>
        item.VehicleBrand.toUpperCase() === brand?.toUpperCase() &&
        item.VehicleModel === model &&
        item.Parts === inputPart
    );
    if (item) {
      setPrice(cleanPrice(item.Price));
    } else {
      setPrice('');
    }
  };

  const handleBrandChange = (_event: ChangeEvent<{}>, value: string | null) => {
    setBrand(value);
    setModel(null);
    setPart('');
    setPrice('');
  };

  const handleModelChange = (_event: ChangeEvent<{}>, value: string | null) => {
    setModel(value);
    setPart('');
    setPrice('');
  };

  const handlePartInputChange = (_event: any, value: string, reason: string) => {
    setPart(value);
  };

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrice(event.target.value);
  };

  const handleQtyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQty(event.target.value);
  };

  const handleAddToCart = async () => {
    if (isAddButtonDisabled) return;
    const amount = parseFloat(cleanPrice(price)) * parseFloat(qty);
    const currentDate = new Date();
    const timezoneOffset = currentDate.getTimezoneOffset() * 60000;
    const localDate = new Date(currentDate.getTime() - timezoneOffset);
    const saveDate = localDate.toISOString().slice(0, -1);

    const newCartItem: CartItem = {
      deId: transId,
      transId: transId,
      name: part,
      amount,
      qty: parseFloat(qty),
      price: parseFloat(price),
      saveDate: saveDate, // Add current DateTime
      applyDepreciation: false, // Initialize with false
      depreciatedAmount: 0, // Initialize with the original amount
    };

    // Add part to the backend
    try {
      const response = await postParts(newCartItem);
      const savedPart = response?.data?.data;

      // Add the new part with its id to the cart
      setCart([...cart, { ...newCartItem, id: savedPart?.id }]);
      setQty('1'); // Reset qty to 1
    } catch (error) {
      console.error('Error adding part:', error);
    }
  };

  const handleDeleteFromCart = async (index: number) => {
    const itemToDelete = cart[index];

    //console.log(itemToDelete);

    // Check if item has an id before attempting to delete
    if (itemToDelete.id) {
      // Delete part from the backend
      try {
        await deletePartsById(itemToDelete.id);
        const newCart = [...cart];
        newCart.splice(index, 1);
        setCart(newCart);
      } catch (error) {
        console.error('Error deleting part:', error);
      }
    } else {
      console.error('Cannot delete item without an id');
    }
  };

  const handleEditCartItem = (index: number, field: string, value: string | number) => {
    const newCart = [...cart];
    const cartItem = newCart[index];

    if (field === 'qty') {
      cartItem.qty = Number(value);
    } else if (field === 'price') {
      cartItem.price = Number(value);
    }

    cartItem.amount = cartItem.qty * cartItem.price;
    cartItem.depreciatedAmount = cartItem.amount * (cartItem.applyDepreciation ? (1 - globalDepreciationPercentage / 100) : 1);
    newCart[index] = cartItem;
    setCart(newCart);
  };

  const isAddButtonDisabled = useMemo(() => {
    const isPriceValid = price.trim() !== '' && !isNaN(parseFloat(price));
    const isQtyValid = qty.trim() !== '' && !isNaN(parseInt(qty)) && parseInt(qty) > 0;
    return part.trim() === '' || !isPriceValid || !isQtyValid;
  }, [part, price, qty]);

  const handleApplyDepreciationChange = async (index: number, checked: boolean) => {
    const newCart = [...cart];
    const cartItem = newCart[index];
    cartItem.applyDepreciation = checked;
    cartItem.depreciatedAmount = checked ? (cartItem.amount * globalDepreciationPercentage / 100) : 0;

    const newStatus = checked ? 1 : 0;
    cartItem.status = newStatus;
    //console.log('newStatus:', newStatus);

    try {
      await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/parts/status?id=${cartItem.id}&status=${newStatus}`, {
        method: 'PUT', // Assuming it's a PUT request; adjust if necessary
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Update the cart after a successful API call
      newCart[index] = cartItem;
      setCart(newCart);
      updateTotalDepreciation(newCart);
    } catch (error) {
      console.error('Error updating part status:', error);
      // Revert the status if the API call fails
      cartItem.applyDepreciation = !checked;
      cartItem.status = checked ? 0 : 1;
      cartItem.depreciatedAmount = !checked ? (cartItem.amount * globalDepreciationPercentage / 100) : 0;
      setCart(newCart);
    }
  };

  const handleGlobalDepreciationChange = (value: number) => {
    setGlobalDepreciationPercentage(value);
    updateTotalDepreciation(cart, value);
  };

  const updateTotalDepreciation = (cart: CartItem[], depreciationPercentage: number = globalDepreciationPercentage) => {
    const totalDep = cart.reduce((acc, curr) => {
      const depAmount = curr.applyDepreciation ? (curr.price * curr.qty * depreciationPercentage) / 100 : 0;
      return acc + depAmount;
    }, 0);
    setTotalDepreciation(totalDep);

    // Update depreciated amounts for each item
    const updatedCart = cart.map((item) => ({
      ...item,
      depreciatedAmount: item.applyDepreciation ? (item.amount * depreciationPercentage / 100) : 0,
    }));
    setCart(updatedCart);
  };

  const calculateTotal = () => {
    return formatNumber(cart.reduce((acc, curr) => acc + curr.amount, 0));
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={3}>
        <Autocomplete
          options={Array.from(new Set(vehiclePartsData.map((item) => item.VehicleBrand.toUpperCase()))).sort()}
          value={brand}
          onChange={handleBrandChange}
          isOptionEqualToValue={(option, value) => option.toUpperCase() === value?.toUpperCase()}
          renderInput={(params) => <TextField {...params} label="Vehicle Brand" />}
          size="small"
        />
      </Grid>
      <Grid item xs={3}>
        <Autocomplete
          options={filteredModels}
          value={model}
          onChange={handleModelChange}
          isOptionEqualToValue={(option, value) => option === value}
          renderInput={(params) => <TextField {...params} label="Vehicle Model" />}
          disabled={!brand}
          size="small"
        />
      </Grid>
      <Grid item xs={3}>
        <Autocomplete
          options={filteredParts}
          value={part}
          onInputChange={handlePartInputChange}
          isOptionEqualToValue={(option, value) => option === value}
          freeSolo
          renderInput={(params) => <TextField {...params} label="Parts" />}
          disabled={!brand || !model}
          size="small"
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label="Amount"
          value={price}
          onChange={handlePriceChange}
          type="text"
          size="small"
          error={price.trim() !== '' && isNaN(parseFloat(price))}
          helperText={price.trim() !== '' && isNaN(parseFloat(price)) ? "Invalid number" : ""}
        />
      </Grid>
      <Grid item xs={1}>
        <TextField
          label="Qty"
          value={qty}
          onChange={handleQtyChange}
          type="number"
          size="small"
          inputProps={{ min: 1 }}
        />
      </Grid>
      <Grid item xl={12} alignItems="center">
        <Button variant="contained" className="tp-custom-button-primary" onClick={handleAddToCart} disabled={isAddButtonDisabled}>
          Add Part
        </Button>
      </Grid>
      <br />
      <br />
      <br />
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 2 }}>
        <Typography variant="body1" style={{ marginRight: '16px' }}>
          Depreciation (%):
        </Typography>
        <Select
          value={globalDepreciationPercentage}
          onChange={(e) => handleGlobalDepreciationChange(Number(e.target.value))}
          size="small"
        >
          {[...Array(21)].map((_, i) => (
            <MenuItem key={i} value={i * 5}>
              {i * 5}%
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Part Name</TableCell>
              <TableCell align="left">Price</TableCell>
              <TableCell align="left">Qty</TableCell>
              <TableCell align="right">Apply Depreciation</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="right">Depreciated Amount</TableCell> {/* New column for depreciated amount */}
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cart.map((item, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {item.name}
                </TableCell>
                <TableCell align="left">{formatNumber(item.price)}</TableCell>
                <TableCell align="left">{item.qty}</TableCell>
                <TableCell align="right">

                  <Checkbox
                    checked={Number(item.status) === 1}
                    onChange={(e) => handleApplyDepreciationChange(index, e.target.checked)}
                  />
                </TableCell>
                <TableCell align="right">{formatNumber(item.amount)}</TableCell>
                <TableCell align="right">{formatNumber(item.depreciatedAmount || 0)}</TableCell> {/* Display depreciated amount */}
                <TableCell align="right">
                  <Button onClick={() => handleDeleteFromCart(index)}>Delete</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <br />
      <br />
      <Grid item xs={12}>
        <Typography variant="body1" className="tp-text-bold">
          Total Parts Amount: ₱ {calculateTotal()}
        </Typography>
        <Typography variant="body1" className="tp-text-bold">
          Total Depreciation: ₱ {formatNumber(totalDepreciation)}
        </Typography>
        <br />
        <br />
      </Grid>
    </Grid>
  );
};

export default ListOfParts;
