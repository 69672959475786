import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
//import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
// import InnerImageZoom from 'react-inner-image-zoom';
// import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import { formatDate } from '../../api/FormatDate';
import NoImageFound from '../../assets/noFoundImage.json';
import DocumentViewer from './DocumentViewer';

// Ace Balce
type OptionType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;

//#region Other Methods
const optionToDocumentType: { [key in OptionType]: string } = {
  0: 'Official Receipt',
  1: 'Certificate of Registration',
  2: 'Driver License',
  3: 'Motor Vehicle',
  4: 'Police Report',
  5: 'Shop Estimate',
  6: 'Affidavit',
  7: 'Other Documents',
};

interface DocumentSummaryProps {
  data: any;
  attachments: any[];
  option: OptionType;
  docId: number;
}

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const DocumentSummary: React.FC<DocumentSummaryProps> = ({
  data,
  attachments,
  option,
  docId,
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  useEffect(() => {
    const documentType = optionToDocumentType[option];
    const initialIndex = attachments.findIndex(
      (attachment: any) => attachment.documentType === documentType
    );
    setSelectedIndex(initialIndex !== -1 ? initialIndex : null);
  }, [option, attachments]);

  const handleChange = (index: number) => {
    setSelectedIndex(selectedIndex === index ? null : index);
  };

  // const getFileNameFromUrl = (url: string | null | undefined): string => {
  //   if (!url) {
  //     return '';
  //   }
  //   const parts = url.split('/');
  //   return parts[parts.length - 1];
  // };

  const getFileNameFromUrl = (url: string | null | undefined): string => {
    if (!url) {
      return '';
    }
    const parts = url.split('/');
    const fileName = parts[parts.length - 1];
    
    if (fileName.includes('BPI MS')) {
      return 'Motor Vehicle Form';
    }
    
    return fileName;
  };
  

  const documentType = optionToDocumentType[option];
  const filteredAttachments = attachments?.filter(
    (attachment: any) => attachment?.documentType === documentType && attachment?.docId === docId
  );

  return (
    <div>
      {filteredAttachments && filteredAttachments.length > 0 ? (
        filteredAttachments.map((res: any, index: number) => (
          <Accordion
            key={index}
            expanded={selectedIndex === index}
            onChange={() => handleChange(index)}
            defaultExpanded={selectedIndex === index}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={'panel' + res.id + 'a-content'}
              id={'panel' + res.id + 'a-header'}
              className={
                res.status === 'Missing'
                  ? 'tp-bg-disable'
                  : 'tp-bg-lightPrimary'
              }
            >
              <Typography className="tp-text-bold">{res.documentType}</Typography>
            </AccordionSummary>
            {res.status === 'Missing' ? null : (
              <AccordionDetails>
                <Typography variant="body1" className="tp-mt-15px">
                  Filename: &ensp;
                  <span>
                    <a
                      href={res.fileUrl}
                      target="_blank"
                      // style={{ color: "#a42a25" }} 
                      rel="noopener noreferrer"
                    >
                      {getFileNameFromUrl(res.fileUrl)}
                    </a>
                  </span>
                </Typography>
                <Typography variant="body1" className="tp-mb-15px">
                  Date Received: &ensp;
                  <span className="tp-text-primary">
                    {formatDate(res.dateReceived)}
                  </span>
                </Typography>
                <hr />
                <DocumentViewer documentUrl={res.fileUrl} />
              </AccordionDetails>
            )}
          </Accordion>
        ))
      ) : (
        <>
          <img src={NoImageFound.image} width="100%" height="75%" alt="img" />
          <br />
          <Typography variant="h6" textAlign="center">
            No Available Document
          </Typography>
        </>
      )}
    </div>
  );
};


export default DocumentSummary;