import React from 'react';
import { Grid, Typography, Box } from '@mui/material';

// Include default values in the checklistItems array
const checklistItems = [
  { description: 'Late Accident Reporting (> 30 calendar days)', defaultChecked: true },
  { description: 'Policy Inception (Policy Start Date) >= DOL for 30 days', defaultChecked: false },
  { description: 'Date Of Loss <= Policy Expiry for 30 days', defaultChecked: false },
  { description: 'Date of Loss Before Policy Issued Date', defaultChecked: false },
  { description: 'Time of Loss = Wee Hours (11pm to 4am)', defaultChecked: true },
  { description: 'Driver License Expiry Date < Date Of Loss', defaultChecked: true },
  { description: 'Driver License Issued Date >= Date Of Loss', defaultChecked: true },
  { description: 'Driving Restriction Code Checking', defaultChecked: true },
  { description: 'Previous Claims History', defaultChecked: false },
];

const Checklist: React.FC = () => {
  // Initialize the state with the default values from checklistItems
  const [checkedItems, setCheckedItems] = React.useState<{ [key: string]: boolean }>(
    checklistItems.reduce((acc, item) => {
      acc[item.description] = item.defaultChecked;
      return acc;
    }, {} as { [key: string]: boolean })
  );

  // Calculate the totals for "Yes" and "No"
  const totalYes = Object.values(checkedItems).filter(value => value).length;
  const totalNo = Object.values(checkedItems).length - totalYes;

  return (
    <div className="tp-mb-20px">
      <Grid container spacing={2} className="tp-mtb-10px">
        <Grid item md={10}>
          <Typography variant="h6" className="tp-text-header-primary">
            Auto Checklist
          </Typography>
        </Grid>
        <Grid item md={2} textAlign="center">
          <Typography variant="h6" className="tp-text-header-primary">
            No / Yes
          </Typography>
        </Grid>
      </Grid>
      <hr className="tp-border-hr_primary tp-mb-20px" />
      {checklistItems.map((item, index) => (
        <Box
          key={item.description}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          my={0.5}
          p={0.5}
          bgcolor={index % 2 === 0 ? 'lightblue' : 'transparent'}
        >
          <Typography variant="body1">{item.description}</Typography>
          <Typography variant="body1" fontWeight="bold" sx={{ mr: 10 }}>
            {checkedItems[item.description] ? 'Yes' : 'No'}
          </Typography>
        </Box>
      ))}

      {/* Display the totals */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        my={2}
        p={1}
        bgcolor="lightgray"
      >
        <Typography variant="h6">Total:</Typography>
        <Typography variant="h6">No - {totalNo} / Yes - {totalYes}</Typography>
      </Box>
    </div>
  );
};

export default Checklist;
