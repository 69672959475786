import React, { useState, ChangeEvent, useEffect } from 'react';
import { TextField, Autocomplete, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid } from '@mui/material';
import vehiclePartsDataJson from './vehiclePartsData.json';
import { postParts, deletePartsById, getPartsByTransId } from './ApiService'; // Import the API functions
import { useParams } from 'react-router-dom';


type VehiclePart = {
  VehicleBrand: string;
  VehicleModel: string;
  Parts: string;
  Price: string;
};

type CartItem = {
  id?: number; // Make id optional because it will be assigned by the backend
  deId?: string;
  transId?: string;
  name: string;
  amount: number;
  qty: number;
  price: number;
  saveDate?: string;
};

type ListOfPartsProps = {
  onTotalAmountChange: (total: string) => void;
  updateCart: (cart: CartItem[]) => void;
  initialBrand: string | null;
  initialModel: string | null;
};

const formatNumber = (value: number) => {
  return value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

const vehiclePartsData: VehiclePart[] = vehiclePartsDataJson as VehiclePart[];

const ListOfPartsLink: React.FC<ListOfPartsProps> = ({ onTotalAmountChange, updateCart, initialBrand, initialModel }) => {
  const [brand, setBrand] = useState<string | null>(initialBrand);
  const [model, setModel] = useState<string | null>(initialModel);
  const [part, setPart] = useState<string>('');
  const [price, setPrice] = useState<string>('');
  const { transId } = useParams<{ transId: string }>();
  const [qty, setQty] = useState<number>(1);
  const [cart, setCart] = useState<CartItem[]>([]);
  const [filteredModels, setFilteredModels] = useState<string[]>([]);
  const [filteredParts, setFilteredParts] = useState<string[]>([]);


  useEffect(() => {
    const fetchParts = async () => {
      try {
        const response = await getPartsByTransId(Number(transId));
        const parts = Array.isArray(response.data) ? response.data : [];
        setCart(parts.map((part: any) => ({
          id: part.id,
          deId: part.deId,
          transId: part.transId,
          name: part.name,
          amount: parseFloat(part.amount), // Ensure amount is a number
          qty: part.qty,
          price: parseFloat(part.amount), // Ensure price is a number
        })));
      } catch (error) {
        console.error("Error fetching parts:", error);
      }
    };

    if (transId) {
      fetchParts();
    }
  }, [transId]); // Depend on refresh and contextRefresh

  useEffect(() => {
    const total = cart.reduce((acc, curr) => acc + curr.amount, 0).toFixed(2);
    onTotalAmountChange(total); // Call the passed function to update parent's state
    updateCart(cart);
  }, [cart, onTotalAmountChange, updateCart]); // Depend on cart

  useEffect(() => {
    if (brand) {
      const models = Array.from(new Set(vehiclePartsData
        .filter((item: VehiclePart) => item.VehicleBrand.toUpperCase() === brand.toUpperCase())
        .map((item: VehiclePart) => item.VehicleModel)));
      models.sort(); // Sort models alphabetically
      setFilteredModels(models);
    }
  }, [brand]);

  useEffect(() => {
    let parts: string[] = [];
    if (brand && model) {
      parts = Array.from(new Set(vehiclePartsData
        .filter(item => item.VehicleBrand.toUpperCase() === brand.toUpperCase() && item.VehicleModel === model)
        .map(item => item.Parts)));
    } else if (brand) {
      parts = Array.from(new Set(vehiclePartsData
        .filter(item => item.VehicleBrand.toUpperCase() === brand.toUpperCase())
        .map(item => item.Parts)));
    } else if (model) {
      parts = Array.from(new Set(vehiclePartsData
        .filter(item => item.VehicleModel === model)
        .map(item => item.Parts)));
    }
    parts.sort(); // Sort parts alphabetically
    setFilteredParts(parts);
  }, [brand, model]);

  useEffect(() => {
    if (part) {
      updatePriceForPart(part);
    }
  }, [part]);

  useEffect(() => {
    // Update the brand state when initialBrand changes
    if (initialBrand) {
      setBrand(initialBrand);
      setModel(null); // Set model to null when brand changes
    }
  }, [initialBrand]);

  const cleanPrice = (priceString: string) => {
    return priceString.replace(/[^\d.-]/g, '');
  };

  const updatePriceForPart = (inputPart: string) => {
    const item = vehiclePartsData.find(item => item.VehicleBrand.toUpperCase() === brand?.toUpperCase() && item.VehicleModel === model && item.Parts === inputPart);
    if (item) {
      setPrice(cleanPrice(item.Price));
    } else {
      setPrice('');
    }
  };

  const handleBrandChange = (_event: ChangeEvent<{}>, value: string | null) => {
    setBrand(value);
    setModel(null);
    setPart('');
    setPrice('');
  };

  const handleModelChange = (_event: ChangeEvent<{}>, value: string | null) => {
    setModel(value);
    setPart('');
    setPrice('');
  };

  const handlePartInputChange = (_event: any, value: string, reason: string) => {
    setPart(value);
  };

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrice(event.target.value);
  };

  const handleQtyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQty(parseInt(event.target.value));
  };

  const handleAddToCart = async () => {
    const amount = parseFloat(cleanPrice(price)) * qty;
    const currentDate = new Date();
    const timezoneOffset = currentDate.getTimezoneOffset() * 60000;
    const localDate = new Date(currentDate.getTime() - timezoneOffset);
    const saveDate = localDate.toISOString().slice(0, -1);

    const newCartItem: CartItem = {
      deId: transId,
      transId: transId,
      name: part,
      amount,
      qty,
      price: parseFloat(price),
      saveDate: saveDate // Add current DateTime
    };

    // Add part to the backend
    try {
      const response = await postParts(newCartItem);
      const savedPart = response.data;

      // Add the new part with its id to the cart
      setCart([...cart, { ...newCartItem, id: savedPart.id }]);
      setQty(1); // Reset qty to 1
    } catch (error) {
      console.error("Error adding part:", error);
    }
  };

  const handleDeleteFromCart = async (index: number) => {
    const itemToDelete = cart[index];

    // Check if item has an id before attempting to delete
    if (itemToDelete.id) {
      // Delete part from the backend
      try {
        await deletePartsById(itemToDelete.id);
        const newCart = [...cart];
        newCart.splice(index, 1);
        setCart(newCart);
      } catch (error) {
        console.error("Error deleting part:", error);
      }
    } else {
      console.error("Cannot delete item without an id");
    }
  };

  const handleEditCartItem = (index: number, field: string, value: string | number) => {
    const newCart = [...cart];
    const cartItem = newCart[index];

    if (field === 'qty') {
      cartItem.qty = Number(value);
    } else if (field === 'price') {
      cartItem.price = Number(value);
    }

    cartItem.amount = cartItem.qty * cartItem.price;
    newCart[index] = cartItem;
    setCart(newCart);
  };

  const calculateTotal = () => {
    return formatNumber(cart.reduce((acc, curr) => acc + curr.amount, 0));
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={3}>
        <Autocomplete
          options={Array.from(new Set(vehiclePartsData.map(item => item.VehicleBrand.toUpperCase()))).sort()}
          value={brand}
          onChange={handleBrandChange}
          isOptionEqualToValue={(option, value) => option.toUpperCase() === value?.toUpperCase()}
          renderInput={(params) => <TextField {...params} label="Vehicle Brand" />}
          size="small"
        />
      </Grid>
      <Grid item xs={3}>
        <Autocomplete
          options={filteredModels}
          value={model}
          onChange={handleModelChange}
          isOptionEqualToValue={(option, value) => option === value}
          renderInput={(params) => <TextField {...params} label="Vehicle Model" />}
          disabled={!brand}
          size="small"
        />
      </Grid>
      <Grid item xs={3}>
        <Autocomplete
          options={filteredParts}
          value={part}
          onInputChange={handlePartInputChange}
          isOptionEqualToValue={(option, value) => option === value}
          freeSolo
          renderInput={(params) => <TextField {...params} label="Parts" />}
          disabled={!brand || !model}
          size="small"
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label="Amount"
          value={price}
          onChange={handlePriceChange}
          type="text"
          size="small"
        />
      </Grid>
      <Grid item xs={1}>
        <TextField
          label="Qty"
          value={qty}
          onChange={handleQtyChange}
          type="number"
          size="small"
          inputProps={{ min: 1 }}
        />
      </Grid>
      <Grid item xl={12} alignItems="center">
        <Button variant="contained" onClick={handleAddToCart}>Add Part</Button>
      </Grid>
      <br />
      <br />
      <br />
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Part Name</TableCell>
              <TableCell align="left">Price</TableCell>
              <TableCell align="left">Qty</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cart.map((item, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">{item.name}</TableCell>
                <TableCell align="left">{formatNumber(item.price)}</TableCell>
                <TableCell align="left">{item.qty}</TableCell>
                <TableCell align="right">{formatNumber(item.amount)}</TableCell>
                
                <TableCell align="right">
                  <Button onClick={() => handleDeleteFromCart(index)}>Delete</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <br />
      <br />
      <Grid item xs={12}>
        <br />
        <br />
        <br />
        <Typography variant="body1" className="tp-text-bold">Total Parts Amount: ₱ {calculateTotal()}</Typography>
        <br />
        <br />
      </Grid>
    </Grid>
  );
};

export default ListOfPartsLink;
