import DETaskUploadClec from "../components/DETaskUploadClec";
import Container from '@mui/material/Container';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import {Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const DECreateTask = () => {
    const navigate = useNavigate();
    return (
        <Container maxWidth={'lg'} sx={{ mt: 12 }}>
             <IconButton onClick={() => navigate('/damage-evaluation')} disableRipple={true} aria-label="open" sx={{ padding: 0 }}>
                    <ArrowBackIcon sx={{ height: 28, width: 28 }} />
                    <Typography sx={{ ml: 1 }}>Back</Typography>
                </IconButton>
        <Typography variant='h4' sx={{ textAlign: 'center', flexGrow: 1 }}>Create Damage Evaluation Task</Typography>
        <DETaskUploadClec />
        </Container>
    );
}

export default DECreateTask;