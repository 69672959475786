export function formatDate(inputDate: string): string {
  const date = new Date(inputDate);

  // Ensure the input date is valid
  if (isNaN(date.getTime())) {
    return "";
  }

  // Format the date
  const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
    date
  );
  const day = date.getDate();
  const year = date.getFullYear();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const period = hour < 12 ? "" : "";
  const formattedDate = `${month} ${day}, ${year} | ${hour}:${minute
    .toString()
    .padStart(2, "0")} ${period}`;

  // Create a Date object from the input string
  // const date = new Date(dateString);

  // // Define the options for formatting the date
  // const options: Intl.DateTimeFormatOptions = {
  //   year: 'numeric',
  //   month: 'long',
  //   day: '2-digit',
  // };

  // // Format the date using Intl.DateTimeFormat
  // const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

  return formattedDate;
}
