import { 
    Container, 
    Typography, 
    Box, 
    TextField, 
    Button, 
    FormControl, 
    FormHelperText, 
    Alert, 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogActions, 
    IconButton, 
    InputAdornment 
} from "@mui/material";
import { useLocation, useNavigate, Link } from "react-router-dom"; // Import useHistory hook
import React, { useState } from "react";
import axios from "axios"; // Import Axios
import LogoImage from '../../assets/logo.json';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";



function useQuery() {
    return new URLSearchParams(useLocation().search);
}
export default function ResetPassword() {
    const query = useQuery();
    const token = query.get("token");
    const username = query.get("username");
    const navigate = useNavigate(); // Add useHistory hook
    const [formError, setFormError] = useState<any>({});
    const [mainState, setMainState] = useState<{ error: string; success: string }>({
        error: "",
        success: ""
    });

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleToggleConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
    const validateForm = (data: FormData) => {
        let isValid = true;
        const errors: any = {};
        const password = data.get('newPassword')?.toString().trim() ?? "";
        const confirmPassword = data.get('confirmPassword')?.toString().trim() ?? "";
        const validatePassword = (password: string) => {
            const regex = /^(?=.*[A-Z])(?=.*[!@#$%^&*_,])(?=.*[0-9]).{8,}$/;
            return regex.test(password);
        }
        
        if (password === "" || confirmPassword === "" || password !== confirmPassword || !validatePassword(password)) {
            errors.newPassword = `Password must be atleast 8 characraters long, contain at least 1 uppercase and lower case letter,
            contain at least 1 special character, at least 1 number and has no leading and trailing spaces. Both passwords must match.`;

            isValid = false;
        }

        if (password !== confirmPassword) {
            errors.confirmPassword = 'Confirm Password and Password should be the same.';
            isValid = false;
        }
    
        setFormError(errors);
        return isValid;
    };

    const formatErrorMessage = (error: string) => {
        return error.split('\n').map((line, index) => (
            <div key={index} className='mt-4'>
                {line}
                <br/>
            </div>
        ));
    };
    

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (validateForm(data) && username !== null && token !== null) {
            try {
                await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/reset-password?username=${username}&token=${token}`, {
                    password: data.get('newPassword'),            
                });
                // Handle success response
                setMainState({ ...mainState, success: "Password has been reset successfully.", error: "" });
                // Optionally redirect the user or clear form fields here
            } catch (error: any) {
                console.error('Failed to reset password', error?.response?.data);
                setMainState({ ...mainState, error: error?.response?.data, success: "" });
            }
        } else {
            console.log('Form validation failed');
        }
    };

    const handleDialogClose = () => {
        navigate("/"); // Redirect to login page
    };

    return (
        <Container component="main" maxWidth="xs" sx={{ height: "100vh" }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: "center", height: "10%" }}>
                <img src={LogoImage.logo} width={180} height={60} alt='img' />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: "center", height: "68%" }}>
                {mainState.error && <Alert severity="error">{mainState.error}</Alert>}
                {mainState.success && (
                    <Dialog open={true} onClose={handleDialogClose}>
                        <DialogTitle>Password Reset</DialogTitle>
                        <DialogContent>
                            <Typography>{mainState.success}</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDialogClose}>OK</Button>
                        </DialogActions>
                    </Dialog>
                )}
                
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: "center"}}>
                    <Typography variant="h4" sx={{ mb: 2 }}>
                        Reset Password
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                        Password must contain at least eight characters, at least one number, and both lower and uppercase letters and special characters (@,.$!%*?&._)
                    </Typography>
                </Box>
                <Box component="form" onSubmit={handleSubmit}>
                    <FormControl fullWidth>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="newPassword"
                            label="New Password"
                            type={showPassword ? "text" : "password"}
                            name="newPassword"
                            autoFocus
                            error={!!formError.newPassword}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="confirmPassword"
                        label="Confirm Password"
                        type={showConfirmPassword ? "text" : "password"}
                        id="confirmPassword"
                        error={!!formError.confirmPassword}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle confirm password visibility"
                                        onClick={handleToggleConfirmPassword}
                                    >
                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    </FormControl>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 5 }}
                    >
                        Submit
                    </Button>
                    <Link to="/" style={{ textDecoration: 'none', marginTop:'10px' }}>
                        <Button fullWidth>
                            Go to ACAP/ISIP Page
                        </Button>
                    </Link>
                    {!!formError?.newPassword && (
                        <FormHelperText sx={{marginTop:"25px"}} error>{formError?.newPassword}</FormHelperText>
                    )}
                </Box>
            </Box>
        </Container>
    );
}




