import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

export default function PolicyAsiaPaymentTable({ rows }: any) {
  return (
    <Box
      sx={{
        minWidth: 100,
        maxheight: 200,
        overflow: "auto",
        border: "1px solid #ccc",
        borderRadius: "4px",
      }}
    >
      <TableContainer component={Paper}>
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{ minWidth: 100, maxheight: 200 }}
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell align="right">OR No</TableCell>
              <TableCell align="right">Date</TableCell>
              <TableCell align="right">Effective Date</TableCell>
              <TableCell align="right">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 && rows[1]?.orno ? (
              rows.map((row: any, index: number) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="right">{row.orno}</TableCell>
                  <TableCell align="right">{row.trandate}</TableCell>
                  <TableCell align="right">{row.effectivedate}</TableCell>
                  <TableCell align="right">{row.amount}</TableCell>
                </TableRow>
              ))
            ) : (
                <TableRow>
                <TableCell align="center" colSpan={12}>
                  No Data Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
