import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useGlobalData } from "../contexts/GlobalDataContext";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import bpims from "../assets/bpims_logo.png";
import dayjs from 'dayjs';
import { getUser } from "../utils/GetCurrentUser";
import { UpdateUserApi } from "../api/UpdateUserApi";


export default function LoginPage() {
  const defaultTheme = createTheme();
  const { globalData, updateGlobalData } = useGlobalData();
  const navigate = useNavigate();
  const [mainState, setMainState] = useState({
    apiError: "",
    formError: "",
    isLoading: false,
  })

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };


  
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    const dateTimeString = dayjs().format('YYYY-MM-DD HH:mm:ss');
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const usernameValue = data.get("username") as string;
    const passwordValue = data.get("password") as string;

    // Clear previous errors
    setMainState((prevState) => ({
      ...prevState,
      formError: "",
      apiError: "",
      isLoading: true,
    }));

    if (!usernameValue || !passwordValue) {
      setMainState((prevState) => ({
        ...prevState,
        formError: "Username and password should not be empty",
        isLoading: false,
      }));
      return;
    }

    setMainState((prevState) => ({ ...prevState, isLoading: true }));
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/authentication/login`,
        {
          username: usernameValue,
          password: passwordValue,
        }
      )
      switch (res?.status === 200) {
        case res?.data?.data?.challengeName === undefined:
          const { idToken, accessToken, refreshToken } = res?.data?.data;
          const arrayToken = idToken?.split('.');
          const userData = JSON.parse(atob(arrayToken[1]));
          const role = userData['custom:role_name'];
          const username = userData['cognito:username'];
          const lastLogout = userData['custom:last_logout'];
          const status = userData['custom:status'];
          const tokenExpiration = userData['exp'];
          const userToUpdate = await getUser(username, globalData);
        
          updateGlobalData({ 
            firstName: res?.data?.givenName,
            lastName: res?.data?.familyName,
            role: role, 
            username: username, 
            idToken: idToken, 
            isUserLogOut: false,
            accessToken: accessToken,
            lastLogin: dateTimeString,
            lastLogout: lastLogout,
            status: status,
            tokenExpiration: tokenExpiration
          });
          
          secureLocalStorage.setItem('firstName', JSON.stringify(res?.data?.givenName));
          secureLocalStorage.setItem('lastName', JSON.stringify(res?.data?.familyName));
          secureLocalStorage.setItem('accessToken', JSON.stringify(accessToken));
          secureLocalStorage.setItem('refreshToken', JSON.stringify(refreshToken));
          secureLocalStorage.setItem('idToken', JSON.stringify(idToken));
          secureLocalStorage.setItem('username', JSON.stringify(username));
          secureLocalStorage.setItem('role', JSON.stringify((role)));
          secureLocalStorage.setItem('lastLogin', JSON.stringify((dateTimeString)));
          secureLocalStorage.setItem('status', JSON.stringify((status)));
          secureLocalStorage.setItem('lastLogout', JSON.stringify((lastLogout)));
          secureLocalStorage.setItem('tokenExpiration', JSON.stringify((tokenExpiration)));

          await UpdateUserApi(userToUpdate, globalData, username, accessToken);
          navigate('/');
          break;
        case res?.data?.data?.challengeName === 'SOFTWARE_TOKEN_MFA':
          secureLocalStorage.setItem('challengeName', JSON.stringify(res?.data?.data?.challengeName));
          secureLocalStorage.setItem('session',  JSON.stringify(res?.data?.data?.session));
          secureLocalStorage.setItem('username', JSON.stringify(usernameValue));

          navigate('/validate-mfa');
          break;
        case res?.data?.data?.challengeName === 'MFA_SETUP':
          secureLocalStorage.setItem('secretKey', JSON.stringify(res?.data?.data?.secretKey));
          secureLocalStorage.setItem('challengeName', JSON.stringify(res?.data?.data?.challengeName));
          secureLocalStorage.setItem('session',  JSON.stringify(res?.data?.data?.session));
          secureLocalStorage.setItem('username', JSON.stringify(usernameValue));
          
          navigate('/setup-mfa');
          break;
        default:
          secureLocalStorage.setItem('username', JSON.stringify(usernameValue));
          secureLocalStorage.setItem('session',  JSON.stringify(res?.data?.data?.session));
          navigate('/change-password');
          break;
      }
    } catch (error: any) {
        setMainState((prevState) => ({
          ...prevState,
          apiError: error?.response?.data || error?.message,
          isLoading: false,
        }));
    } finally {
      setMainState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            height: "100vh",
          }}
        >
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box>
              <img src={bpims} width={180} height={60} alt="img" />
            </Box>
            {mainState.formError && (
              <Alert severity="error" sx={{ width: "100%" }}>
                {mainState.formError}
              </Alert>
            )}
            {mainState.apiError && (
              <Alert severity="error" sx={{ width: "100%" }}>
                {mainState.apiError}
              </Alert>
            )}
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
              />
              <FormControl sx={{ my: 2 }} fullWidth variant="outlined">
                <InputLabel htmlFor="password">Password</InputLabel>
                <OutlinedInput
                  required
                  fullWidth
                  name="password"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
              <Button
                className="tp-custom-button-primary"
                type="submit"
                fullWidth
                disabled={mainState?.isLoading === true}
                variant="contained"
              >
                {mainState?.isLoading === true ? (
                  <>
                    <CircularProgress color="inherit" size="1.5em" />
                    &emsp;
                  </>
                ) : (
                  <></>
                )}
                Sign In
              </Button>
              <Grid container sx={{ mt: 1 }}>
                <Grid item xs>
                  <Link href="forgot-password" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
}
