import * as React from "react";
import {
    Container,
    Card,
    CardContent,
    Grid,
    Typography,
    IconButton,
    Box,
    Button,
    TextField,
    FormControl
} from '@mui/material';
import Select, { ActionMeta, SingleValue } from 'react-select';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EstimationTable from './damage_evaluation/EstimationTable';
import ClientTransactionLink from './damage_evaluation/ClientTransactionLink';
import ForProcessor from './damage_evaluation/ForProcessor';
import NoImageFound from '../assets/noFoundImage.json';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { formatDate } from '../api/FormatDate';
import axios from 'axios';
import { GlobalDataContext } from '../contexts/GlobalDataContext';
import FieldChecklistClec from './processor_validation/FieldChecklistClec';
import GenerateInsurer from './GenerateInsurer';

import ComputationSheet from "./ComputationSheet";
import { format } from "path";

// Utility function
function toTitleCase(str: string | undefined | null): string | undefined {
  if (!str) {
    return str as undefined;
  }

  return str.replace(/\w\S*/g, (word) => {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
  });
}

export default function DamageEvaluationLink({
  totalRepairersAmount,
  setTotalRepairersAmount,
  totalLaborAmount,
  setTotalLaborAmount,
  selectedBrand,
  selectedModel,
  onBrandModelUpdate,
}: {
  totalRepairersAmount: number;
  setTotalRepairersAmount: React.Dispatch<React.SetStateAction<number>>;
  totalLaborAmount: number;
  selectedBrand: string | null;
  selectedModel: string | null;
  onBrandModelUpdate: (brand: string | null, model: string | null) => void;
  setTotalLaborAmount: React.Dispatch<React.SetStateAction<number>>;
}) {
  const handleNumberChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setter: React.Dispatch<React.SetStateAction<number>>
  ) => {
    const value = event.target.value;
    const parsedValue = parseFloat(value.replace(/,/g, ""));

    if (!isNaN(parsedValue)) {
      setter(parsedValue);
    }
  };
  type OptionType = { value: number; label: string };
  type CartItem = {
    id?: number; // Make id optional because it will be assigned by the backend
    deId?: string;
    transId?: string;
    name: string;
    amount: number;
    qty: number;
    price: number;
    saveDate?: string;
  };
  const [ourOfferGrossTotal, setOurOfferGrossTotal] = React.useState<number>(0);
  const [totalDeductibleAmount, setTotalDeductibleAmount] =
    React.useState<number>(0);
  const [totalAmount, setTotalAmount] = React.useState<number>(0);
  const [laborPlusVat, setLaborPlusVat] = React.useState<number>(0);
  const [cart, setCart] = React.useState<CartItem[]>([]);
  const laborAndMaterials = laborPlusVat;
  const regularParts = totalAmount;
  const [lossReserveParts, setLossReserveParts] = React.useState<number>(0);
  const [lossReserveLabor, setLossReserveLabor] = React.useState<number>(0);
  const location = useLocation();
  const { claimNo } = location.state || {};
  const [totalDepreciation, setTotalDepreciation] = React.useState(0);
  const handleTotalDepreciationChange = (newTotalDepreciation: number) => {
    setTotalDepreciation(newTotalDepreciation);
  };

  const formatNumber = (value: number) => {
    return value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const parseNumber = (value: string) => {
    const parsedValue = parseFloat(value.replace(/,/g, ""));
    return isNaN(parsedValue) ? 0 : parsedValue;
  };

  const [initialTotalParts, setTotalParts] = React.useState<number>(0);
  const [initialTotalLabor, setTotalLabor] = React.useState<number>(0);

  const handleUpdate = (parts: number, labor: number) => {
    setTotalParts(parts);
    setTotalLabor(labor / 1.12);
  };

  const updateTotalDeductible = (amount: number, totalNumber: number) => {
    const totalDeductible = totalNumber * 1;
    setTotalDeductibleAmount(totalDeductible);
  };

  const handleUpdateCart = (newCart: CartItem[]) => {
    setCart(newCart);
  };
  const updateGrossTotal = (amount: number, laborVat: number) => {
    setTotalAmount(amount);
    setLaborPlusVat(laborVat);
    const grossTotal = amount + laborVat;
    setOurOfferGrossTotal(grossTotal);
  };

  // console.log('DE cart', cart);

  const optionsLoss: OptionType[] = [
    { value: 1, label: "Pending for Offer" },
    { value: 2, label: "Third Party Damage" },
    { value: 3, label: "Theft" },
  ];

  const [mainState, setMainState] = React.useState<any>({
    data: {},
    // selections: {
    //     status: optionsLoss[0], // Set the default value to the first option
    // },
  });
  const [userData, setUserData] = React.useState<any>({});
  const [isDisable, setIsDisable] = React.useState<boolean>(false);
  const { transId } = useParams();
  const navigate = useNavigate();
  const { globalData, setGlobalData } = React.useContext(GlobalDataContext);

  const handleSelectChange = (
    selection: SingleValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => {
    console.log(selection);
    setMainState({
      ...mainState,
      data: { ...mainState.data, status: selection?.value },
      selections: { status: selection },
    });
  };

  const fetchData = async () => {
    try {
      setMainState({ ...mainState, isLoading: true });
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/damage-details?transId=${transId}`
      );
      const data = res.data.data;
      const statusOptions: any = {
        0: null,
        1: { value: 1, label: "Approved" },
        2: { value: 2, label: "Reject" },
      };
      setMainState({
        ...mainState,
        data: data,
        selections: { status: statusOptions[data?.status] || optionsLoss[0] }, // Use the fetched status or the first option as default
        isLoading: false,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateData = async () => {
    try {
      setMainState({ ...mainState, isLoading: true });
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/damage-details?transId=${transId}`,
        mainState.data
      );
      const data = res.data;
      const statusOptions: any = {
        0: null,
        1: { value: 1, label: "Approved" },
        2: { value: 2, label: "Reject" },
      };
      setMainState({
        ...mainState,
        data: data,
        selections: { status: statusOptions[data?.status] || optionsLoss[0] },
        isLoading: false,
      });
      navigate(-1);
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  // const apiSubmitStatus = async (endpoint: string) => {
  //     try {
  //         await axios.put(`${process.env.REACT_APP_BACKEND_URL}${endpoint}`);
  //     } catch (err: any) {
  //         console.error(err?.response?.data);
  //     }
  // };

  const apiSubmitEmail = async (endpoint: string) => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}${endpoint}`);
    } catch (err: any) {
      console.error(err?.response?.data);
    }
  };

  // const apiCheckStatus = async () => {
  //     try {
  //         const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/acap/DamageEvaluation/${transId}`);
  //         const responseStatus = res?.data?.status || null;
  //         if (responseStatus === 1) setIsDisable(true);
  //     } catch (err: any) {
  //         console.error(err?.response?.data);
  //     }
  // };

  const handleSubmit = () => {
    // updateData();
    // apiSubmitStatus(`/api/acap/document-validation/document-tracker/document?transId=${transId}&status=Completed`);
    // apiSubmitStatus(`/api/acap/DamageEvaluation/${transId}?status=1`);
    navigate("/damage-evaluation");
    // window.location.reload();
    apiSubmitEmail(
      `/api/acap/damage-evaluation/task/approve?transId=${transId}`
    );
  };

  React.useEffect(() => {
    //fetchData();
    // apiCheckStatus();
    const userData = JSON.parse(localStorage.getItem("user") as string);
    setUserData(userData);
  }, []);

  return (
    <Container maxWidth={"xl"} sx={{ my: 12 }}>
      {/* <IconButton onClick={() => navigate(-1)} disableRipple={true} aria-label="open" sx={{ my: 2, padding: 0 }}>
                <ArrowBackIcon sx={{ height: 28, width: 28 }} />
                <Typography sx={{ ml: 1 }}>Back</Typography>
            </IconButton> */}
      <Card sx={{ my: 4 }}>
        <CardContent className="tp-mlr-50px tp-mtb-20px">
          <Grid container spacing={2}>
            <Grid item xs={9} sm={9} md={9}>
              <Typography variant="h4" className="tp-text-header-primary">
                Damage Evaluation
              </Typography>
              <Typography
                variant="body1"
                display="block"
                className="tp-text-input-sub"
              >
                Note: Please check all Required Fields with (
                <span className="tp-text-required">*</span>) before you submit.
              </Typography>
            </Grid>
          </Grid>
          <hr />
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item>
              <br />
              <Typography variant="h5" className="tp-text-header-primary">
                Type of Loss:{" "}
                <span className="tp-text-default">Own Damage</span>
              </Typography>
            </Grid>
            <Grid item>
              <br />
              <Typography variant="h5" className="tp-text-header-primary">
                Claim No: <span className="tp-text-default">{claimNo}</span>
              </Typography>
            </Grid>
          </Grid>
          <hr className="tp-border-hr_primary" />
          <br />

          <Grid container spacing={2}>
            <Grid item xs={8} sm={8} md={8}>
              <Typography variant="h5" className="tp-text-header-primary">
                <span className="tp-text-default">Transaction No.: &ensp;</span>
                ACAP - {transId}
              </Typography>
              <Typography variant="body1" className="tp-text-header-primary">
                <span className="tp-text-default">Date Received: &ensp;</span>
                2024-07-16
                {/* {formatDate(mainState?.data?.saveDate)? formatDate(mainState?.data?.saveDate): "2021-09-01"} */}
              </Typography>
              <br />
              <br />
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Typography variant="body1" className="tp-text-bold">
                    Insured:
                  </Typography>
                </Grid>
                <Grid item md={6} textAlign="right">
                  <Typography variant="body1" className="tp-text-primary">
                    CHRISTIAN VARONA
                  </Typography>
                </Grid>
              </Grid>
              <hr />
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Typography variant="body1" className="tp-text-bold">
                    Policy No.:
                  </Typography>
                </Grid>
                <Grid item md={6} textAlign="right">
                  <Typography variant="body1" className="tp-text-primary">
                    {mainState?.data?.policyNo
                      ? mainState?.data?.policyNo
                      : "PH611332"}
                  </Typography>
                </Grid>
              </Grid>
              <hr />

              <br />
              <Typography variant="h6" className="tp-text-header-primary">
                List of Items
                <Typography
                  variant="caption"
                  display="block"
                  className="tp-text-subtitle"
                >
                  Note: These are the available data in OCR Process.
                </Typography>
              </Typography>

              {/* Estimation Table */}
              <EstimationTable transID={transId} />
              <TextField
                label="Total Parts Amount"
                variant="outlined"
                type="text"
                value={formatNumber(totalRepairersAmount)}
                onChange={(e) => handleNumberChange(e, setTotalRepairersAmount)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Total Labor Amount"
                variant="outlined"
                type="text"
                value={formatNumber(totalLaborAmount)}
                onChange={(e) => handleNumberChange(e, setTotalLaborAmount)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <br />
            <Grid item xs={4} sm={4} md={4}>
              {mainState?.data?.esfGdriveField ? (
                <>
                  <Typography variant="h6" className="tp-text-header-primary">
                    Document Summary
                  </Typography>
                  <Typography variant="body1" className="tp-mt-15px">
                    Filename: &ensp;
                    <span className="tp-text-primary">Estimation Form</span>
                  </Typography>
                  <Typography variant="body1" className="tp-mb-15px">
                    Date Received: &ensp;
                    <span className="tp-text-primary">
                      {formatDate(mainState?.data?.saveDate)}
                    </span>
                  </Typography>
                  <hr />
                  {mainState?.data?.esfGdriveFileType === "pdf" ? (
                    <iframe
                      width={"100%"}
                      height={"600"}
                      src={`https://drive.google.com/file/d/116nG1_HtgAqkIUDV0dUxIqCOEJk8kFdU/view?usp=drive_link`}
                      title="pdf"
                    ></iframe>
                  ) : (
                    <iframe
                      width={"100%"}
                      height={"600"}
                      src={`https://drive.google.com/file/d/116nG1_HtgAqkIUDV0dUxIqCOEJk8kFdU/view?usp=drive_link`}
                      title="jpg"
                    ></iframe>
                  )}
                </>
              ) : (
                <>
                  {/* <img src={NoImageFound.image} width="100%" height="75%" alt="img" />
                                    <br />
                                    <Typography variant="h6" textAlign="center">
                                        No Available Document
                                    </Typography> */}
                  <Typography variant="h6" className="tp-text-header-primary">
                    Document Summary
                  </Typography>
                  <Typography variant="body1" className="tp-mt-15px">
                    Filename: &ensp;
                    <span className="tp-text-primary">Estimation Form</span>
                  </Typography>
                  <Typography variant="body1" className="tp-mb-15px">
                    Date Received: &ensp;
                    <span className="tp-text-primary">
                      {/* {formatDate(mainState?.data?.saveDate)} */}
                      2024-07-16
                    </span>
                  </Typography>
                  <hr />
                  <iframe
                    width={"100%"}
                    height={"600"}
                    src={`https://drive.google.com/file/d/116nG1_HtgAqkIUDV0dUxIqCOEJk8kFdU/preview`}
                    title="pdf"
                  ></iframe>
                </>
              )}
            </Grid>
          </Grid>
          <hr className="tp-border-hr_primary" />
          <br />

          {/* Client Transaction */}
          <ClientTransactionLink
            transID={transId}
            data={mainState?.data}
            lossReserveParts={lossReserveParts}
            setLossReserveParts={setLossReserveParts}
            lossReserveLabor={lossReserveLabor}
            setLossReserveLabor={setLossReserveLabor}
            initialLaborAmount={initialTotalLabor}
            initialPartsAmount={initialTotalParts}
            updateGrossTotal={updateGrossTotal}
            updateTotalDeductible={updateTotalDeductible}
            updateCart={handleUpdateCart}
            totalRepairersAmount={totalRepairersAmount}
            totalLaborAmount={totalLaborAmount}
            setTotalLaborAmount={setTotalLaborAmount}
            selectedBrand={selectedBrand}
            selectedModel={selectedModel}
          />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                variant="h5"
                className="tp-text-header-primary"
                textAlign="center"
              >
                CLEC Reports
              </Typography>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={2} sm={2} md={2}>
              <Typography variant="h5" className="tp-text-header-primary">
                Insurer Report
              </Typography>
            </Grid>
            <Grid item xs={3} sm={3} md={3}>
              <GenerateInsurer
                repairersParts={totalRepairersAmount}
                repairersLabor={totalLaborAmount}
                laborPlusVat={laborPlusVat}
                totalAmount={totalAmount}
                totalDeductible={totalDeductibleAmount}
                ourOfferGrossTotal={ourOfferGrossTotal}
                partsCart={cart}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={2} sm={2} md={2}>
              <Typography variant="h5" className="tp-text-header-primary">
                Computation Sheet
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <ComputationSheet
                laborAndMaterials={laborAndMaterials + laborAndMaterials * 0.12}
                totalLabor={laborAndMaterials}
                regularParts={regularParts}
                depreciation={totalDepreciation}
                deductible={totalDeductibleAmount}
                totalLossReserve={lossReserveParts + lossReserveLabor}
              />
            </Grid>
          </Grid>
          <br />
          <br />
          {/* <Grid container spacing={2}>
                        <Grid item xs={2} sm={2} md={2}>
                            <Typography variant="h5" className="tp-text-header-primary">
                                Loa Generation
                            </Typography>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3}>
                            <Button color="info"
                                variant="contained">Generate LOA</Button>
                        </Grid>
                    </Grid> */}

          {/* For Processor */}

          <hr className="tp-border-hr_primary" />
          <br />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" className="tp-text-header-primary">
              Login As User: &ensp;
              <span style={{ color: "#525252" }}>
                {toTitleCase(globalData?.firstName)}{" "}
                {toTitleCase(globalData?.lastName)}
              </span>
            </Typography>
            <Button
              disabled={isDisable}
              color="primary"
              variant="contained"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
          <br />
        </CardContent>
      </Card>
    </Container>
  );
}
