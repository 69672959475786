import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import GlobalDataStore from "./contexts/GlobalDataContext";
import AuditTrailDataStore from "./contexts/AuditTrailContext";
import { BrowserRouter } from "react-router-dom";
import { pdfjs } from "react-pdf";
// import { ReactKeycloakProvider } from '@react-keycloak/web';
// import keycloak from './Keycloak'
pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.mjs`;
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    {/* <ReactKeycloakProvider authClient={keycloak}> */}
    <BrowserRouter>
      <GlobalDataStore>
        <AuditTrailDataStore>
          <App />
        </AuditTrailDataStore>
      </GlobalDataStore>
    </BrowserRouter>
    {/* </ReactKeycloakProvider> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
