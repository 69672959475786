export function removeTrailingCommas(jsonStr: string) {
  return jsonStr.replace(/,\s*([}\]])/g, "$1");
}

export function convertStringToNumber(
  input: string | null | undefined
): number {
  if (input == null || input.trim() === "") {
    return 0;
  }

  // Remove all non-numeric characters except the decimal point
  const cleanedString = input.replace(/[^\d.]/g, "");

  // Convert the cleaned string to a number
  const number = parseFloat(cleanedString);
  // If the conversion does not result in a valid number, return 0
  if (isNaN(number)) {
    return 0;
  }
  // Fix to 2 decimal points
  return parseFloat(number.toFixed(2));
}

export function formatNumber(num: number) {
  return num.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export function capitalizeAndReplaceUnderscore(str: string | null | undefined): string {
  if (str === null || str === undefined || str.trim() === "") return ""; // Handle null, undefined, or empty string

  return str
    .split("_") // Split the string by underscores
    .map(word => 
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // Capitalize the first letter of each word
    )
    .join(" "); // Join the words with a space
}