import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  Grid,
  Modal,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { AuditTrailDataContext } from "../../contexts/AuditTrailContext";
import { GlobalDataContext } from "../../contexts/GlobalDataContext";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  p: 4,
};

export default function TransferButton({
  docId,
  transId,
  endpoint,
  close,
  documentType
}: any) {
  const [docStatus, setDocStatus] = React.useState<number>(20);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openModal1, setOpenModal1] = React.useState(false);
  const [openModal2, setOpenModal2] = React.useState(false);
  const [openModal3, setOpenModal3] = React.useState(false);
  const [newTransId, setNewTransId] = React.useState<any>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [modal1Options, setModal1Options] = React.useState([]);
  const openMenu = Boolean(anchorEl);
  const { createAuditTrailData } =
    React.useContext(AuditTrailDataContext);
  const { globalData, setGlobalData } = React.useContext(GlobalDataContext);

  // fetch all acap ids by status
  const fetchAcapIdsByStatus = async () => {
    try {
      (async () => {
        axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/api/acap-task/ids`,
          // headers: {
          //   'Authorization': `Bearer ${globalData?.sessionToken}`
          // }
        })
          .then((res: any) => {
            const data = res.data.data;
            const tdata = data.map((el: any) => {
              return { label: `ACAP-${String(el)}`, value: el };
            });
            setModal1Options(tdata);
          })
          .catch((err: any) => {
            console.error(err?.response?.data);
            let errorMessage = "";
            if (err?.response?.status === 401)
              errorMessage = err?.response?.data;
            if (err?.response?.status === 400)
              errorMessage = err?.response?.data;
          });
      })();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  React.useEffect(() => {
    fetchAcapIdsByStatus();
  }, []);

  // Transfer to another transaction
  const handleInputChangeTransId = (e: any, v: string | null) => {
    const newValue = v ?? null;
    setNewTransId(newValue);
  };
  const transferToAnotherTransId = async () => {
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}${endpoint}?documentId=${docId}`,
      {
        method: "PATCH",
        body: JSON.stringify({ newTransId: newTransId?.value }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        createAuditTrailData({
          transId: transId,
          taskType: "document_validation",
          event: "transfer_document",
          description: `Transfer ${documentType} document with document id #${docId} from ACAP-${transId} to ACAP-${newTransId?.value}`,
          userId: globalData.id
        })

        setIsLoading(false);
        handleModal1Close();
        close();
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  const handleModal1Open = () => setOpenModal1(true);
  const handleTransferTransId = () => {
    console.log(`newTransId: ${newTransId?.value}`, `oldTrandsId: ${transId}`);
    setIsLoading(true);
    transferToAnotherTransId();
  };
  const handleModal1Close = () => {
    setNewTransId(null);
    setIsLoading(false);
    setOpenModal1(false);
  };

  // Transfer to archive
  const handleModal2Open = () => setOpenModal2(true);
  const handleModal2Close = () => {
    setIsLoading(false);
    setOpenModal2(false);
  };
  const transferForArchiving = async () => {
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}${endpoint}/archive?transId=${transId}&id=${docId}&status=${docStatus}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        createAuditTrailData({
          transId: transId,
          taskType: "document_validation",
          event: "archive_document",
          description: `Archive ${documentType} document with document id #${docId}`,
          userId: globalData.id
        })
        setIsLoading(false);
        handleModal2Close();
        close();
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  // Modals
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenModal1 = () => {
    handleModal1Open();
    handleMenuClose();
  };
  const handleOpenModal2 = () => {
    handleModal2Open();
    handleMenuClose();
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={openMenu ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? "true" : undefined}
        onClick={handleMenuClick}
        variant="contained"
        color="warning"
        size="medium"
      >
        Transfer
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {documentType !== "Service Invoice" && documentType !== "Sales Invoice" && documentType !== "Isip Other Documents" ? (
          <MenuItem onClick={handleOpenModal1}>Another Transaction</MenuItem>
        ) : (
          <></>
        )}
        
        <MenuItem onClick={handleOpenModal2}>Archive</MenuItem>
      </Menu>

      {/* Modal1 (Transfer to Another Trans ID) */}
      <Modal
        open={openModal1}
        // onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6" className="tp-text-header-primary">
            Transfer To Another Transaction
          </Typography>
          <hr />
          &ensp;
          <FormControl variant="outlined" fullWidth className="tp-mb-30px">
            {/* <Typography variant="body1" className="tp-text-bold">
              Transaction No.:
            </Typography> */}
            <Autocomplete
              disablePortal
              isOptionEqualToValue={(options, value) =>
                options.value === value.value
              }
              id="combo-box-demo"
              options={modal1Options}
              size="small"
              value={newTransId}
              onChange={handleInputChangeTransId}
              disabled={isLoading}
              renderInput={(params) => (
                <TextField {...params} label="Transaction ID" />
              )}
            />
            &ensp;
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Button
                  disabled={newTransId === null || isLoading}
                  onClick={() => {
                    handleTransferTransId();
                  }}
                  variant="contained"
                  size="medium"
                  color="error"
                  fullWidth
                >
                  {isLoading ? (
                    <>
                      <CircularProgress color="inherit" size="1.5em" />
                      &emsp;
                    </>
                  ) : (
                    <></>
                  )}
                  {isLoading ? "Loading..." : "Submit"}
                </Button>
              </Grid>
              <Grid item xs={6} md={6}>
                <Button
                  disabled={isLoading}
                  onClick={() => {
                    handleModal1Close();
                  }}
                  variant="contained"
                  size="medium"
                  color="warning"
                  fullWidth
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </FormControl>
        </Box>
      </Modal>

      {/* Modal2 (Archiving document) */}
      <Modal
        open={openModal2}
        // onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6" className="tp-text-header-primary">
            Transfer for Archive
          </Typography>
          <hr />
          &ensp;
          <FormControl variant="outlined" fullWidth className="tp-mb-30px">
            <Typography variant="body1">
            Are you sure you want to archive the document?
            </Typography>
            &ensp;
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Button
                  onClick={() => {
                    transferForArchiving();
                  }}
                  variant="contained"
                  size="medium"
                  color="error"
                  fullWidth
                >
                  {isLoading ? (
                    <>
                      <CircularProgress color="inherit" size="1.5em" />
                      &emsp;
                    </>
                  ) : (
                    <></>
                  )}
                  {isLoading ? "Loading..." : "Yes"}
                </Button>
              </Grid>
              <Grid item xs={6} md={6}>
                <Button
                  disabled={isLoading}
                  onClick={() => {
                    handleModal2Close();
                  }}
                  variant="contained"
                  size="medium"
                  color="warning"
                  fullWidth
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </FormControl>
        </Box>
      </Modal>
    </div>
  );
}
