import { createContext, useContext, useState, ReactNode, useCallback } from 'react';
import secureLocalStorage from "react-secure-storage";

export type GlobalData = {
    id: number | null,
    username: string | null,
    transId: string | null,
    firstName: string | null,
    lastName: string | null,
    role: string | null,
    token: string | null,
    idToken: string | null,
    accessToken: string | null,
    refreshToken: string | null,
    isUserLogOut: Boolean | null,
    decision: string | null,
    remarks: string | null,
    startTime: Date | (Date),
    lastLogin: string | null,
    lastLogout: string | null,
    status: string | null,
    tokenExpiration: string | null,
    // Added for submission function capability
    submitForm?: () => Promise<void>; // Assuming an async function without arguments
}

interface GlobalDataContextInterface {
    globalData: GlobalData,
    setGlobalData: React.Dispatch<React.SetStateAction<GlobalData>>,
    updateGlobalData: (data: Partial<GlobalData>) => void, // For updating specific parts of the global data
}

const initialState: GlobalDataContextInterface = {
    globalData: {
        id: secureLocalStorage.getItem('me') ? JSON.parse(secureLocalStorage.getItem('me') as string)['id'] : null,
        username: secureLocalStorage.getItem('me') ? JSON.parse(secureLocalStorage.getItem('me') as string)['username'] : null,
        firstName: secureLocalStorage.getItem('me') ? JSON.parse(secureLocalStorage.getItem('me') as string)['firstName'] : null,
        lastName: secureLocalStorage.getItem('me') ? JSON.parse(secureLocalStorage.getItem('me') as string)['lastName'] : null,
        role: secureLocalStorage.getItem('me') ? JSON.parse(secureLocalStorage.getItem('me') as string)['roleName'] : null,
        token: secureLocalStorage.getItem('me') ? JSON.parse(secureLocalStorage.getItem('me') as string)['token'] : null,
        idToken: secureLocalStorage.getItem('me') ? JSON.parse(secureLocalStorage.getItem('me') as string)['idToken'] : null,
        accessToken: secureLocalStorage.getItem('me') ? JSON.parse(secureLocalStorage.getItem('me') as string)['accessToken'] : null,
        refreshToken: secureLocalStorage.getItem('me') ? JSON.parse(secureLocalStorage.getItem('me') as string)['refreshToken'] : null,
        tokenExpiration: secureLocalStorage.getItem('me') ? JSON.parse(secureLocalStorage.getItem('me') as string)['tokenExpiration'] : null,
        lastLogin: null,
        lastLogout: null,
        status: null,
        isUserLogOut: false,
        decision: null,
        remarks: null,
        transId: null,
        startTime: new Date(),
        // No initial value for submitForm; it will be set later
    },
    setGlobalData: () => {},
    updateGlobalData: () => {},
}

export const GlobalDataContext = createContext<GlobalDataContextInterface>(initialState);
export const useGlobalData = () => useContext(GlobalDataContext);

type GlobalDataProviderProps = {
    children: ReactNode;
}

export default function GlobalDataStore({ children }: GlobalDataProviderProps) {
    const [globalData, setGlobalData] = useState<GlobalData>(initialState.globalData);

    // Function to update parts of the global data
    const updateGlobalData = useCallback((data: Partial<GlobalData>) => {
        setGlobalData(prev => ({ ...prev, ...data }));
    }, [setGlobalData]);

    // This is where you could define a submission function that does something,
    // for now, it's a placeholder function.
    // You should implement the actual submission logic inside your form component
    // and then use updateGlobalData to set this function.
 
    return (
    <GlobalDataContext.Provider value={{ globalData, setGlobalData, updateGlobalData }}>
        {children}
    </GlobalDataContext.Provider>
    );
}
