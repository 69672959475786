import axios from 'axios';

const apiClient = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/acap`, // Change this to your actual backend URL
    headers: {
        'Content-Type': 'application/json',
    },
});

export const postLabor = (labor: any) => {
    return apiClient.post(`/labor`, labor);
};

export const deleteLaborById = (id: number) => {
    return apiClient.delete(`/labor/${id}`);
};



export const getLaborsByTransId = async (transId: number) => {
    const response = await apiClient.get(`/labor?transId=${transId}`);
    return response.data;
};
