import React, { useState, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import {
  Grid,
  Typography,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import axios from 'axios';
import { useRefresh } from './RefreshContext';

interface UploadClecProps {
  onUpdate: (parts: number, labor: number) => void;
  transId: any;
  claimNo: any;
  onBrandModelUpdate: (brand: string | null, model: string | null) => void;
}

const UploadClec: React.FC<UploadClecProps> = ({ onUpdate, transId, onBrandModelUpdate, claimNo }) => {
  
  
  
  const [sheets, setSheets] = useState<any[]>([]);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [dataExists, setDataExists] = useState<boolean>(false);
  const [apiData, setApiData] = useState<any>(null);
  const [showUploadButton, setShowUploadButton] = useState<boolean>(true);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const postPartsDataRef = useRef(false);
  const { triggerRefresh } = useRefresh();

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls'],
    },
    onDrop: async (acceptedFiles) => {
      const file = acceptedFiles[0];
      const reader = new FileReader();
  
      reader.onload = async (e) => {
        const data = new Uint8Array(e?.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetsData = workbook.SheetNames.map((sheetName) => ({
          name: sheetName,
          data: XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 }),
        }));
        
        // Immediately set the sheets state
        setSheets(sheetsData);
    
        // Validate claim number before proceeding
        const claimDetails = sheetsData.find(sheet => sheet.name === 'Claim Details')?.data as any[] || [];
        const claimNoField = claimDetails[1]?.[11]; // Ensure you're accessing the correct index
        
        if (claimNo !== claimNoField) {
          setAlertMessage(`The claim number ${claimNoField} from the file does not match the claim number ${claimNo}`);
          setSheets([]);  // Clear the sheets if the validation fails
          return; // Stop further processing if the claim numbers do not match
        }
  
        setAlertMessage(null); // Clear the alert if the claim numbers match
    
        // If claim number matches, proceed with posting data
        try {
          await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/acap/clec_estimate`, {
            transId: transId,
            claimDetails: JSON.stringify(claimDetails),
            scopeOfWork: JSON.stringify(sheetsData.find(sheet => sheet.name === 'Scope of Work')?.data || []),
            parts: JSON.stringify(sheetsData.find(sheet => sheet.name === 'Parts')?.data || []),
            estimate: JSON.stringify(sheetsData.find(sheet => sheet.name === 'Estimate')?.data || []),
          });
    
          console.log('Data posted successfully');
          setShowUploadButton(false); // Hide upload button after successful upload
          setDataExists(true); // Indicate that data now exists
          triggerRefresh(); // Trigger refresh to update data
        } catch (error) {
          console.error('Error posting data:', error);
        }
      };
      
      reader.readAsArrayBuffer(file);
    },
  });
  

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/acap/clec_estimate/transId/${transId}`);
        if (response.data) {
          setDataExists(true); // Data exists, parse it
          setApiData(response.data); // Store the response data
          parseApiData(response.data); // Parse the response data
        } else {
          setDataExists(false); // No data found, allow upload
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setDataExists(false); // In case of an error, allow upload
      }
    };

    fetchData();
  }, [transId]);

  const parseApiData = (data: any) => {
    // Parsing claimDetails
    const claimDetails = JSON.parse(data.claimDetails);
    const unitField = claimDetails[1][3];


    if (unitField) {
      const unitParts = unitField.split(' ');
      const brand = unitParts[1] || '';
      const model = unitParts[2] || '';
      onBrandModelUpdate(brand, model);
    } else {
      onBrandModelUpdate(null, null);
    }

    // Parsing estimate
    const estimateData = JSON.parse(data.estimate);
    const totalParts = estimateData[1] ? estimateData[1][4] : 0;
    const totalLabor = estimateData[1] ? estimateData[1][3] : 0;
    onUpdate(totalParts, totalLabor);

    // You can parse other sections similarly if needed
  };

  useEffect(() => {
    if (sheets.length > 0 && !postPartsDataRef.current) {
      const firstSheet = sheets[0];
      const unitField = firstSheet.data[1][3]; // Assuming the unit field is in the first cell
      const claimNoField = firstSheet.data[1][11];

      if (claimNo !== claimNoField) {
        setAlertMessage(`The claim number ${claimNoField} from the file does not match the claim number ${claimNo}`);
        return; // Stop further processing if the claim numbers do not match
      } else {
        setAlertMessage(null); // Clear the alert if the claim numbers match
      }

      if (unitField) {
        const unitParts = unitField.split(' ');
        const brand = unitParts[1] || '';
        const model = unitParts[2] || '';
        onBrandModelUpdate(brand, model);
      } else {
        onBrandModelUpdate(null, null);
      }

      const estimateSheet = sheets.find(sheet => sheet.name === 'Estimate' || sheet.name === 'estimate');
      if (estimateSheet) {
        const totalParts = estimateSheet.data[1] ? estimateSheet.data[1][4] : 0;
        const totalLabor = estimateSheet.data[1] ? estimateSheet.data[1][3] : 0;
        onUpdate(totalParts, totalLabor);
      }

      const partsSheet = sheets.find(sheet => sheet.name === 'Parts' || sheet.name === 'parts');
      if (partsSheet) {
        postPartsData(partsSheet.data, transId);
        postPartsDataRef.current = true;
      }
    }
  }, [sheets, onUpdate, transId, onBrandModelUpdate, claimNo]);

  const postPartsData = async (data: any[], transId: number) => {
    const partsData = data.slice(1).map(row => {
      const part = row[0];
      const price = parseFloat(row[1]);
      if (part && !isNaN(price)) {
        const qty = 1;
        const amount = price * qty;
        const currentDate = new Date();
        const timezoneOffset = currentDate.getTimezoneOffset() * 60000;
        const localDate = new Date(currentDate.getTime() - timezoneOffset);
        const saveDate = localDate.toISOString().slice(0, -1);
  
        return {
          deId: transId,
          transId: transId,
          name: part,
          amount,
          qty,
          price,
          saveDate
        };
      }
      return null;
    }).filter(item => item !== null); // Filter out null values
  
    try {
      for (const part of partsData) {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/acap/parts`, part, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
      }
      console.log('Parts data posted successfully');
      triggerRefresh(); // Trigger refresh
    } catch (error) {
      console.error('Error posting parts data:', error);
    }
  };
  

  const handleDelete = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/acap/clec_estimate/transId/${transId}`);
      console.log('Estimate deleted successfully');
      setDataExists(false); // Reset data existence flag
      setApiData(null); // Clear API data
      setSheets([]); // Clear uploaded file data
      setShowUploadButton(true); // Show upload button again
      onUpdate(0, 0); // Reset total parts and labor amounts
      triggerRefresh(); // Trigger refresh
    } catch (error) {
      console.error('Error deleting estimate:', error);
    }
    setOpenDialog(false); // Close the dialog
  };

  const renderTypography = (sheet: any) => (
    <Box>
      {sheet.data[0].map((header: any, headerIndex: number) => (
        <Grid container key={headerIndex} spacing={2} alignItems="center">
          <Grid item xs={2}>
            <Typography variant="subtitle1" component="div">
              {header}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1" component="div">
              {sheet.data[1] ? sheet.data[1][headerIndex] : ''}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Box>
  );

  const renderTable = (sheet: any) => {
    const headers = sheet.data[0];
    const rows = sheet.data.slice(1).map((row: any[]) => {
      const filledRow = [...row];
      while (filledRow.length < headers.length) {
        filledRow.push(''); // Fill missing values with empty strings
      }
      return filledRow;
    });

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header: any, index: number) => (
                <TableCell key={index}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: any[], rowIndex: number) => (
              <TableRow key={rowIndex}>
                {row.map((cell: any, cellIndex: number) => (
                  <TableCell key={cellIndex}>
                    {cell !== undefined && cell !== null ? cell : ''}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderApiData = () => {
    if (!apiData) return null;

    const claimDetails = JSON.parse(apiData.claimDetails);
    const scopeOfWork = JSON.parse(apiData.scopeOfWork);
    const parts = JSON.parse(apiData.parts);
    const estimate = JSON.parse(apiData.estimate);

    const formattedSheets = [
      { name: 'Claim Details', data: claimDetails },
      { name: 'Scope of Work', data: scopeOfWork },
      { name: 'Parts', data: parts },
      { name: 'Estimate', data: estimate },
    ];

    return formattedSheets.map((sheet, sheetIndex) => (
      <Box key={sheetIndex} sx={{ marginTop: 2 }}>
        <Typography variant="h6">{sheet.name}</Typography>
        {sheet.name === 'Scope of Work' || sheet.name === 'Parts' ? renderTable(sheet) : renderTypography(sheet)}
      </Box>
    ));
  };

  return (
    <Box sx={{ padding: 2 }}>
      {alertMessage && <Alert severity="warning">{alertMessage}</Alert>}
      {!dataExists && showUploadButton && (
        <Paper variant="outlined" {...getRootProps()} sx={{ padding: 2, textAlign: 'center' }}>
          <input {...getInputProps()} />
          <Typography>Drag 'n' drop some files here, or click to select files</Typography>
        </Paper>
      )}
      {(dataExists || sheets.length > 0) && (
        <>
          {/* <Typography variant="h6">Data from API or Uploaded File</Typography> */}
          {apiData && renderApiData()}
          {sheets.length > 0 &&
            sheets.map((sheet, sheetIndex) => (
              <Box key={sheetIndex} sx={{ marginTop: 2 }}>
                <Typography variant="h6">{sheet.name}</Typography>
                {sheet.name === 'Scope of Work' || sheet.name === 'Parts' || sheet.name === 'scope of work' || sheet.name === 'parts'
                  ? renderTable(sheet)
                  : renderTypography(sheet)}
              </Box>
            ))}
          <Button variant="outlined" color="secondary" onClick={() => setOpenDialog(true)} sx={{ mt: 2 }}>
            Delete Estimate
          </Button>
        </>
      )}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this Initial Estimate?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary" variant="contained">
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UploadClec;
