import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, MenuItem, Select, Typography, SelectChangeEvent,
  Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';

const formatNumber = (num: number | null): string => {
  if (num === null || num === undefined) return '';
  return num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

const BoldTableRow = styled(TableRow)(({ theme }) => ({
  '& td, & th': {
    fontWeight: 'bold',
  },
}));

const TableHeaderCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
}));

interface ComputationSheetProps {
  laborAndMaterials: number;
  regularParts: number;
  deductible: number;
  totalLossReserve: number;
  totalLabor: number;
  depreciation: number;
}

const ComputationSheet: React.FC<ComputationSheetProps> = ({ laborAndMaterials, regularParts, deductible, depreciation, totalLossReserve, totalLabor }) => {
  const [markup, setMarkup] = useState<number>(0.15);
  const [selectedShop, setSelectedShop] = useState<string>('Not Listed');
  const [data, setData] = useState<any[]>([]);

  const shopList = [
    "Not Listed",
    "1DYNAMIX AUTOMOTIVE INC.",
    "ARK DIVERSIFIED AND AUTOMOTORS GROUP, INC.",
    "AVESCOR MOTORS OF BACOLOD, INC.",
    "AVESCOR MOTORS, INC.",
    "AUTOALLEE GROUP INC.",
    "AUTOVELOCITY CORPORATION",
    "BRITANNICA UNITED MOTORS INCORPORATED",
    "BEST SOUTHERN GENESIS MOTORS",
    "DAVAO BONIFACIO MOTORS, INC.",
    "DIAMOND MOTOR CORPORATION",
    "EUROCORSA MOTORCYCLE SHOP",
    "FREEWAY MOTOR SALES OF CABANATUAN CORP.",
    "FRONTIER AUTOMOTIVE MARILAO CORPORATION",
    "GENCARS BATANGAS CITY, INC.",
    "GENESIS MOTORS CORPORATION",
    "GRAND CANYON MULTI HOLDINGS, INC.",
    "GRANDCARS, INC.",
    "HONDA CARS DAVAO, INC",
    "HONDA CARS ILOCOS, INC.",
    "HONDA CARS PANGASINAN, INC.",
    "HONDA CARS TARLAC, INC.",
    "HYUNDAI E. RODRIGUEZ SR. AVE",
    "Hyundai Manila Bay",
    "INDUSTRIAL & TRANSPORT EQUIPMENT, INC.",
    "ISUZU MAKATI",
    "MAXIMOTOR CORP.",
    "MOTORCENTRUM CORPORATION",
    "MOTORPLAZA INC.",
    "MNV AUTO GROUP INC.",
    "NORTH QUADRANT VENTURES AND RESOURCES, INC.",
    "NORTHPOINT ALLIANCE MOTORS CORP.",
    "SAKURA AUTOWORLD, INC",
    "SOUTHERN AUTO MART & TRADING CORP.",
    "SUZUKI E.RODRIGUEZ SR. AVE.",
    "TRI-LINE CAR CARE CENTER",
    "Wheels, Inc."
  ];

  const handleMarkupChange = (event: SelectChangeEvent<number>) => {
    setMarkup(Number(event.target.value));
  };

  const handleShopChange = (event: SelectChangeEvent<string>) => {
    setSelectedShop(event.target.value);
  };

  useEffect(() => {
    const initialData = [
      { category: 'Labor And Materials', values: [laborAndMaterials, laborAndMaterials, null, null, null], isBold: true },
      { category: 'Regular Parts', values: [regularParts, regularParts, null, null, null], isBold: true },
      { category: 'GROSS CLAIM', values: [null, null, null, null, null], isBold: true },
      { category: "LESS: Claimant's Participation", values: [null, null, null, null, null] },
      { category: 'Deductible(s)', values: [deductible, deductible, deductible, null, null] },
      { category: 'Depreciation (Regular)', values: [depreciation, depreciation, null, null, null] },
      { category: 'NET CLAIM', values: [null, null, null, null, null], isBold: true },
      { category: 'BOOKED LOSS RESERVES', values: [null, null, null, null, null] },
      { category: 'Net Claim (Cash Settled Parts)', values: [0.00, 0.00, null, null, null] },
      { category: 'Net Claim (Non-VAT)', values: [0.00, 0.00, null, null, null] },
      { category: 'Labor and Materials', hidden: 'Labor', values: [totalLabor, totalLabor, null, null, null] },
      { category: 'Total Parts', values: [null, null, null, null, null] },
      { category: 'Total 12.00% VAT Due', values: [totalLossReserve * 0.12, totalLossReserve * 0.12, null, null, null] },
      { category: 'Total 2.00% Withholding Tax (Labor & Materials)', values: [totalLabor * .02, -270.24, null, null, null] },
      { category: 'Total 1.00% Withholding Tax (Parts)', values: [714.29, 714.29, null, null, null] },
      { category: 'NET CLAIM PAYMENT/S', values: [99103.54, 94149.09, null, null, null] },
    ];

    let netClaim = 0;
    let bookReserveMarkup = 0;
    let bookReserve = 0;
    let labor_with_vat = 0;
    let totalnetClaim = 0;
    let wht_labor = 0;
    let wht_parts = 0;
    let laborMarkup = 0;
    let totalPartsMarkup = 0;
    let netClaimMarkup = 0;
    let withHoldingPartsMarkup = 0;

    const newData = initialData.map((row) => {
      if (row.category === 'Labor And Materials' || row.category === 'Non-VATable Labor') {
        row.values[1] = row.values[0] ? row.values[0] - (row.values[0] * markup) : row.values[1];
        row.values[2] = row.values[1];
      }

      if (row.category === 'Total 12.00% VAT Due') {
        row.values[0] = bookReserve * 0.12;
        row.values[1] = (laborMarkup + totalPartsMarkup) * 0.12;
        labor_with_vat = row.values[0];

      }

      if (row.hidden === 'Labor') {
        row.values[1] = row.values[0] ? row.values[0] - (row.values[0] * markup) : row.values[1];
        laborMarkup = row.values[1] ?? 0;

      }

      if (row.category === 'VATable Labor') {
        row.values[1] = initialData[0]?.values[1];
        row.values[2] = row.values[1];
      }
      if (row.category === 'Regular Parts' || row.category === 'Non-VATable Parts') {
        row.values[1] = row.values[0];
        row.values[2] = row.values[1];
      }
      if (row.category === 'GROSS CLAIM') {
        row.values[0] = (initialData[0]?.values[0] ?? 0) + (initialData[1]?.values[0] ?? 0);
        row.values[1] = (initialData[0]?.values[1] ?? 0) + (initialData[1]?.values[1] ?? 0);
        row.values[2] = row.values[1];
      }
      if (row.category === 'NET CLAIM') {

        row.values[0] = (initialData[2]?.values[0] ?? 0) - (initialData[4]?.values[0] ?? 0) - depreciation;
        row.values[1] = (initialData[2]?.values[1] ?? 0) - (initialData[4]?.values[1] ?? 0) - depreciation;
        totalnetClaim = row.values[0];
        netClaimMarkup = row.values[1];
        row.values[2] = row.values[1];
      }
      if (row.category === 'BOOKED LOSS RESERVES') {

        row.values[0] = ((initialData[2]?.values[0] ?? 0) - (initialData[4]?.values[0] ?? 0)) / 1.12;
        row.values[1] = ((initialData[2]?.values[1] ?? 0) - (initialData[4]?.values[1] ?? 0)) / 1.12;
        bookReserve = row.values[0];
        bookReserveMarkup = row.values[1];
        row.values[2] = row.values[1];
      }
      if (row.category === 'Total Parts') {
        netClaim = ((initialData[1]?.values[0] ?? 0) - (initialData[4]?.values[0] ?? 0) - depreciation) / 1.12;
        row.values[0] = netClaim;
        row.values[1] = netClaim;
        totalPartsMarkup = row.values[0];
      }
      if (row.category === "Total 1.00% Withholding Tax (Parts)") {
        if (selectedShop !== 'Not Listed') {
          row.values[0] = netClaim * 0.01;
          wht_parts = row.values[0];
        } else {
          row.values[0] = 0;
          wht_parts = 0;
        }
        row.values[1] = row.values[0];
        withHoldingPartsMarkup = row.values[1];
      }

      if (row.category === 'Total 2.00% Withholding Tax (Labor & Materials)') {
        row.values[0] = ((initialData[0]?.values[0] ?? 0) / 1.12) * 0.02;
        wht_labor = row.values[0];
        row.values[1] = row.values[0] - (row.values[0] * markup);
      }

      if (row.category === 'NET CLAIM PAYMENT/S') {
        row.values[0] = totalnetClaim - wht_labor - wht_parts;
        row.values[1] = netClaimMarkup - wht_labor - wht_parts;
      }

      return row;
    });
    setData(newData);
  }, [laborAndMaterials, regularParts, deductible, markup, totalLossReserve, totalLabor, selectedShop]);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Typography variant="h6" gutterBottom textAlign={'center'}>
            Markup
          </Typography>
          <Select
            value={markup}
            onChange={handleMarkupChange}
            variant="outlined"
            inputProps={{ style: { textAlign: 'center' } }}
            MenuProps={{
              PaperProps: {
                style: {
                  textAlign: 'center',
                },
              },
            }}
            fullWidth
          >
            <MenuItem value={0}>0%</MenuItem>
            <MenuItem value={0.15}>15%</MenuItem>
            <MenuItem value={0.20}>20%</MenuItem>
            <MenuItem value={0.25}>25%</MenuItem>
            <MenuItem value={0.30}>30%</MenuItem>
            <MenuItem value={0.35}>35%</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={8}>
          <Typography variant="h6" gutterBottom textAlign={'center'}>
            Shop List 1% WHT Parts
          </Typography>
          <Select
            value={selectedShop}
            onChange={handleShopChange}
            variant="outlined"
            fullWidth
             inputProps={{ style: { textAlign: 'center' } }}
  MenuProps={{
    PaperProps: {
      style: {
        textAlign: 'center',
      },
    },
  }}
          >
            {shopList.map((shop, index) => (
              <MenuItem key={index} value={shop}>{shop}</MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>




      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeaderCell></TableHeaderCell>
              <TableHeaderCell></TableHeaderCell>
              <TableHeaderCell>Offer 1</TableHeaderCell>
              <TableHeaderCell>Offer 2</TableHeaderCell>
              <TableHeaderCell>Cash Settlement</TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <React.Fragment key={index}>
                {row.isBold ? (
                  <BoldTableRow>
                    <TableCell>{row.category}</TableCell>
                    <TableCell>{row.category === 'Labor And Materials' || row.category === 'Regular Parts' || row.category === 'Deductible(s)' ? formatNumber(row.values[0]) : ''}</TableCell>
                    {row.values.map((value: any, idx: any) => (
                      <TableCell key={idx}>{value !== null ? formatNumber(value) : ''}</TableCell>
                    ))}
                  </BoldTableRow>
                ) : (
                  <TableRow>
                    <TableCell>{row.category}</TableCell>
                    <TableCell>{row.category === 'Labor And Materials' || row.category === 'Regular Parts' || row.category === 'Deductible(s)' ? formatNumber(row.values[0]) : ''}</TableCell>
                    {row.values.map((value: any, idx: any) => (
                      <TableCell key={idx}>{value !== null ? formatNumber(value) : ''}</TableCell>
                    ))}
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ComputationSheet;
