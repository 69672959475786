export function formatDateEmail(rawDate: any): string {
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
  
    // Check if rawDate is an array (or string)
    if (Array.isArray(rawDate)) {
      // Parse date components from the array
      const [year, month, day, hours, minutes, seconds] = rawDate;
      const date = new Date(year, month - 1, day, hours, minutes, seconds);
  
      const formattedMonth = months[date.getMonth()];
      const formattedDay = date.getDate();
      const formattedYear = date.getFullYear();
  
      let formattedHours = date.getHours();
      const formattedMinutes = date.getMinutes();
      const ampm = formattedHours >= 12 ? 'pm' : 'am';
  
      formattedHours = formattedHours % 12;
      formattedHours = formattedHours ? formattedHours : 12; // the hour '0' should be '12'
      const strMinutes = formattedMinutes < 10 ? `0${formattedMinutes}` : formattedMinutes;
  
      return `${formattedMonth} ${formattedDay}, ${formattedYear} | ${formattedHours}:${strMinutes}${ampm}`;
    }
  
    if (typeof rawDate === 'string') {
      // Assume string is a simple comma-separated format
      const dateParts = rawDate.split(',').map(Number);
  
      if (dateParts.length === 6) {
        const [year, month, day, hours, minutes, seconds] = dateParts;
        const date = new Date(year, month - 1, day, hours, minutes, seconds);
  
        const formattedMonth = months[date.getMonth()];
        const formattedDay = date.getDate();
        const formattedYear = date.getFullYear();
  
        let formattedHours = date.getHours();
        const formattedMinutes = date.getMinutes();
        const ampm = formattedHours >= 12 ? 'pm' : 'am';
  
        formattedHours = formattedHours % 12;
        formattedHours = formattedHours ? formattedHours : 12; // the hour '0' should be '12'
        const strMinutes = formattedMinutes < 10 ? `0${formattedMinutes}` : formattedMinutes;
  
        return `${formattedMonth} ${formattedDay}, ${formattedYear} | ${formattedHours}:${strMinutes}${ampm}`;
      }
    }
  
    console.error('formatDate error: Unsupported format for rawDate:', rawDate);
    return 'Invalid date';
  }
  