import {
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  Box,
  Button,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Select from "react-select";
import EstimationTable from "./EstimationTable";
import axios from "axios";
import MissingDocument from "../MissingDocument";
import { GlobalDataContext } from "../../contexts/GlobalDataContext";
import TransferButton from "./TransferButton";
import { v4 as uuidv4 } from "uuid";
import { AuditTrailDataContext } from "../../contexts/AuditTrailContext";

function toTitleCase(str: string | undefined | null): string | undefined {
  if (!str) {
    return str as undefined;
  }

  return str.replace(/\w\S*/g, (word) => {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
  });
}

export default function EstimationForm({
  option,
  transID,
  disabled,
  handleBack,
  handleNext,
  close,
  userData,
  docId,
}: any) {
  const [mainState, setMainState] = React.useState<any>({
    data: {},
    oriData: {},
    selections: {},
    changed: {},
    formError: {},
    isError: false,
    attachments: [],
    isLoading: false,
  });
  const [isMissing, setIsMissing] = React.useState<boolean>(false);
  const [isSubmit, setIsSubmit] = React.useState<boolean>(false);
  const { globalData, setGlobalData } = React.useContext(GlobalDataContext);
  const [shopItemTable, setShopItemTable] = React.useState({
    headers: [],
    rows: []
  });
  const { createAuditTrailData } =
    React.useContext(AuditTrailDataContext);

  const fetchData = async () => {
    try {
      (async () => {
        axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/api/acap/shop-estimate?transId=${transID}&id=${docId}`,
          // headers: {
          //   'Authorization': `Bearer ${globalData?.sessionToken}`
          // }
        })
          .then((res: any) => {
            const data = res.data.data;
            const tblShopItemsJSON = res.data.data.tableItems ? JSON.parse(res.data.data.tableItems) : null;

            console.log("mvf", data);
            if (tblShopItemsJSON) {
              const tblShopItemRows = tblShopItemsJSON.rows.map((row: any) => {
                // Generate a unique ID for each row
                const id = uuidv4();
            
                // Handle the case where row is empty
                if (row.length === 0) {
                  return tblShopItemsJSON.header.reduce(
                    (acc: any, key: any) => {
                      acc[key] = "";
                      return acc;
                    },
                    { id }
                  ); // Include unique id in the object
                }
            
                return row.reduce(
                  (acc: any, value: any, index: any) => {
                    acc[tblShopItemsJSON.header[index]] = value;
                    return acc;
                  },
                  { id }
                ); // Include unique id in the object
              });
            
              setShopItemTable({
                headers: tblShopItemsJSON.header,
                rows: tblShopItemRows,
              });
            }


            const statusOptions: any = {
              0: null,
              1: { value: 1, label: "Open" },
              2: { value: 2, label: "Approve" },
              3: { value: 3, label: "Reject" },
            };
            setMainState({
              ...mainState,
              data: data,
              selections: { status: statusOptions[data?.status] },
            });

            createAuditTrailData({
              transId: transID,
              taskType: "document_validation",
              event: "view_form",
              description: `View shop estimate form with document id #${docId}`,
              userId: globalData.id
            })
          })
          .catch((err: any) => {
            console.error(err);
            let errorMessage = "";
            if (err?.response?.status === 401)
              errorMessage = err?.response?.data;
            if (err?.response?.status === 400)
              errorMessage = err?.response?.data;
          });
      })();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateData = async () => {

    // prepare table of victims
    let tblItemsReq = mainState?.data?.tableItems;
    if (shopItemTable?.rows.length > 0 && shopItemTable?.headers.length > 0) {
      tblItemsReq = JSON.stringify({
        header: shopItemTable?.headers,
        rows: shopItemTable?.rows.map((obj) => {
          return shopItemTable?.headers.map((key) => obj[key]);
        })
      })
    }

    const dataReq = {
      ...mainState?.data,
      tableItems: tblItemsReq
    }

    try {
      setMainState((prevState: any) => ({ ...prevState, isLoading: true }));
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/shop-estimate?transId=${transID}&id=${docId}`,
        dataReq
      );
      const data = res.data;
      const statusOptions: any = {
        0: null,
        1: { value: 1, label: "Open" },
        2: { value: 2, label: "Approve" },
        3: { value: 3, label: "Reject" },
      };
      setMainState((prevState: any) => ({
        ...prevState,
        data: data,
        selections: { status: statusOptions[data.status] },
        formError: data?.error,
        isLoading: false,
      }));

      createAuditTrailData({
        transId: transID,
        taskType: "document_validation",
        event: "update_form",
        description: `Update shop estimate form with document id #${docId}`,
        userId: globalData.id
      })
      close();
    } catch (error) {
      console.error("Error updating data:", error);
      setMainState((prevState: any) => ({ ...prevState, isLoading: false }));
    }
  };

  // const apiGetDocumentStatus = async () => {
  //   axios({
  //     method: 'get',
  //     url: `${process.env.REACT_APP_BACKEND_URL}/api/acap/shop-estimate?transId=${transID}`,
  //     // headers: {
  //     //   'Authorization': `Bearer ${globalData?.sessionToken}`
  //     // }
  //   }).then((res: any) => {
  //     console.log("Response: ", res.data.status)
  //     setIsMissing(res.data?.status === "Missing" ? true : false);
  //   }).catch((err: any) => {
  //     console.error(err?.response?.data)
  //     let errorMessage = ""
  //     if (err?.response?.status === 401) errorMessage = err?.response?.data
  //     if (err?.response?.status === 400) errorMessage = err?.response?.data
  //   })
  // }

  const isAnyFieldEmpty = () => {
    console.log("empty:", mainState?.data);
    for (const key in mainState?.data) {
      const fieldValue = mainState?.data[key];
      if (
        key !== "remarks" &&
        key !== "id" &&
        key !== "status" &&
        key !== "discount" &&
        key !== "preparedBy" &&
        key !== "saveDate" &&
        key !== "sourceId" &&
        key !== "table_items" &&
        key !== "tax" &&
        key !== "total" &&
        key !== "transId" &&
        (typeof fieldValue !== "string" || !fieldValue.trim())
      ) {
        console.log("mainState error:", key);
        return true;
      } else if (
        key === "status" &&
        (typeof fieldValue !== "number" || fieldValue === 0)
      ) {
        console.log("mainState error:", key);
        return true;
      }
    }
    return false;
  };

  React.useEffect(() => {
    // apiGetDocumentStatus();
    fetchData();
  }, []);

  const handleInputChange = (e: any) => {
    const { value, id } = e.target;
    setMainState({ ...mainState, data: { ...mainState.data, [id]: value } });
  };

  const handleSelectChange = (selection: any) => {
    console.log(selection);
    setMainState({
      ...mainState,
      data: { ...mainState.data, status: selection.value },
      selections: { status: selection },
    });
  };

  // const handleBlur = (id: any) => {
  //   if (datachanged[id]) { //check if have any changes in the value and then data will reflect on the Database via PUT API.
  //     updateData(transID, 'EstimationForm', false)
  //   }

  // }

  //#endregion Control Methods

  return (
    <div>
      {isMissing === true ? (
        <MissingDocument />
      ) : (
        <>
          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Vendor Name <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="vendorName"
                  size="small"
                  type="text"
                  placeholder={`Enter Vendor Name Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.refNoConf + "%"} size="small" className={datadata.refNoConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.vendorName || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('refNo')}
                  inputProps={{
                    "aria-label": "vendorName",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.vendorName === ""}
                />
                {(mainState?.data?.vendorName ?? "") === "" && (
                  <FormHelperText error id="vendorName">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Issued Date <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="issuedDate"
                  size="small"
                  type="text"
                  placeholder={`Enter Issued Date Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.issuedDateConf + "%"} size="small" className={datadata.issuedDateConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.issuedDate || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('issuedDate')}
                  inputProps={{
                    "aria-label": "issuedDate",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.issuedDate === ""}
                />
                {(mainState?.data?.issuedDate ?? "") === "" && (
                  <FormHelperText error id="issuedDate">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Customer's Name
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="customerName"
                  size="small"
                  type="text"
                  placeholder={`Enter Customer's Name Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.customerNameConf + "%"} size="small" className={datadata.customerNameConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.customerName || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('customerName')}
                  inputProps={{
                    "aria-label": "customerName",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.customerName === ""}
                />
                {(mainState?.data?.customerName ?? "") === "" && (
                  <FormHelperText error id="customerName">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Mobile No.
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="mobileNo"
                  size="small"
                  type="text"
                  placeholder={`Enter Mobile No. Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.mobileNoConf + "%"} size="small" className={datadata.mobileNoConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.mobileNo || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('mobileNo')}
                  inputProps={{
                    "aria-label": "mobileNo",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.mobileNo === ""}
                />
                {(mainState?.data?.mobileNo ?? "") === "" && (
                  <FormHelperText error id="mobileNo">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Email Address <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="emailAddress"
                  size="small"
                  type="text"
                  placeholder={`Enter Email Address Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.emailAddressConf + "%"} size="small" className={datadata.emailAddressConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.emailAddress || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('emailAddress')}
                  inputProps={{
                    "aria-label": "emailAddress",
                  }}
                  disabled={disabled}
                  // error={mainState?.data?.emailAddress === ""}
                />
                {/* {(mainState?.data?.emailAddress ?? "") === "" && (
                      <FormHelperText error id="emailAddress">
                        Empty required field. Please input data
                      </FormHelperText>
                    )} */}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Contact Person
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="contactPerson"
                  size="small"
                  type="text"
                  placeholder={`Enter Contact Person Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.contactPersonConf + "%"} size="small" className={datadata.contactPersonConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.contactPerson || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('contactPerson')}
                  inputProps={{
                    "aria-label": "contactPerson",
                  }}
                  disabled={disabled}
                  // error={mainState?.data?.contactPerson === ""}
                />
                {/* {mainState?.data?.contactPerson === "" && (
                      <FormHelperText error id="contactPerson">
                        Empty required field. Please input data
                      </FormHelperText>
                    )} */}
              </FormControl>
            </Grid>
            {/* <Grid item md={3}>
                  <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                    <Typography variant="body1" className="tp-text-bold">
                      Prepared By
                 
                      <span className="tp-text-required">*</span>
                    </Typography>
                    <OutlinedInput
                      id="preparedBy"
                      size="small"
                      type="text"
                      placeholder={`Enter Contact Person Here...`}
                   
                      value={mainState?.data?.contactPerson || ''}
                      onChange={handleInputChange}
                    
                      inputProps={{
                        "aria-label": "contactPerson",
                      }}
                      disabled={disabled}
                      error={mainState?.data?.contactPerson === ""}
                    />
                    {mainState?.data?.contactPerson === "" && (
                      <FormHelperText error id="contactPerson">
                        Empty required field. Please input data
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid> */}
          </Grid>

          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={3}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Plate No.
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="plateNo"
                  size="small"
                  type="text"
                  placeholder={`Enter Plate No. Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.mobileNoConf + "%"} size="small" className={datadata.mobileNoConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.plateNo || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('mobileNo')}
                  inputProps={{
                    "aria-label": "plateNo",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.plateNo === ""}
                />
                {(mainState?.data?.plateNo ?? "") === "" && (
                  <FormHelperText error id="plateNo">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Model / Year <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="modelYear"
                  size="small"
                  type="text"
                  placeholder={`Enter Model Year Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.emailAddressConf + "%"} size="small" className={datadata.emailAddressConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.modelYear || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('emailAddress')}
                  inputProps={{
                    "aria-label": "modelYear",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.modelYear === ""}
                />
                {(mainState?.data?.modelYear ?? "") === "" && (
                  <FormHelperText error id="modelYear">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Serial No.
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="serialNo"
                  size="small"
                  type="text"
                  placeholder={`Enter Serial No. Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.contactPersonConf + "%"} size="small" className={datadata.contactPersonConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.serialNo || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('contactPerson')}
                  inputProps={{
                    "aria-label": "serialNo",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.serialNo === ""}
                />
                {mainState?.data?.serialNo === "" && (
                  <FormHelperText error id="serialNo">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Discount Parts and Labors
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="discPartslabors"
                  size="small"
                  type="text"
                  placeholder={`Enter Discount Parts and Labors Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.contactPersonConf + "%"} size="small" className={datadata.contactPersonConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.discPartslabors || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('contactPerson')}
                  inputProps={{
                    "aria-label": "discPartslabors",
                  }}
                  disabled={disabled}
                  // error={mainState?.data?.discPartslabors === ""}
                />
                {/* {mainState?.data?.discPartslabors === "" && (
                      <FormHelperText error id="discPartslabors">
                        Empty required field. Please input data
                      </FormHelperText>
                    )} */}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Scope of Works
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="scopeOfWorks"
                  size="small"
                  type="text"
                  placeholder={`Enter Scope of Works Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.mobileNoConf + "%"} size="small" className={datadata.mobileNoConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.scopeOfWorks || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('mobileNo')}
                  inputProps={{
                    "aria-label": "scopeOfWorks",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.scopeOfWorks === ""}
                />
                {(mainState?.data?.scopeOfWorks ?? "") === "" && (
                  <FormHelperText error id="scopeOfWorks">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Job Instruction <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="jobInstruction"
                  size="small"
                  type="text"
                  placeholder={`Enter Job Instruction Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.emailAddressConf + "%"} size="small" className={datadata.emailAddressConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.jobInstruction || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('emailAddress')}
                  inputProps={{
                    "aria-label": "jobInstruction",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.jobInstruction === ""}
                />
                {(mainState?.data?.jobInstruction ?? "") === "" && (
                  <FormHelperText error id="jobInstruction">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Parts Name
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="partsName"
                  size="small"
                  type="text"
                  placeholder={`Enter Parts Name Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.contactPersonConf + "%"} size="small" className={datadata.contactPersonConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.partsName || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('contactPerson')}
                  inputProps={{
                    "aria-label": "partsName",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.partsName === ""}
                />
                {mainState?.data?.partsName === "" && (
                  <FormHelperText error id="partsName">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <br />
          <br />
          {/* 
            // #region EstimateTable
          */}
          <EstimationTable
            transID={transID}
            mainState={mainState}
            shopItemTable={shopItemTable}
            setShopItemTable={setShopItemTable}
            disabled={disabled}
          />
          <br />
          <br />

          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Tinsmith
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  {/* <span className="tp-text-required">*</span> */}
                </Typography>
                <OutlinedInput
                  id="tinsmith"
                  size="small"
                  type="text"
                  placeholder={`Enter Tinsmith Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.mobileNoConf + "%"} size="small" className={datadata.mobileNoConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.tinsmith || 0}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('mobileNo')}
                  inputProps={{
                    "aria-label": "tinsmith",
                  }}
                  disabled={disabled}
                  // error={mainState?.data?.tinsmith === ""}
                />
                {/* {(mainState?.data?.tinsmith ?? "") === "" && (
                      <FormHelperText error id="tinsmith">
                        Empty required field. Please input data
                      </FormHelperText>
                    )} */}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Body Repair Labor <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="bodyRepairlabor"
                  size="small"
                  type="text"
                  placeholder={`Enter Body Repair Labor Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.emailAddressConf + "%"} size="small" className={datadata.emailAddressConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.bodyRepairlabor || 0}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('emailAddress')}
                  inputProps={{
                    "aria-label": "bodyRepairlabor",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.bodyRepairlabor === ""}
                />
                {(mainState?.data?.bodyRepairlabor ?? "") === "" && (
                  <FormHelperText error id="bodyRepairlabor">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Painting
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="painting"
                  size="small"
                  type="text"
                  placeholder={`Enter Painting Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.contactPersonConf + "%"} size="small" className={datadata.contactPersonConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.painting || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('contactPerson')}
                  inputProps={{
                    "aria-label": "painting",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.painting === ""}
                />
                {mainState?.data?.painting === "" && (
                  <FormHelperText error id="painting">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Total Sublet
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  {/* <span className="tp-text-required">*</span> */}
                </Typography>
                <OutlinedInput
                  id="totalSublet"
                  size="small"
                  type="text"
                  placeholder={`Enter Total Sublet Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.mobileNoConf + "%"} size="small" className={datadata.mobileNoConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.totalSublet || 0}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('mobileNo')}
                  inputProps={{
                    "aria-label": "totalSublet",
                  }}
                  disabled={disabled}
                  // error={mainState?.data?.totalSublet === ""}
                />
                {/* {(mainState?.data?.totalSublet ?? "") === "" && (
                      <FormHelperText error id="totalSublet">
                        Empty required field. Please input data
                      </FormHelperText>
                    )} */}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Total Oil Lubricant{" "}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="totalOillubricant"
                  size="small"
                  type="text"
                  placeholder={`Enter Total Oil Lubricant Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.emailAddressConf + "%"} size="small" className={datadata.emailAddressConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.totalOillubricant || 0}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('emailAddress')}
                  inputProps={{
                    "aria-label": "totalOillubricant",
                  }}
                  disabled={disabled}
                  // error={mainState?.data?.totalOillubricant === ""}
                />
                {/* {(mainState?.data?.totalOillubricant ?? "") === "" && (
                      <FormHelperText error id="totalOillubricant">
                        Empty required field. Please input data
                      </FormHelperText>
                    )} */}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Materials
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="materials"
                  size="small"
                  type="text"
                  placeholder={`Enter Materials Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.contactPersonConf + "%"} size="small" className={datadata.contactPersonConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.materials || 0}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('contactPerson')}
                  inputProps={{
                    "aria-label": "materials",
                  }}
                  disabled={disabled}
                  // error={mainState?.data?.materials === ""}
                />
                {/* {mainState?.data?.materials === "" && (
                      <FormHelperText error id="materials">
                        Empty required field. Please input data
                      </FormHelperText>
                    )} */}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={3}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Miscellaneous
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="miscellaneous"
                  size="small"
                  type="text"
                  placeholder={`Enter Miscellaneous Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.mobileNoConf + "%"} size="small" className={datadata.mobileNoConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.miscellaneous || 0}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('mobileNo')}
                  inputProps={{
                    "aria-label": "miscellaneous",
                  }}
                  disabled={disabled}
                  // error={mainState?.data?.miscellaneous === ""}
                />
                {/* {(mainState?.data?.miscellaneous ?? "") === "" && (
                      <FormHelperText error id="miscellaneous">
                        Empty required field. Please input data
                      </FormHelperText>
                    )} */}
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Sundries <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="sundries"
                  size="small"
                  type="text"
                  placeholder={`Enter Sundries Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.emailAddressConf + "%"} size="small" className={datadata.emailAddressConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.sundries || 0}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('emailAddress')}
                  inputProps={{
                    "aria-label": "sundries",
                  }}
                  disabled={disabled}
                  // error={mainState?.data?.sundries === ""}
                />
                {/* {(mainState?.data?.sundries ?? "") === "" && (
                      <FormHelperText error id="sundries">
                        Empty required field. Please input data
                      </FormHelperText>
                    )} */}
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Tax
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  {/* <span className="tp-text-required">*</span> */}
                </Typography>
                <OutlinedInput
                  id="vat"
                  size="small"
                  type="text"
                  placeholder={`Enter Tax Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.mobileNoConf + "%"} size="small" className={datadata.mobileNoConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.vat || 0}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('mobileNo')}
                  inputProps={{
                    "aria-label": "vat",
                  }}
                  disabled={disabled}
                  // error={mainState?.data?.vat === ""}
                />
                {/* {(mainState?.data?.vat ?? "") === "" && (
                      <FormHelperText error id="vat">
                        Empty required field. Please input data
                      </FormHelperText>
                    )} */}
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Discount
                </Typography>
                <OutlinedInput
                  id="discount"
                  size="small"
                  type="text"
                  placeholder={`Enter Discount Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.emailAddressConf + "%"} size="small" className={datadata.emailAddressConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.discount || 0}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('emailAddress')}
                  inputProps={{
                    "aria-label": "discount",
                  }}
                  disabled={disabled}
                  // error={mainState?.data?.discount === ""}
                />
                {/* {(mainState?.data?.discount ?? "") === "" && (
                      <FormHelperText error id="discount">
                        Empty required field. Please input data
                      </FormHelperText>
                    )} */}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Total Labor
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="totalLabor"
                  size="small"
                  type="text"
                  placeholder={`Enter Total Labor Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.contactPersonConf + "%"} size="small" className={datadata.contactPersonConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.totalLabor || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('contactPerson')}
                  inputProps={{
                    "aria-label": "totalLabor",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.totalLabor === ""}
                />
                {mainState?.data?.totalLabor === "" && (
                  <FormHelperText error id="materials">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Total Parts
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="totalParts"
                  size="small"
                  type="text"
                  placeholder={`Enter Total Parts Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.contactPersonConf + "%"} size="small" className={datadata.contactPersonConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.totalParts || "3,900.00"}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('contactPerson')}
                  inputProps={{
                    "aria-label": "totalParts",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.totalParts === ""}
                />
                {mainState?.data?.totalLabor === "" && (
                  <FormHelperText error id="totalParts">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Total Amount
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="totalAmount"
                  size="small"
                  type="text"
                  placeholder={`Enter Total Amount Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.contactPersonConf + "%"} size="small" className={datadata.contactPersonConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.totalAmount || "59,230.00"}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('contactPerson')}
                  inputProps={{
                    "aria-label": "totalAmount",
                  }}
                  disabled={disabled}
                  // error={mainState?.data?.totalAmount === ""}
                />
                {/* {mainState?.data?.totalAmount === "" && (
                      <FormHelperText error id="totalAmount">
                        Empty required field. Please input data
                      </FormHelperText>
                    )} */}
              </FormControl>
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
          <br />
          <Typography variant="h6" className="tp-text-header-primary">
            For Processor
          </Typography>
          <hr />
          <FormControl variant="outlined" fullWidth className="tp-mb-20px">
            <Typography variant="body1" className="tp-text-bold">
              Status <span className="tp-text-required">*</span>
            </Typography>
            <Select
              id="status"
              placeholder="Please select status here..."
              value={mainState?.selections?.status || null}
              onChange={handleSelectChange}
              isDisabled={disabled}
              options={[
                { value: 1, label: "Open" },
                { value: 2, label: "Approved" },
                { value: 3, label: "Reject" },
              ]}
            />
            {mainState?.data?.status === null && (
              <FormHelperText error id="status">
                Empty required field. Please input data
              </FormHelperText>
            )}
          </FormControl>
          <FormControl variant="outlined" fullWidth className="tp-mb-30px">
            <Typography variant="body1" className="tp-text-bold">
              Remarks
            </Typography>
            <OutlinedInput
              id="remarks"
              size="small"
              type="text"
              multiline
              className="tp-mb-30px"
              placeholder={`Enter Remarks Here...`}
              value={mainState?.data?.remarks || ""}
              onChange={handleInputChange}
              // onBlur={() => handleBlur('remarks')}
              inputProps={{
                "aria-label": "remarks",
              }}
              disabled={disabled}
              // error={!!dataformError?.remarks}
            />
            {/* {!!dataformError?.remarks && (
            <FormHelperText error id="remarks">
              {dataformError?.remarks}
            </FormHelperText>
          )} */}
          </FormControl>
          <hr />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <Typography variant="h6" className="tp-text-header-primary">
              Login As User: &ensp;
              <span style={{ color: "#525252" }}>
                {toTitleCase(globalData?.firstName)}{" "}
                {toTitleCase(globalData?.lastName)}
              </span>
            </Typography>
            <Box
              sx={{
                display: "flex",
              }}
            >
              {/* {option !== 0 && <Button onClick={() => handleBack()} variant="outlined" size='medium' >Back</Button>}&emsp; */}
              {disabled === true ? (
                <Button
                  onClick={() => {
                    close();
                  }}
                  variant="contained"
                  size="medium"
                  color="error"
                >
                  {" "}
                  Close{" "}
                </Button>
              ) : (
                <>
                  <TransferButton
                    docId={docId}
                    transId={transID}
                    endpoint={"/api/acap/shop-estimate"}
                    documentType={"Shop Estimate"}
                    close={close}
                  />
                  &emsp;
                  <Button
                    // disabled={isAnyFieldEmpty()}
                    onClick={() => {
                      updateData();
                      // handleNext();
                    }}
                    variant="contained"
                    size="medium"
                    color="error"
                  >
                    Save
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </>
      )}
    </div>
  );
}
