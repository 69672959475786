import {
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  Box,
  Button,
  Typography,
} from "@mui/material";
import * as React from "react";
import Select from "react-select";
import axios from "axios";
import MissingDocument from "../MissingDocument";
import { GlobalDataContext } from "../../contexts/GlobalDataContext";
import TransferButton from "./TransferButton";
import { AuditTrailDataContext } from "../../contexts/AuditTrailContext";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function toTitleCase(str: string | undefined | null): string | undefined {
  if (!str) {
    return str as undefined;
  }

  return str.replace(/\w\S*/g, (word) => {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
  });
}

export default function DriverLicenseForm({
  option,
  transID,
  disabled,
  handleBack,
  handleNext,
  close,
  userData,
  docId,
}: any) {
  const [mainState, setMainState] = React.useState<any>({
    data: {},
    oriData: {},
    selections: {},
    changed: {},
    formError: {},
    isError: false,
    attachments: [],
    isLoading: false,
  });
  const [isMissing, setIsMissing] = React.useState<boolean>(false);
  const [isSubmit, setIsSubmit] = React.useState<boolean>(false);
  const { globalData, setGlobalData } = React.useContext(GlobalDataContext);
  const { createAuditTrailData } =
    React.useContext(AuditTrailDataContext);

  const fetchData = async () => {
    console.log('DL', docId)
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/driver-license?transId=${transID}&id=${docId}`
      );
      const data = res.data.data;
      const statusOptions: any = {
        0: null,
        1: { value: 1, label: "Open" },
        2: { value: 2, label: "Approve" },
        3: { value: 3, label: "Reject" },
      };

      setMainState((prevState: any) => ({
        ...prevState,
        data: data,
        selections: { status: statusOptions[data?.status] },
      }));

      createAuditTrailData({
        transId: transID,
        taskType: "document_validation",
        event: "view_form",
        description: `View driver license form with document id #${docId}`,
        userId: globalData.id
      })
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateData = async () => {
    try {
      setMainState((prevState: any) => ({ ...prevState, isLoading: true }));
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/driver-license?transId=${transID}&id=${docId}`,
        mainState.data
      );
      const data = res.data;
      const statusOptions: any = {
        0: null,
        1: { value: 1, label: "Open" },
        2: { value: 2, label: "Approve" },
        3: { value: 3, label: "Reject" },
      };
      setMainState((prevState: any) => ({
        ...prevState,
        data: data,
        selections: { status: statusOptions[data.status] },
        formError: data?.error,
        isLoading: false,
      }));

      createAuditTrailData({
        transId: transID,
        taskType: "document_validation",
        event: "update_form",
        description: `Update driver license form with document id #${docId}`,
        userId: globalData.id
      })

      close();
    } catch (error) {
      console.error("Error updating data:", error);
      setMainState((prevState: any) => ({ ...prevState, isLoading: false }));
    }
  };

  const isAnyFieldEmpty = () => {
    for (const key in mainState?.data) {
      const fieldValue = mainState?.data[key];
      if (
        key !== "remarks" &&
        key !== "id" &&
        key !== "status" &&
        key !== "signatureGdriveId" &&
        key !== "dlIssuedDate" &&
        (typeof fieldValue !== "string" || !fieldValue.trim())
      ) {
        return true;
      } else if (
        key === "status" &&
        (typeof fieldValue !== "number" || fieldValue === 0)
      ) {
        return true;
      }
    }
    return false;
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleInputChange = (e: any) => {
    const { value, id } = e.target;
    setMainState((prevState: any) => ({
      ...prevState,
      data: { ...prevState.data, [id]: value },
    }));
  };

  const handleSelectChange = (selection: any) => {
    setMainState((prevState: any) => ({
      ...prevState,
      data: { ...prevState.data, status: selection.value },
      selections: { status: selection },
    }));
  };

  return (
    <div>
      {isMissing ? (
        <MissingDocument />
      ) : (
        <>
          <FormControl variant="outlined" fullWidth className="tp-mb-20px">
            <Typography variant="body1" className="tp-text-bold">
              Driver's Name
              <span className="tp-text-required">*</span>
            </Typography>
            <OutlinedInput
              id="driverName"
              size="small"
              type="text"
              placeholder={`Enter Driver's Name Here...`}
              value={mainState?.data?.driverName || ""}
              onChange={handleInputChange}
              inputProps={{
                "aria-label": "driverName",
              }}
              error={mainState?.data?.driverName === ""}
            />
            {mainState?.data?.driverName === "" && (
              <FormHelperText error id="driverName">
                Empty required field. Please input data
              </FormHelperText>
            )}
          </FormControl>
          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={4}>
              <FormControl size="small" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Nationality <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="nationality"
                  size="small"
                  type="text"
                  placeholder={`Enter Nationality Here...`}
                  value={mainState?.data?.nationality || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "nationality",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.nationality === ""}
                />
                {mainState?.data?.nationality === "" && (
                  <FormHelperText error id="nationality">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl size="small" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Sex <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="sex"
                  size="small"
                  type="text"
                  placeholder={`Enter Received From Here...`}
                  value={mainState?.data?.sex || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "sex",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.sex === ""}
                />
                {mainState?.data?.sex === "" && (
                  <FormHelperText error id="sex">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Date of Birth <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="dateBirth"
                  size="small"
                  type="text"
                  placeholder={`Enter Date of Birth Here...`}
                  value={mainState?.data?.dateBirth || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "dateBirth",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.dateBirth === ""}
                />
                {(mainState?.data?.dateBirth ?? "") === "" && (
                  <FormHelperText error id="dateBirth">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Weight <span className="tp-text-subtitle">(kg)</span>
                </Typography>
                <OutlinedInput
                  id="weight"
                  size="small"
                  type="text"
                  placeholder={`Enter Weight Here...`}
                  value={mainState?.data?.weight || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "weight",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.weight === ""}
                />
                {mainState?.data?.weight === "" && (
                  <FormHelperText error id="weight">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Height <span className="tp-text-subtitle">(m)</span>
                </Typography>
                <OutlinedInput
                  id="height"
                  size="small"
                  type="text"
                  placeholder={`Enter Height Here...`}
                  value={mainState?.data?.height || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "height",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.height === ""}
                />
                {mainState?.data?.height === "" && (
                  <FormHelperText error id="height">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <FormControl variant="outlined" fullWidth className="tp-mb-20px">
            <Typography variant="body1" className="tp-text-bold">
              Address
            </Typography>
            <OutlinedInput
              id="address"
              size="small"
              type="text"
              placeholder={`Enter Address Here...`}
              value={mainState?.data?.address || ""}
              onChange={handleInputChange}
              inputProps={{
                "aria-label": "address",
              }}
              disabled={disabled}
              error={mainState?.data?.address === ""}
            />
            {mainState?.data?.address === "" && (
              <FormHelperText error id="address">
                Empty required field. Please input data
              </FormHelperText>
            )}
          </FormControl>
          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  License No.
                </Typography>
                <OutlinedInput
                  id="licenseNo"
                  size="small"
                  type="text"
                  placeholder={`Enter License No. Here...`}
                  value={mainState?.data?.licenseNo || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "licenseNo",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.licenseNo === ""}
                />
                {mainState?.data?.licenseNo === "" && (
                  <FormHelperText error id="licenseNo">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Expiration Date <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="expirationDate"
                  size="small"
                  type="text"
                  placeholder={`Enter Expiration Date Here...`}
                  value={mainState?.data?.expirationDate || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "expirationDate",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.expirationDate === ""}
                />
                {mainState?.data?.expirationDate === "" && (
                  <FormHelperText error id="expirationDate">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Agency Code
                </Typography>
                <OutlinedInput
                  id="agencyCode"
                  size="small"
                  type="text"
                  placeholder={`Enter Agency Code Here...`}
                  value={mainState?.data?.agencyCode || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "agencyCode",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.agencyCode === ""}
                />
                {mainState?.data?.agencyCode === "" && (
                  <FormHelperText error id="agencyCode">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Blood Type
                </Typography>
                <OutlinedInput
                  id="bloodType"
                  size="small"
                  type="text"
                  placeholder={`Enter Blood Type Here...`}
                  value={mainState?.data?.bloodType || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "bloodType",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.bloodType === ""}
                />
                {mainState?.data?.bloodType === "" && (
                  <FormHelperText error id="bloodType">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Eyes Color
                </Typography>
                <OutlinedInput
                  id="eyesColor"
                  size="small"
                  type="text"
                  placeholder={`Enter Eyes Color Here...`}
                  value={mainState?.data?.eyesColor || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "eyesColor",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.eyesColor === ""}
                />
                {mainState?.data?.eyesColor === "" && (
                  <FormHelperText error id="eyesColor">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  DL Codes <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="dlCodes"
                  size="small"
                  type="text"
                  placeholder={`Enter DL Codes Here...`}
                  value={mainState?.data?.dlCodes || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "dlCodes",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.dlCodes === ""}
                />
                {mainState?.data?.dlCodes === "" && (
                  <FormHelperText error id="dlCodes">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Conditions <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="conditions"
                  size="small"
                  type="text"
                  placeholder={`Enter Conditions Here...`}
                  value={mainState?.data?.conditions || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "conditions",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.conditions === ""}
                />
                {mainState?.data?.conditions === "" && (
                  <FormHelperText error id="conditions">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Restrictions 
                </Typography>
                <OutlinedInput
                  id="restrictions"
                  size="small"
                  type="text"
                  placeholder={`Enter Restrictions Here...`}
                  value={mainState?.data?.restrictions || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "restrictions",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.restrictions === ""}
                />
                {mainState?.data?.restrictions === "" && (
                  <FormHelperText error id="restrictions">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Type of License 
                </Typography>
                <OutlinedInput
                  id="typeOfLicense"
                  size="small"
                  type="text"
                  placeholder={`Enter Type of License Here...`}
                  value={mainState?.data?.typeOfLicense || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "typeOfLicense",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.typeOfLicense === ""}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={12}>
              <Typography variant="body1" className="tp-text-bold">
                With Signature ? <span className="tp-text-required">*</span>
              </Typography>
              Yes
            </Grid>
          </Grid>
          <Typography variant="h6" className="tp-text-header-primary">
            For Processor
          </Typography>
          <hr />
          <FormControl variant="outlined" fullWidth className="tp-mb-20px">
            <Typography variant="body1" className="tp-text-bold">
              Status <span className="tp-text-required">*</span>
            </Typography>
            <Select
              id="status"
              placeholder="Please select status here..."
              value={mainState?.selections?.status || null}
              onChange={handleSelectChange}
              isDisabled={disabled}
              options={[
                { value: 1, label: "Open" },
                { value: 2, label: "Approve" },
                { value: 3, label: "Reject" },
              ]}
            />
            {mainState?.data?.status === null && (
              <FormHelperText error id="status">
                Empty required field. Please input data
              </FormHelperText>
            )}
          </FormControl>
          <FormControl variant="outlined" fullWidth className="tp-mb-30px">
            <Typography variant="body1" className="tp-text-bold">
              Remarks
            </Typography>
            <OutlinedInput
              id="remarks"
              size="small"
              type="text"
              multiline
              placeholder={`Enter Remarks Here...`}
              value={mainState?.data?.remarks || ""}
              onChange={handleInputChange}
              inputProps={{
                "aria-label": "remarks",
              }}
              disabled={disabled}
            />
          </FormControl>
          <hr />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <Typography variant="h6" className="tp-text-header-primary">
              Login As User: &ensp;
              <span style={{ color: "#525252" }}>
                {toTitleCase(globalData?.firstName)}{" "}
                {toTitleCase(globalData?.lastName)}
              </span>
            </Typography>
            <Box sx={{ display: "flex" }}>
              {/* {option !== 0 && <Button onClick={handleBack} variant="outlined" size='medium'>Back</Button>}&emsp; */}
              {disabled ? (
                <Button
                  onClick={handleNext}
                  variant="contained"
                  size="medium"
                  color="primary"
                >
                  Next
                </Button>
              ) : (
                <>
                  <TransferButton
                    docId={docId}
                    transId={transID}
                    endpoint={"/api/acap/document-validation/driver-license"}
                    documentType={"Driver License"}
                    close={close}
                  />
                  &emsp;
                  <Button
                    onClick={updateData}
                    variant="contained"
                    color="error"
                    size="medium"
                  >
                    Save
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </>
      )}
    </div>
  );
}
