import React from "react";
import {
  Typography,
  Box,
  Button,
  CircularProgress,
  OutlinedInput,
  FormControl,
  Modal,
  IconButton,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { GlobalDataContext } from "../contexts/GlobalDataContext";
import { useParams } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import { AuditTrailDataContext } from "../contexts/AuditTrailContext";

interface ClientListProps {
  clientCodeField: string;
  claimRegistrationData: any;
  clientValidated: boolean,
  setClientValidated: (clientValidated: boolean) => void;
}

export default function ClientList({
  clientCodeField,
  claimRegistrationData,
  clientValidated,
  setClientValidated
}: ClientListProps) {
  interface ClientListData {
    id?: number;
    transId?: number;
    clientNumber: string | undefined;
    clientType: string;
    surName: string;
    givenName: string;
    clientSex: string;
    civilStatus: string;
    addressLine01: string;
    addressLine02: string;
    addressLine03: string;
    addressLine04: string;
    addressLine05: string;
    postalCode: string;
    homePhone: string;
    officePhone: string;
    emailAddress: string;
    riskProfileLevel: string;
    mobilePhone: string;
    birthday: string;
    clientStatus: number;
    countryofOrigin: number;
    registrationDate: number;
    taxIdNumber: number;
    marketSector: number;
    registationNumber: number;
    validatedBy?: string;
    saveDate?: string;
    remarks?: string;
    status?: number;
  }

  const [modalOpen, setModalOpen] = React.useState(false);
  const [clientData, setClientData] = React.useState<ClientListData | null>(
    null
  );
  
  const [loading, setLoading] = React.useState(false);
  const [mainState, setMainState] = React.useState<any>({
    data: { clientNo: clientCodeField || "" },
    clientData: {},
  });
  const { globalData } = React.useContext(GlobalDataContext);
  const { transId } = useParams();
  const { createAuditTrailData } =
    React.useContext(AuditTrailDataContext);

  // #region createClient
  const createClient = async (clientDataReq: any) => {
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/client-list?transId=${transId}`,
        JSON.stringify(clientDataReq),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res: any) => {
        console.log(res?.data?.message);

        createAuditTrailData({
          transId: Number(transId),
          taskType: "document_validation",
          event: "validate_client_details",
          description: `Validate client details with #${mainState?.data?.clientNo}`,
          userId: globalData.id
        })
        setClientValidated(true);
        setModalOpen(false);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  // #region deletePolicyAsiaRecord
  const deleteClientRecord = async (transactionId: any) => {
    await axios
      .delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/client-list?transId=${transactionId}`
      )
      .then((res: any) => {
        console.log(res?.data?.message);
        if (res?.data?.data) {
          createAuditTrailData({
            transId: Number(transId),
            taskType: "document_validation",
            event: "reset_client_details",
            description: `Reset client details with #${mainState?.data?.clientNo}`,
            userId: globalData.id
          })
          setClientData(null);
          setMainState({
            data: { clientNo: "" },
            clientData: {},
          });
          setClientValidated(false);
        }
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  // #region useEffect
  React.useEffect(() => {
    const getClient = async () => {
      await axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/client-list?transId=${transId}`
        )
        .then((res: any) => {
          console.log("client", res?.data?.data);
          const clientNoRes = res?.data?.data;
          let clientCode: string | null = "";
          if (clientNoRes) {
            clientCode = clientNoRes?.clientNumber;
            setClientValidated(true);
          } else if (clientCodeField) {
            clientCode = clientCodeField;
          } else {
            clientCode = null;
          }
          setMainState((prevState: any) => ({
            ...prevState,
            data: { ...prevState.data, clientNo: clientCode || "" },
          }));
        })
        .catch((err: any) => {
          console.log(err);
        });
    };

    getClient();
  }, [clientCodeField]);

  const handleInputChange = (e: any) => {
    const { value, id } = e.target;
    setMainState((prevState: any) => ({
      ...prevState,
      data: { ...prevState.data, [id]: value },
    }));
  };

  const isEmptyObject = (obj: any) => obj && Object.keys(obj).length === 0;

  const handleModalOpen = async () => {
    const clientNo = mainState.data.clientNo;
    if (!clientNo) {
      alert("Please enter a client number");
      return;
    }
    setLoading(true);

    console.log("input client", mainState.data.clientNo);

    try {
      const signatureResponse = await fetch(
        `${process.env.REACT_APP_SIGNATURE_ENDPOINT}`,
        {
          method: "POST",
          body: JSON.stringify({
            data: {
              clientInfo: {
                clientNumber: clientNo,
              },
            },
          }),
        }
      );

      if (!signatureResponse.ok) {
        throw new Error(`Error: ${signatureResponse.statusText}`);
      }

      const signatureData = await signatureResponse.json();
      console.log("Signature response:", signatureData.data);

      const signature = signatureData.data;

      const clientEnquiryResponse = await fetch(
        `${process.env.REACT_APP_CLIENT_ENDPOINT}`,
        {
          method: "POST",
          body: JSON.stringify({
            data: {
              clientInfo: {
                clientNumber: clientNo,
              },
            },
            signature: signature,
          }),
        }
      );

      if (!clientEnquiryResponse.ok) {
        throw new Error(`Error: ${clientEnquiryResponse.statusText}`);
      }

      const clientData = await clientEnquiryResponse.json();
      if (clientData.data && !isEmptyObject(clientData.data)) {
        setClientData(clientData.data);
      } else {
        setClientData(null);
      }
      setModalOpen(true);
    } catch (error) {
      console.error("Error fetching client data:", error);
      setClientData(null);
      setModalOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  // #region handleConfirm
  const handleConfirm = () => {
    // prepare request data
    const clientDataReq = {
      ...clientData,
      transId: Number(transId),
      status: 1,
    };

    // TODO: create api
    createClient(clientDataReq);
  };

  const handleReset = () => {
    // setMainState({ ...mainState, data: { ...mainState.data, policyNo: "" } });
    deleteClientRecord(transId);
  };

  return (
    <div>
      <FormControl variant="outlined" fullWidth>
        <Typography variant="body1" className="tp-text-bold">
          Client No. <span className="tp-text-required">*</span>
        </Typography>
        <OutlinedInput
          id="clientNo"
          size="small"
          type="text"
          placeholder={`Enter Client No. Here...`}
          value={mainState.data.clientNo}
          onChange={handleInputChange}
          inputProps={{
            "aria-label": "clientNo",
          }}
          disabled={
            !!(claimRegistrationData && claimRegistrationData.claimNumber)
          } // !! will return as boolean type, so if claimNumber is visible return true
          error={mainState?.data?.clientNo === ""}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={handleReset}
                edge="end"
                disabled={
                  !!(claimRegistrationData && claimRegistrationData.claimNumber)
                }
              >
                <RefreshIcon />
              </IconButton>
            </InputAdornment>
          }
        />
        <Button
          variant="contained"
          color={clientValidated ? "error" : "warning"}
          onClick={handleModalOpen}
          // style={{ backgroundColor: policyValidated ? "green" : "red" }}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : clientValidated ? (
            "Validated / View Details"
          ) : (
            "Check Client Details"
          )}
        </Button>
      </FormControl>

      <div>
        <Modal
          open={modalOpen}
          onClose={handleModalClose}
          sx={{ overflowY: "scroll" }}
        >
          <Box
            sx={{
              padding: 4,
              backgroundColor: "white",
              margin: "auto",
              width: "50%",
              marginTop: "10%",
              position: "relative",
            }}
          >
            <IconButton
              onClick={handleModalClose}
              sx={{ position: "absolute", top: 8, right: 8 }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" gutterBottom>
              Client Details
            </Typography>
            {clientData ? (
              <>
                <Typography variant="body2">
                  <strong>Client Number:</strong> {clientData.clientNumber}
                </Typography>
                <Typography variant="body2">
                  <strong>Client Type:</strong> {clientData.clientType}
                </Typography>
                <Typography variant="body2">
                  <strong>Surname:</strong> {clientData.surName}
                </Typography>
                <Typography variant="body2">
                  <strong>Given Name:</strong> {clientData.givenName}
                </Typography>
                <Typography variant="body2">
                  <strong>Client Sex:</strong> {clientData.clientSex}
                </Typography>
                <Typography variant="body2">
                  <strong>Civil Status:</strong> {clientData.civilStatus}
                </Typography>
                <Typography variant="body2">
                  <strong>Address Line 01:</strong> {clientData.addressLine01}
                </Typography>
                <Typography variant="body2">
                  <strong>Address Line 02:</strong> {clientData.addressLine02}
                </Typography>
                <Typography variant="body2">
                  <strong>Address Line 03:</strong> {clientData.addressLine03}
                </Typography>
                <Typography variant="body2">
                  <strong>Address Line 04:</strong> {clientData.addressLine04}
                </Typography>
                <Typography variant="body2">
                  <strong>Address Line 05:</strong> {clientData.addressLine05}
                </Typography>
                <Typography variant="body2">
                  <strong>Postal Code:</strong> {clientData.postalCode}
                </Typography>
                <Typography variant="body2">
                  <strong>Home Phone:</strong> {clientData.homePhone}
                </Typography>
                <Typography variant="body2">
                  <strong>Office Phone:</strong> {clientData.officePhone}
                </Typography>
                <Typography variant="body2">
                  <strong>Email Address:</strong> {clientData.emailAddress}
                </Typography>
                <Typography variant="body2">
                  <strong>Risk Profile Level:</strong>{" "}
                  {clientData.riskProfileLevel}
                </Typography>
                <Typography variant="body2">
                  <strong>Mobile Phone:</strong> {clientData.mobilePhone}
                </Typography>
                <Typography variant="body2">
                  <strong>Birthday:</strong> {clientData.birthday}
                </Typography>
                <Typography variant="body2">
                  <strong>Client Status:</strong> {clientData.clientStatus}
                </Typography>
                <Typography variant="body2">
                  <strong>Country of Origin:</strong>{" "}
                  {clientData.countryofOrigin}
                </Typography>
                <Typography variant="body2">
                  <strong>Registration Date:</strong>{" "}
                  {clientData.registrationDate}
                </Typography>
                <Typography variant="body2">
                  <strong>Tax ID Number:</strong> {clientData.taxIdNumber}
                </Typography>
                <Typography variant="body2">
                  <strong>Market Sector:</strong> {clientData.marketSector}
                </Typography>
                <Typography variant="body2">
                  <strong>Registration Number:</strong>{" "}
                  {clientData.registationNumber}
                </Typography>
                {!clientValidated && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleModalClose}
                    >
                      Cancel
                    </Button>
                    <Button variant="contained" onClick={handleConfirm}>
                      Confirm
                    </Button>
                  </Box>
                )}
              </>
            ) : (
              <Typography variant="body2" color="error">
                Client Doesn't Exist
              </Typography>
            )}
          </Box>
        </Modal>
      </div>
    </div>
  );
}
